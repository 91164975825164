import { CurrentSnapshots, SnapshotState } from "./SelectedSnapshotContext";

type Update = (state: SnapshotState) => void;

interface Params {
  changeNextSnapshot: Update;
  changePrevSnapshot: Update;
  changeSnapshot: Update;
  currentSnapshots: CurrentSnapshots;
}

export const createSnapshotsActions = ({
  changeNextSnapshot,
  changePrevSnapshot,
  changeSnapshot,
  currentSnapshots,
}: Params) =>
  Object.entries(currentSnapshots)
    .map(([key, value]) => {
      let action: Update;
      if (key === "source") {
        return;
      }
      switch (key) {
        case "prevSnapshot": {
          action = changePrevSnapshot;
          break;
        }
        case "snapshot": {
          action = changeSnapshot;
          break;
        }
        case "nextSnapshot": {
          action = changeNextSnapshot;
          break;
        }
        default: {
          action = () => {};
        }
      }
      return { value, setAction: action };
    })
    .filter((val) => !!val) as { value: number; setAction: Update }[];
