import { BottomMenuItem } from "core/components/BottomMenuItem";
import { HorizontalBox } from "core/components/CommonStyles";
import { ReactNode } from "react";
import { Button } from "tombac";
import { ChevronLeftIcon, ChevronRightIcon } from "tombac-icons";
import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { useSnapshots } from "viewer/contexts/SnapshotsContext";

export const SnapshotSwitcher = () => {
  const { snapshotsState } = useSnapshots();
  const { selectedSnapshotTimestamp, goNext, goPrev } = useSelectedSnapshot();
  const isButtonDisabled = snapshotsState.state !== "loaded";

  const handlePrevClick = () => {
    if (selectedSnapshotTimestamp && snapshotsState.state === "loaded") {
      const foundedIndex = snapshotsState.snapshots.findIndex(
        (val) => val === selectedSnapshotTimestamp
      );
      if (foundedIndex > -1) {
        goPrev(snapshotsState.snapshots[foundedIndex - 2]);
      }
    }
  };

  const handleNextClick = () => {
    if (selectedSnapshotTimestamp && snapshotsState.state === "loaded") {
      const foundedIndex = snapshotsState.snapshots.findIndex(
        (val) => val === selectedSnapshotTimestamp
      );
      if (foundedIndex > -1) {
        goNext(snapshotsState.snapshots[foundedIndex + 2]);
      }
    }
  };

  const canNext = () => {
    if (snapshotsState.state === "loaded") {
      const foundedIndex = snapshotsState.snapshots.findIndex(
        (val) => val === selectedSnapshotTimestamp
      );

      return (
        foundedIndex > -1 && foundedIndex + 1 < snapshotsState.snapshots.length
      );
    }

    return false;
  };

  const canPrev = () => {
    if (snapshotsState.state === "loaded") {
      const foundedIndex = snapshotsState.snapshots.findIndex(
        (val) => val === selectedSnapshotTimestamp
      );

      return foundedIndex - 1 >= 0;
    }

    return false;
  };

  return (
    <BottomMenuItem>
      <HorizontalBox $gap="8px">
        <CircleButton
          prepend={<ChevronLeftIcon />}
          onClick={handlePrevClick}
          disabled={isButtonDisabled || !canPrev()}
        />
        <CircleButton
          prepend={<ChevronRightIcon />}
          onClick={handleNextClick}
          disabled={isButtonDisabled || !canNext()}
        />
      </HorizontalBox>
    </BottomMenuItem>
  );
};

interface ButtonProps {
  prepend: ReactNode;
  onClick: VoidFunction;
  disabled: boolean;
}

const CircleButton = ({ prepend, onClick, disabled }: ButtonProps) => (
  <Button
    prepend={prepend}
    onClick={onClick}
    shape="circle"
    variant="flat"
    disabled={disabled}
  />
);
