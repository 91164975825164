import { EffectCode } from "./effectCode.types";
import { IncidentFeature } from "./snapshots.types";

export const getMainCausesFromFeature = (incident: IncidentFeature): string[] =>
  incident.properties.application.trafficIncident.cause.map((c) => c.mainCause);

export const getEffectFromFeature = (feature: IncidentFeature): EffectCode =>
  feature.properties.application.trafficIncident.section.effectCode;

export const getWarningLevelsFromFeature = (
  incident: IncidentFeature
): string[] =>
  incident.properties.application.trafficIncident.cause.map(
    (c) => c.warningLevel
  );

export const getFeatureFRC = (feature: IncidentFeature): number => {
  const frc =
    feature.properties.location.openLR.linearLocationReference.first
      .lineProperties.functionalRoadClass;
  const frcLevel = +frc.split("FUNCTIONAL_ROAD_CLASS_")[1];
  return frcLevel;
};
