import * as React from "react";

import { Box, Button, Checkbox, Heading, Label } from "tombac";

import { Snapshot } from "core/domain/snapshots.types";
import { useToggleList } from "./useToggleList";
import { IncidentFeature } from "core/domain/snapshots.types";
import { IncidentFilter } from "viewer/contexts/FiltersContext";
import { CheckboxLabel } from "./Filters.style";
import { VerticalBox } from "core/components/CommonStyles";

const countProps = (
  snapshot: Snapshot,
  propertyGetter: any,
  availableProperties: any
) => {
  const counts: { [s: string]: number } = {};

  availableProperties.forEach((prop: string) => {
    counts[prop] = 0;
  });

  snapshot.features.forEach((incident) => {
    const props = propertyGetter(incident);
    props.forEach((c: string) => (counts[c] += 1));
  });

  return counts;
};

const compareProps = (counts: any) => (a: string, b: string) =>
  counts[b] - counts[a];

const isEmptyProp = (counts: any, c: string) => counts[c] === 0;

const constructFilter =
  (
    includedProps: string[],
    propertyGetter: any,
    availableProperties: string[]
  ) =>
  (incident: IncidentFeature) => {
    if (includedProps.length === availableProperties.length) {
      return true;
    }
    const props = propertyGetter(incident);
    for (const prop of props) {
      if (includedProps.includes(prop)) {
        return true;
      }
    }
    return false;
  };

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface Props {
  snapshot: Snapshot;
  propertyGetter: (inc: IncidentFeature) => string[] | string;
  availableProperties: string[];
  propertyFormatter: (s: string) => string;
  title: any;
  onFilter: (filter: IncidentFilter) => void;
}

export function IncidentPropFilter({
  snapshot,
  propertyGetter,
  availableProperties,
  propertyFormatter,
  title,
  onFilter,
}: Props) {
  const [includedProps, setIncludedProp, setProps] = useToggleList(
    availableProperties
  ) as any;
  const showEmpty = false;

  React.useEffect(() => {
    onFilter(
      constructFilter(includedProps, propertyGetter, availableProperties)
    );
  }, [includedProps]);

  const changeProp = (prop: string, value: boolean) => {
    setIncludedProp(prop, value);
  };

  const counts = React.useMemo(
    () => countProps(snapshot, propertyGetter, availableProperties),
    [snapshot]
  );
  return (
    <Box $p="12px 24px" $borderBottom="1px solid #e5e5e5">
      <Heading level={3} $display="flex" $alignItems="center" $gap="8px">
        {title}
        <Box $display="flex" $gap="4px">
          <Button
            onClick={() => setProps(availableProperties)}
            variant="flat"
            size="xs"
          >
            All
          </Button>
          <Button onClick={() => setProps([])} variant="flat" size="xs">
            None
          </Button>
        </Box>
      </Heading>
      <VerticalBox $gap="8px" $mt="12px">
        {availableProperties
          .sort(compareProps(counts))
          .filter(showEmpty ? () => true : (c) => !isEmptyProp(counts, c))
          .map((c) => {
            const isEmpty = isEmptyProp(counts, c);
            const prettyProp = propertyFormatter(c).toLowerCase();
            const isEnabled = includedProps.includes(c);
            const count = counts[c];

            return (
              <Box key={c}>
                <Checkbox
                  $display="flex"
                  $alignItems="center"
                  label={
                    <CheckboxLabel>
                      <Label>{capitalizeFirstLetter(prettyProp)}</Label>
                      <div className="IncidentPropFilter__prop-count">
                        {count} incidents
                      </div>
                    </CheckboxLabel>
                  }
                  checked={isEmpty ? false : isEnabled}
                  onChange={(v) => changeProp(c, v.currentTarget.checked)}
                  disabled={isEmpty}
                />
              </Box>
            );
          })}
      </VerticalBox>
    </Box>
  );
}
