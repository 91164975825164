import { VerticalBox } from "core/components/CommonStyles";
import { Heading } from "tombac";
import { Day, DaysContainer } from "./RangeSelector.styles";
import { GroupedDays } from "./utils";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface Props {
  groupedDays: GroupedDays;
  selectedDay: Date | null;
  onClick: (day: Date) => void;
}

export const MonthDays = ({ groupedDays, selectedDay, onClick }: Props) => {
  return (
    <VerticalBox $gap="8px" $m="1.5sp 0">
      <Heading level={5}>{monthNames[groupedDays.month]}</Heading>
      <DaysContainer>
        {groupedDays.days.map((day, i) => (
          <Day
            key={i}
            selected={day.valueOf() === selectedDay?.valueOf()}
            onClick={() => onClick(day)}
          >
            {day.getDate()}
          </Day>
        ))}
      </DaysContainer>
    </VerticalBox>
  );
};
