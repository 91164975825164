import { LngLatBoundsLike } from "mapbox-gl";
// import { getCountryLocation, getCountryBBox } from "./countries";
import countriesCfg from "./countries.json";
import feeds from "./feeds.json";

export interface FeedCfg {
  id: string;
  feedId: string;
  label: string;
  envId: "PRODUCTION" | "PROSPECT";
  customerId: number;
  startDate: string;
  endDate?: string;
}

export const feedCfg = feeds.sort((f1, f2) => f1.label.localeCompare(f2.label));

export const getFeedSettings = (feedId: string) =>
  feedCfg.find((feed) => feed.id === feedId) as FeedCfg;

export const getFeedStartDate = (feedId: string) =>
  new Date(getFeedSettings(feedId).startDate);

export const getFeedEndDate = (feedId: string) => {
  const feedCfg = getFeedSettings(feedId);
  if (!feedCfg.endDate) {
    return undefined;
  }

  return new Date(feedCfg.endDate);
};

export const getFeedBBox = (feedId: string): LngLatBoundsLike | undefined => {
  const countryId = feedId.split("_")[0];

  const countryInfo = countriesCfg.find(({ code }) => code === countryId);
  if (!countryInfo) {
    return;
  }

  const [neLng, neLat, swLng, swLat] = countryInfo.bbox;
  return [
    [neLng, neLat],
    [swLng, swLat],
  ];
};
