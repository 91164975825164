import "./JSONView.css";

interface Props {
  object: any;
  width?: number | string;
}

function syntaxHighlight(json: string) {
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    (match: any) => {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}

export const JSONView = ({ object, width }: Props) => {
  const objectString = JSON.stringify(object, null, 2);
  const highlighted = syntaxHighlight(objectString);
  return (
    <div
      style={{ width }}
      className="JSONView"
      dangerouslySetInnerHTML={{ __html: highlighted }}
    />
  );
};
