import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { Snapshot } from "core/domain/snapshots.types";
import { useEffect, useState } from "react";

export const useSnapshotToDisplay = () => {
  const { snapshotState } = useSelectedSnapshot();
  const [snapshotToDisplay, setSnapshotToDisplay] = useState<Snapshot>();

  useEffect(() => {
    if (snapshotState.state === "loaded") {
      setSnapshotToDisplay(snapshotState.snapshot);
    } else if (snapshotState.state === "not-selected") {
      setSnapshotToDisplay(undefined);
    }
  }, [snapshotState]);

  return snapshotToDisplay;
};
