import { useEffect, useState } from "react";
import styled from "styled-components";
import { ChevronRightIcon, ChevronLeftIcon } from "tombac-icons";
import { FiltersSettings } from "./FiltersSettings";
import { useSnapshotToDisplay } from "./useSnapshotToDisply";

export const FiltersView = () => {
  const [isOpen, setOpen] = useState(true);
  const snapshotToDisplay = useSnapshotToDisplay();

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [isOpen, snapshotToDisplay]);

  if (!snapshotToDisplay) {
    return null;
  }

  return (
    <Container isOpen={isOpen}>
      <FiltersContent>
        {isOpen ? <FiltersSettings snapshot={snapshotToDisplay} /> : null}
      </FiltersContent>
      <FiltersToggle isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
        {isOpen ? (
          <>
            <ChevronLeftIcon />
            Hide filters
          </>
        ) : (
          <ChevronRightIcon />
        )}
      </FiltersToggle>
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  position: relative;
  height: 100vh;
  overflow: auto;
  width: ${(props) => (props.isOpen ? "300px" : "50px")};
  background: white;
  border-right: 1px solid #e5e5e5;
`;

const FiltersToggle = styled.button<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: white;
  border: none;
  border-top: 1px solid #e5e5e5;
  width: ${(props) => (props.isOpen ? "299px" : "49px")};
  height: 72px;
  display: block;
  cursor: pointer;
  font-family: "Gotham";

  &:hover {
    background: #f1f1f1;
  }
`;

const FiltersContent = styled.div`
  padding-bottom: 100px;
`;
