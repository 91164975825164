import { HorizontalBox } from "core/components/CommonStyles";
import { ReactNode } from "react";
import { Label } from "tombac";
import { Value } from "./SelectedIncident.style";

type ValueType = string | number | undefined;

interface Props {
  label: string;
  value: ValueType;
  formatter: (val: ValueType) => ReactNode;
}

export const ValueLabel = ({ formatter, label, value }: Props) => {
  return (
    <HorizontalBox $justifyContent="space-between" $alignItems="center">
      <Label>{label}</Label>
      <Value>{formatter(value)} </Value>
    </HorizontalBox>
  );
};
