import { Button } from "tombac";
import { UploadIcon } from "tombac-icons";
import { useDropzone } from "react-dropzone";
import { ConvertApi } from "api/convert.api";
import { useState } from "react";
import { useActiveDropZone } from "./useActiveDropZone";
import { DropContainer, DropInfo } from "./Upload.style";
import { ErrorModal } from "./ErrorModal";
import { ConvertingModal } from "./ConvertingModal";
import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { createSnapshotsWithId } from "viewer/contexts/createSnapshotsWithId";
import { BottomMenuItem } from "core/components/BottomMenuItem";
import { useFeed } from "viewer/contexts/FeedContext";

type ConvertState = "CONVERTING" | "ERROR" | "NOT_STARTED";

export const Upload = () => {
  const [isDropZoneActive, close] = useActiveDropZone();
  const [convertState, setConvertState] = useState<ConvertState>("NOT_STARTED");
  const { changeLoadedSnapshot } = useSelectedSnapshot();
  const { changeFeed } = useFeed();

  const handleFile = (files: File[]) => {
    setConvertState("CONVERTING");
    close();
    if (!files.length) return;
    const file = files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      if (reader.result === null) return;
      ConvertApi.convertSnapshot(reader.result as ArrayBuffer)
        .then((data) => {
          changeFeed(data.metadata.feedId);
          changeLoadedSnapshot(createSnapshotsWithId(data));
          setConvertState("NOT_STARTED");
        })
        .catch(() => setConvertState("ERROR"));
    };
  };

  const { open, getRootProps, getInputProps } = useDropzone({
    onDrop: handleFile,
  });

  return (
    <BottomMenuItem disableBorder>
      <DropContainer {...getRootProps()} isActive={isDropZoneActive}>
        <input {...getInputProps()} />
        <DropInfo>Drop file here</DropInfo>
      </DropContainer>

      <Button variant="ghost" prepend={<UploadIcon />} onClick={open}>
        Upload
      </Button>
      <ErrorModal
        isOpen={convertState === "ERROR"}
        onAccept={() => setConvertState("NOT_STARTED")}
      />
      <ConvertingModal isOpen={convertState === "CONVERTING"} />
    </BottomMenuItem>
  );
};
