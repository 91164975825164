import styled from "styled-components";

export const MapContainer = styled.div`
  position: relative;
  height: 100%;
  flex-grow: 1;
  width: 100%;

  .mapboxgl-map {
    font: unset;
    position: absolute;
    width: 100%;
    height: 100%;

    .mapboxgl-control-container {
      .mapboxgl-ctrl {
        clear: both;
        pointer-events: auto;
        -webkit-transform: translate(0);
        transform: translate(0);
      }

      .mapboxgl-ctrl button {
        width: 40px;
        height: 40px;
        transition: 0.1s ease-out;
        transition-property: background-color;
      }
      .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPlus%3C/title%3E%3Cg id='Plus' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='+-copy' fill='%234A4C4C' points='3.99740503 10 3.99740503 6.02194528 0 6.02194528 9.09494702e-13 4.01173764 3.99740503 4.01173764 3.99740503 0 6.02167802 7.27595761e-12 6.02167802 4.01173764 10 4.01173764 10 6.02194528 6.02167802 6.02194528 6.02167802 10'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
      }

      .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EMinus%3C/title%3E%3Cg id='Minus' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='+-copy' fill='%234A4C4C' points='0 6.02194528 9.09494702e-13 4.01173764 10 4.01173764 10 6.02194528'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
      }

      .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='8px' height='20px' viewBox='0 0 8 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Triangle-Copy' fill='%23333333' points='4.02828147 0 8.05656294 8 -3.01980663e-14 8'%3E%3C/polygon%3E%3Cpolygon id='Triangle-Copy-2' fill='%23BEBEBE' points='4 20 0 12 8 12'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
      }

      .mapboxgl-ctrl-attrib-inner {
        display: none;
      }
    }
  }
`;

export const MapCopyrightContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 6px;
  font-size: 11px;
  font-family: "Gotham Book", arial, sans-serif;
  z-index: 1;
  & > a {
    text-decoration: none;
    color: #000;
  }
`;
