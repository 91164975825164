import { useMemo } from "react";
import { Props as ReactSelectProps } from "react-select";
import { PropsWithPropStyling } from "tombac";
import { Option, Select } from "./Select";

export interface SimpleSelectCoreProps<ValueType> {
  options: Option<ValueType>[];
  onChange?: (value: ValueType) => void;
  value?: ValueType | null;
  invalid?: boolean;
  selectProps?: Omit<ReactSelectProps, "options" | "value" | "onChange">;
  placeholder?: string;
}

export type SimpleSelectProps<T> = PropsWithPropStyling<
  SimpleSelectCoreProps<T>
>;

export function SimpleSelect<T>({
  options,
  onChange = () => {},
  value,
  selectProps = {},
  ...rest
}: SimpleSelectProps<T>) {
  const selectedValue = useMemo(
    () => options.find((o) => o.value === value),
    [value]
  );

  return (
    <Select
      options={options}
      value={selectedValue || null}
      onChange={({ value }: any) => onChange(value)}
      {...rest}
      {...(selectProps as any)}
    />
  );
}
