import { EffectCode } from "./effectCode.types";
import { MainCause } from "./mainCause.types";

export const formatEffectCode = (code: EffectCode): string =>
  code
    .split("EFFECT_CODE_")[1]
    .split("_")
    .map((s) => s.toLowerCase())
    .join(" ");

export const formatMainCause = (cause: MainCause): string =>
  cause.split("MAIN_CAUSE_")[1].split("_").join(" ");

export const formatWarningLevel = (warningLevel: string): string =>
  warningLevel.split("WARNING_LEVEL_")[1].split("_").join(" ");
