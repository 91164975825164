import marianStyle from "./marian.open.style";
import reineStyle from "./reine.style";
import { clone } from "ramda";
import { ApiKeys } from "../constants/ApiKeys";

export const insertAfter = (style, layerAfterId, layersToInsert) => {
  const newStyle = clone(style);
  let layersBefore = [];
  let layersAfter = [];
  newStyle.layers.forEach((layer) => {
    if (layer.id === layerAfterId) {
      layersBefore = layersAfter;
      layersAfter = [];
    }
    layersAfter.push(layer);
  });
  newStyle.layers = [...layersBefore, ...layersToInsert, ...layersAfter];
  return newStyle;
};

const mapStyle = {
  ...marianStyle,
  sources: {
    vectorTiles: {
      maxzoom: 22,
      minzoom: 0,
      tiles: [
        `https://a.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=${ApiKeys.MAP}&language=en-US`,
        `https://b.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=${ApiKeys.MAP}&language=en-US`,
        `https://c.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=${ApiKeys.MAP}&language=en-US`,
        `https://d.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=${ApiKeys.MAP}&language=en-US`,
      ],
      type: "vector",
    },
    "reine-edges": {
      maxzoom: 18,
      minzoom: 13,
      tiles: [
        `https://api.tomtom.com/reine/maps/mvt/DSEG/{z}/{x}/{y}.pbf?key=${ApiKeys.REINE}`,
      ],
      type: "vector",
    },
    "reine-edges-end": {
      maxzoom: 18,
      minzoom: 13,
      tiles: [
        `https://api.tomtom.com/reine/maps/mvt/DSEG/{z}/{x}/{y}.pbf?key=${ApiKeys.REINE}&simplify=startend`,
      ],
      type: "vector",
    },
    "japan-reine-edges": {
      maxzoom: 18,
      minzoom: 13,
      tiles: [
        `https://api.tomtom.com/reine/maps/mvt/OPEN_DSEG/{z}/{x}/{y}.pbf?key=${ApiKeys.REINE}`,
      ],
      type: "vector",
    },
    "japan-reine-edges-end": {
      maxzoom: 18,
      minzoom: 13,
      tiles: [
        `https://api.tomtom.com/reine/maps/mvt/OPEN_DSEG/{z}/{x}/{y}.pbf?key=${ApiKeys.REINE}&simplify=startend`,
      ],
      type: "vector",
    },
    openmaptiles: {
      url: "https://api.maptiler.com/tiles/v3/tiles.json?key=KLEP6Fto9rzNlEoqkQvl",
      type: "vector",
    },
  },
};
const mapConfig = {
  style: mapStyle,
  reineStyle: insertAfter(
    mapStyle,
    "Disputed country border",
    reineStyle.layers
  ),
  reineLayers: reineStyle.layers,
  defaults: {
    maxBounds: [
      [-260, -85],
      [180, 85],
    ],
    maxZoom: 20,
    minZoom: 2.5,
  },
};

export default mapConfig;
