import { Snapshot } from "core/domain/snapshots.types";
const CACHE_SIZE = 16;

export const createCache = (cacheSize: number = CACHE_SIZE) => {
  const cache = new Map();
  return {
    cache,
    has: (key: string) => cache.has(key),
    get: (key: string) => cache.get(key),
    set: (key: string, val: any) => {
      if (cache.size > cacheSize) {
        const firstKey = cache.keys().next().value;
        if (firstKey) {
          cache.delete(firstKey);
        } else {
          // Panic
          cache.clear();
        }
      }
      cache.set(key, val);
    },
  };
};

export const createSnapshotCache = () => {
  const cache: {
    snapshot: Snapshot | null;
    decodedIds: Set<string>;
    errorIds: Set<string>;
  } = { snapshot: null, decodedIds: new Set(), errorIds: new Set() };

  return {
    cache,
    difference: (keys: string[]) =>
      keys.filter(
        (key) => !cache.decodedIds.has(key) && !cache.errorIds.has(key)
      ),
    get: () => cache.snapshot,
    getErrors: () => cache.errorIds,
    set: (keys: string[], val: Snapshot) => {
      if (cache.snapshot === null) {
        cache.snapshot = val;
      } else {
        const newFeatures = [...cache.snapshot.features, ...val.features];
        cache.snapshot.features = newFeatures;
      }
      keys.forEach((key) => {
        const feature =
          val.features.find(
            (feature) =>
              feature.properties.messageManagement.Id === parseInt(key, 10)
          ) || null;
        feature === null ? cache.errorIds.add(key) : cache.decodedIds.add(key);
      });
    },
  };
};
