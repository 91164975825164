import { formatDate } from "core/utils/formatDate";
import { IncidentFeature } from "core/domain/snapshots.types";

type ValueType = string | number | undefined;

const createCodeFormatter =
  (prefix: string) =>
  (value: ValueType): string =>
    ("" + value)
      .split(prefix)[1]
      .split("_")
      .map((word) => word.toLowerCase())
      .join(" ");

const timeFormatter = (timestamp: ValueType): string =>
  formatDate(new Date(new Date(Number(timestamp) * 1000).toUTCString()));

const transformSubcause = (subCause: {
  [subcause: string]: string;
}): { subcause: string; subcauseDetail: string } => {
  const result = [];

  for (const subcause in subCause) {
    if (subCause.hasOwnProperty(subcause)) {
      result.push({ subcause, subcauseDetail: subCause[subcause] });
    }
  }
  return result[0];
};

export const createOverviewLabel = (incidentFeature: IncidentFeature) => {
  const { section, cause } =
    incidentFeature.properties.application.trafficIncident;
  const { messageManagement } = incidentFeature.properties;

  const causeSections = cause.map((cause) => {
    const baseArray = [
      {
        label: "Main Cause",
        value: cause.mainCause as string,
        formatter: createCodeFormatter("MAIN_CAUSE_"),
      },
    ];
    if (cause.subCause) {
      const { subcause, subcauseDetail } = transformSubcause(cause.subCause);

      baseArray.push({
        label: "Sub Cause",
        value: subcause,
        formatter: (val: ValueType) =>
          val === undefined ? "No data" : `${val}`,
      });

      baseArray.push({
        label: "Sub Cause Detail",
        value: subcauseDetail,
        formatter: createCodeFormatter("SUB_CAUSE_"),
      });
    }
    baseArray.push({
      label: "Warning level",
      value: cause.warningLevel,
      formatter: (val) =>
        val === undefined
          ? "No data"
          : createCodeFormatter("WARNING_LEVEL_")(val),
    });

    return baseArray;
  });

  return {
    section: [
      {
        label: "Average Traffic Speed",
        value: section.averageTrafficSpeed,
        formatter: (val: ValueType) =>
          val === undefined ? "No data" : `${val} km/h`,
      },
      {
        label: "Delay",
        value: section.delay,
        formatter: (val: ValueType) =>
          val === undefined ? "No data" : `${val} s`,
      },
      {
        label: "Effect Code",
        value: section.effectCode,
        formatter: createCodeFormatter("EFFECT_CODE_"),
      },
      {
        label: "Length Affected",
        value: section.lengthAffected,
        formatter: (val: ValueType) =>
          val === undefined ? "No data" : `${val} m`,
      },
      {
        label: "Offset",
        value: section.offset,
        formatter: (val: ValueType) =>
          val === undefined ? "No data" : `${val} m`,
      },
    ],
    cause: causeSections,
    message: [
      {
        label: "Id",
        value: messageManagement.Id,
        formatter: (val: ValueType) => `${val}`,
      },
      {
        label: "Version",
        value: messageManagement.version,
        formatter: (val: ValueType) =>
          val === undefined ? "No data" : `${val}`,
      },
      {
        label: "Expiry Time",
        value: messageManagement.expiryTime,
        formatter: (data: ValueType) =>
          data === undefined ? "No data" : timeFormatter(data),
      },
    ],
  };
};
