import { useState } from "react";
import { Button } from "tombac";
import { DownloadIcon } from "tombac-icons";
import { useLoadedSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { SnapshotsApi } from "api/snapshots.api";
import download from "downloadjs";
import { useFeed } from "viewer/contexts/FeedContext";
import { BottomMenuItem } from "core/components/BottomMenuItem";

export const Download = () => {
  const { snapshot } = useLoadedSelectedSnapshot();
  const { feed } = useFeed();

  const [downloading, setDownloading] = useState(false);
  const handleClick = () => {
    setDownloading(true);
    SnapshotsApi.getProdobufSnapshot({
      feed,
      timestamp: snapshot.metadata.creationTime,
    })
      .then((data) => download(data, `${snapshot.metadata.creationTime}.pbf`))
      .then(() => setDownloading(false));
  };

  return (
    <BottomMenuItem>
      <Button
        busy={downloading}
        variant="ghost"
        onClick={handleClick}
        prepend={<DownloadIcon />}
      >
        Download
      </Button>
    </BottomMenuItem>
  );
};
