import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useFeed } from "viewer/contexts/FeedContext";
import { range } from "ramda";
import { SimpleSelect } from "core/components/Select/SimpleSelect";
import { getFeedStartDate, getFeedEndDate } from "api/feed";
import { isSameDay } from "date-fns";

interface Props {
  selectedHour: number;
  setSelectedHour: Dispatch<SetStateAction<number>>;
  selectedDate: Date | null;
}

const defaultOptions = range(0, 24).map((h) => ({
  label: h.toString(),
  value: h,
}));

export const HourSelector = ({
  selectedHour,
  setSelectedHour,
  selectedDate,
}: Props) => {
  const { feed } = useFeed();
  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(23);
  const startDate = getFeedStartDate(feed);
  const endDate = getFeedEndDate(feed);

  useEffect(() => {
    if (selectedDate && isSameDay(startDate, selectedDate)) {
      const sHour = startDate.getUTCHours() || 0;
      setMinHour(sHour);
    } else if (selectedDate && endDate && isSameDay(endDate, selectedDate)) {
      const eHour = endDate.getUTCHours() || 23;
      setMaxHour(eHour);
    } else {
      setMinHour(0);
      setMaxHour(23);
    }
  }, [feed, selectedDate]);

  const hourOptions = useMemo(() => {
    return defaultOptions.filter(
      (h) => h.value <= maxHour && h.value >= minHour
    );
  }, [feed, minHour, maxHour]);

  useEffect(() => {
    if (selectedHour < minHour) {
      setSelectedHour(minHour);
    } else if (selectedHour > maxHour) {
      setSelectedHour(maxHour);
    } else {
      setSelectedHour(selectedHour);
    }
  }, [hourOptions]);

  return (
    <SimpleSelect
      options={hourOptions}
      value={selectedHour}
      onChange={setSelectedHour}
      selectProps={{ menuPlacement: "top" }}
    />
  );
};
