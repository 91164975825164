import { IncidentFeature } from "core/domain/snapshots.types";
import { useMemo } from "react";
import { createOverviewLabel } from "./createOverviewLabels";
import { JSONView } from "./JSONView";
import { OverviewSection } from "./OverviewSection";
import { SelectedIncidentContainer } from "./SelectedIncident.style";
import { ValueLabel } from "./ValueLabel";
import { ErrorValue } from "./SelectedIncident.style";

interface Props {
  selectedIncident: IncidentFeature;
}

export const SelectedIncident = ({ selectedIncident }: Props) => {
  const { section, cause, message } = useMemo(
    () => createOverviewLabel(selectedIncident),
    [selectedIncident]
  );

  return (
    <SelectedIncidentContainer>
      <OverviewSection header="Section">
        {section.map((label, i) => (
          <ValueLabel key={i} {...label} />
        ))}
      </OverviewSection>
      {cause.map((cause, i) => (
        <OverviewSection key={i} header="Cause">
          {cause.map((label, i) => (
            <ValueLabel key={i} {...label} />
          ))}
        </OverviewSection>
      ))}
      <OverviewSection header="Message">
        {message.map((label, i) => (
          <ValueLabel key={i} {...label} />
        ))}
      </OverviewSection>
      <OverviewSection header="Location" dropdown>
        <JSONView
          object={selectedIncident.properties.location.openLR}
          width={300}
        />
      </OverviewSection>
      {selectedIncident.geometry.type !== "Point" && (
        <OverviewSection header="Geometry" dropdown>
          <JSONView object={selectedIncident.geometry} width={300} />
        </OverviewSection>
      )}
      {selectedIncident.properties.hasError && (
        <OverviewSection header="Errors">
          <ErrorValue>Location data couldn't be decoded.</ErrorValue>
        </OverviewSection>
      )}
    </SelectedIncidentContainer>
  );
};

// import { IncidentFeature } from "core/domain/snapshots.types";
// import { useMemo } from "react";
// import { createOverviewLabel } from "./createOverviewLabels";
// import { JSONView } from "./JSONView";
// import { OverviewSection } from "./OverviewSection";
// import { SelectedIncidentContainer } from "./SelectedIncident.style";
// import { ValueLabel } from "./ValueLabel";
// import { ErrorValue } from "./SelectedIncident.style";

// interface Props {
//   selectedIncident: IncidentFeature;
// }

// export const SelectedIncident = ({ selectedIncident }: Props) => {
//   const { section, cause, message } = useMemo(
//     () => createOverviewLabel(selectedIncident),
//     [selectedIncident]
//   );

//   return (
//     <SelectedIncidentContainer>
//       <OverviewSection header="Section">
//         {section.map((label, i) => (
//           <ValueLabel key={i} {...label} />
//         ))}
//       </OverviewSection>
//       {cause.map((cause, i) => (
//         <OverviewSection key={i} header="Cause">
//           {cause.map((label, i) => (
//             <ValueLabel key={i} {...label} />
//           ))}
//         </OverviewSection>
//       ))}
//       <OverviewSection header="Message">
//         {message.map((label, i) => (
//           <ValueLabel key={i} {...label} />
//         ))}
//       </OverviewSection>
//       <OverviewSection header="Location" dropdown>
//         <JSONView
//           object={selectedIncident.properties.location.openLR}
//           width={300}
//         />
//       </OverviewSection>
//       {selectedIncident.geometry.type !== "Point" && (
//         <OverviewSection header="Geometry" dropdown>
//           <JSONView object={selectedIncident.geometry} width={300} />
//         </OverviewSection>
//       )}
//       {selectedIncident.properties.hasError && (
//         <OverviewSection header="Errors">
//           <ErrorValue>Location data couldn't be decoded.</ErrorValue>
//         </OverviewSection>
//       )}
//     </SelectedIncidentContainer>
//   );
// };
