import {
  Button,
  Modal,
  ModalActions as BaseActions,
  ModalContent,
  ModalHeader,
  ModalLayout,
} from "tombac";

interface Props {
  isOpen: boolean;
  onAccept: VoidFunction;
}

const ModalActions = BaseActions as any;

export const ErrorModal = ({ isOpen, onAccept }: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalLayout>
        <ModalHeader>Failed to convert snapshot.</ModalHeader>
        <ModalContent>
          Please make sure to upload a valid snapshot in .pbf format.
        </ModalContent>
        <ModalActions>
          <Button variant="primary" onClick={onAccept}>
            OK
          </Button>
        </ModalActions>
      </ModalLayout>
    </Modal>
  );
};
