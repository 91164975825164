import { MouseEventHandler, ReactNode, useState } from "react";
import { Button } from "tombac";
import { ChevronDownIcon, ChevronUpIcon } from "tombac-icons";
import {
  SectionHeader,
  OverviewSectionContainer,
  LabelsContainer,
} from "./SelectedIncident.style";

interface Props {
  dropdown?: boolean;
  children: ReactNode;
  header: string;
}

export const OverviewSection = ({
  children,
  header,
  dropdown = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick: MouseEventHandler<any> = (ev) => {
    ev.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <OverviewSectionContainer onClick={handleClick} dropdown={dropdown}>
      <SectionHeader level={4} dropdown={dropdown}>
        {dropdown && (
          <Button
            shape="circle"
            prepend={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            variant="flat"
            size="xs"
            onClick={handleClick}
          />
        )}
        {header}
      </SectionHeader>
      {dropdown ? (
        isOpen && <LabelsContainer>{children}</LabelsContainer>
      ) : (
        <LabelsContainer>{children}</LabelsContainer>
      )}
    </OverviewSectionContainer>
  );
};
