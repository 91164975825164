import chroma from "chroma-js";
import { EffectCode } from "core/domain/effectCode.types";
import { getEffectFromFeature } from "core/domain/getters";
import { IncidentFeature } from "core/domain/snapshots.types";

const colorMap: { [code in EffectCode]: string } = {
  EFFECT_CODE_INVALID: "#F00",
  EFFECT_CODE_UNKNOWN_TRAFFIC_FLOW: "#888",
  EFFECT_CODE_FREE_TRAFFIC_FLOW: "#57c513",
  EFFECT_CODE_HEAVY_TRAFFIC: "#f97f05",
  EFFECT_CODE_SLOW_TRAFFIC: "#f97f05",
  EFFECT_CODE_QUEUING_TRAFFIC: "#f97f05",
  EFFECT_CODE_STATIONARY_TRAFFIC: "#e22527",
  EFFECT_CODE_CLOSED: "#666",
};

export const getEffectColor = (effect: EffectCode) => colorMap[effect];

export const getFeatureColor = (incident: IncidentFeature): string =>
  colorMap[getEffectFromFeature(incident)];

export const getEffectOutlineColor = (effect: EffectCode): string =>
  chroma(getEffectColor(effect)).set("hsl.l", "*0.5").css();

export const getFeatureOutlineColor = (feature: IncidentFeature): string =>
  getEffectOutlineColor(getEffectFromFeature(feature));
