import { useLoadedSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { createGetFeaturesUnderMouse } from "core/utils/getFeaturesUnderMouse";
import { IncidentFeature } from "core/domain/snapshots.types";
import { MapMouseEvent } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";
import { getFeatureStyleProps } from "../utils/geoStyle";
import { useSelectedIncidentId } from "viewer/contexts/SelectedIncident";
import { useMap } from "core/components/Map/GlMap";

type SelectedIncidentFeature = Omit<IncidentFeature, "properties"> & {
  properties: IncidentFeature["properties"] & {
    lineColor?: string;
    circleColor?: string;
    outlineColor?: string;
    lineWidth?: number;
    outlineWidth?: number;
  };
};

export const useSelectIncident = (layerIds: string[]) => {
  const { snapshot, decodedErrors } = useLoadedSelectedSnapshot();

  const { selectedIncidentId, changeSelectedIncident } =
    useSelectedIncidentId();
  const [selectedIncident, setSelectedIncident] =
    useState<SelectedIncidentFeature>();

  const [selectedFeatures, setSelectedFeatures] =
    useState<mapboxgl.MapboxGeoJSONFeature[]>();
  const [selectedIncidents, setSelectedIncidents] =
    useState<SelectedIncidentFeature[]>();

  const map = useMap();

  const getFeaturesUnderMouse = createGetFeaturesUnderMouse(layerIds, true);

  const toIncidentFeature = (
    feature: SelectedIncidentFeature
  ): SelectedIncidentFeature => {
    const geoProps = getFeatureStyleProps(feature);
    const hasError =
      decodedErrors && decodedErrors.has(feature?.properties?.id);
    return {
      ...feature,
      properties: {
        ...feature.properties,
        ...geoProps,
        hasError,
      },
    };
  };

  useEffect(() => {
    if (snapshot && selectedIncidentId) {
      const feature = snapshot.features.find(
        (feature) => feature.properties.id === selectedIncidentId
      );

      if (feature !== undefined) {
        setSelectedIncident(toIncidentFeature(feature));
      }
    } else {
      setSelectedIncident(undefined);
    }
  }, [selectedIncidentId, snapshot]);

  useEffect(() => {
    if (snapshot && selectedFeatures) {
      const featureIds = selectedFeatures.map(
        (mFeature) => mFeature.properties?.id
      );
      const features = snapshot.features
        .filter((f) => featureIds.indexOf(f.properties.id) !== -1)
        .map((feature) => toIncidentFeature(feature));
      setSelectedIncidents(features);
    } else {
      setSelectedIncidents(undefined);
    }
  }, [selectedFeatures, snapshot]);

  const handleClick = useCallback(
    (ev: MapMouseEvent) => {
      const features = getFeaturesUnderMouse(ev, map);

      if (features.length) {
        const featureToSelect = features[0];
        changeSelectedIncident(featureToSelect?.properties?.id);
        setSelectedFeatures(features);
      } else {
        changeSelectedIncident(undefined);
        setSelectedFeatures(undefined);
      }
    },
    [snapshot]
  );

  useEffect(() => {
    map.on("click", handleClick);
    return () => {
      map.off("click", handleClick);
    };
  }, [handleClick]);

  return { selectedIncident, selectedIncidents };
};

// import { useLoadedSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
// import { createGetFeaturesUnderMouse } from "core/utils/getFeaturesUnderMouse";
// import { IncidentFeature } from "core/domain/snapshots.types";
// import { MapMouseEvent } from "mapbox-gl";
// import { useCallback, useEffect, useState } from "react";
// import { getFeatureStyleProps } from "../utils/geoStyle";
// import { useSelectedIncidentId } from "viewer/contexts/SelectedIncident";
// import { useMap } from "core/components/Map/GlMap";

// type SelectedIncidentFeature = Omit<IncidentFeature, "properties"> & {
//   properties: IncidentFeature["properties"] & {
//     lineColor?: string;
//     circleColor?: string;
//     outlineColor?: string;
//     lineWidth?: number;
//     outlineWidth?: number;
//   };
// };

// export const useSelectIncident = (layerIds: string[]) => {
//   const { snapshot, decodedErrors } = useLoadedSelectedSnapshot();

//   const { selectedIncidentId, changeSelectedIncident } =
//     useSelectedIncidentId();
//   const [selectedIncident, setSelectedIncident] =
//     useState<SelectedIncidentFeature>();

//   const map = useMap();

//   const getFeaturesUnderMouse = createGetFeaturesUnderMouse(layerIds, true);

//   useEffect(() => {
//     if (snapshot && selectedIncidentId) {
//       const feature = snapshot.features.find(
//         (feature) => feature.properties.id === selectedIncidentId
//       );

//       if (feature !== undefined) {
//         const geoProps = getFeatureStyleProps(feature);
//         const hasError =
//           decodedErrors && decodedErrors.has(feature.properties.id);
//         setSelectedIncident({
//           ...feature,
//           properties: {
//             ...feature.properties,
//             ...geoProps,
//             hasError,
//           },
//         });
//       }
//     } else {
//       setSelectedIncident(undefined);
//     }
//   }, [selectedIncidentId, snapshot]);

//   const handleClick = useCallback(
//     (ev: MapMouseEvent) => {
//       const features = getFeaturesUnderMouse(ev, map);
//       console.log(features);
//       if (features.length) {
//         const featureToSelect = features[0];
//         changeSelectedIncident(featureToSelect?.properties?.id);
//       } else {
//         changeSelectedIncident(undefined);
//       }
//     },
//     [snapshot]
//   );

//   useEffect(() => {
//     map.on("click", handleClick);
//     return () => {
//       map.off("click", handleClick);
//     };
//   }, [handleClick]);

//   return selectedIncident;
// };
