import { IncidentFeature } from "core/domain/snapshots.types";
import { getFeatureLineWidth, getFeatureOutlineWidth } from "./widths";
import { getFeatureColor, getFeatureOutlineColor } from "./colors";

export const getFeatureStyleProps = (feature: IncidentFeature) => {
  const geometryType = feature.geometry.type;

  return geometryType === "Point"
    ? { circleColor: getFeatureColor(feature) }
    : {
        lineColor: getFeatureColor(feature),
        lineWidth: getFeatureLineWidth(feature),
        outlineWidth: getFeatureOutlineWidth(feature),
        outlineColor: getFeatureOutlineColor(feature),
      };
};
