import { IncidentFeature } from "core/domain/snapshots.types";
import { ReactNode, createContext, useContext, useState } from "react";

export type IncidentFilter =
  | ((incident: IncidentFeature) => boolean)
  | undefined;

export type IncidentFilterContainer =
  | { filterFunction: IncidentFilter }
  | undefined;

interface FiltersContext {
  currentFilter?: IncidentFilterContainer;
  setFilter: (filter: IncidentFilter) => void;
  clearFilter: () => void;
}

export const FiltersContext = createContext<FiltersContext | null>(null);

interface Props {
  children: ReactNode;
}

export const useCurrentFilter = () => {
  const val = useContext(FiltersContext);
  if (!val) {
    throw Error("Component isn't in selected snapshot provider");
  }
  return val.currentFilter?.filterFunction;
};

export const useFilterActions = () => {
  const val = useContext(FiltersContext);
  if (!val) {
    throw Error("Component isn't in selected snapshot provider");
  }

  const { setFilter, clearFilter } = val;

  return {
    setFilter,
    clearFilter,
  };
};

export const FiltersProvider = (props: Props) => {
  const { children } = props;

  const [currentFilter, setCurrentFilter] =
    useState<IncidentFilterContainer>(undefined);

  const clearFilter = () => setCurrentFilter(undefined);
  const setFilter = (filter: IncidentFilter) => {
    setCurrentFilter({ filterFunction: filter });
  };

  return (
    <FiltersContext.Provider
      value={{
        currentFilter,
        setFilter,
        clearFilter,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
