import { addHours, format } from "date-fns";

const dateWithoutTimeZone = (date: Date) => {
  const offset = date.getTimezoneOffset() / 60;
  return addHours(date, offset);
};

export const formatDate = (date: Date): string =>
  format(dateWithoutTimeZone(date), "yyyy/MM/dd HH:mm:ss");

export const timestampToDateString = (timestamp: number) =>
  formatDate(new Date(new Date(timestamp * 1000).toUTCString()));
