import { ReactNode } from "react";
import { useFeed } from "./FeedContext";
import { SelectedSnapshotProvider } from "./SelectedSnapshotContext";

interface Props {
  children: ReactNode;
}

export const ConnectedSelectedSnapshotWithFeed = ({ children }: Props) => {
  const { feed } = useFeed();
  return (
    <SelectedSnapshotProvider feed={feed}>{children}</SelectedSnapshotProvider>
  );
};
