import { useState } from "react";

export function useToggleList(defaultValues: any[]) {
  const [values, setValues] = useState(defaultValues);

  const setValue = (value: any, include: boolean) =>
    setValues((values: any[]) => {
      let newValues = [...values];
      if (include && !values.includes(value)) {
        newValues.push(value);
      }
      if (!include && values.includes(value)) {
        newValues.splice(newValues.indexOf(value), 1);
      }
      return newValues;
    });

  return [values, setValue, setValues];
}
