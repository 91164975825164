import { Box } from "tombac";
import { BottomMenu } from "./BottomMenu/BottomMenu";
import { ConnectedSelectedSnapshotWithFeed } from "./contexts/ConnectedSelectedSnapshotWithFeed";
import { ConnectedSnapshotsWithFeed } from "./contexts/ConnectedSnapshotsWithFeed";
import { FeedProvider } from "./contexts/FeedContext";
import { MapView } from "./Map/MapView";
import { FiltersView } from "./Filters/FiltersView";
import { FiltersProvider } from "./contexts/FiltersContext";
import { SelectedIncidentProvider } from "./contexts/SelectedIncident";
export const Viewer = () => {
  return (
    <FeedProvider>
      <ConnectedSnapshotsWithFeed>
        <ConnectedSelectedSnapshotWithFeed>
          <SelectedIncidentProvider>
            <FiltersProvider>
              <Box $width="100vw" $display="flex" $flexDirection="row">
                <FiltersView />
                <Box
                  $height="100vh"
                  $display="flex"
                  $flexDirection="column"
                  $flexGrow="1"
                >
                  <MapView />
                  <BottomMenu />
                </Box>
              </Box>
            </FiltersProvider>
          </SelectedIncidentProvider>
        </ConnectedSelectedSnapshotWithFeed>
      </ConnectedSnapshotsWithFeed>
    </FeedProvider>
  );
};
