import { ReactNode } from "react";
import { useFeed } from "./FeedContext";
import { SnapshotsProvider } from "./SnapshotsContext";

interface Props {
  children: ReactNode;
}

export const ConnectedSnapshotsWithFeed = ({ children }: Props) => {
  const { feed } = useFeed();
  return <SnapshotsProvider feed={feed}>{children}</SnapshotsProvider>;
};
