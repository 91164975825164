import { getFeatureFRC } from "core/domain/getters";
import { IncidentFeature } from "core/domain/snapshots.types";
import { LineWidth, widths } from "./widthsValues";

export type LineStop = [number, number];

export const getFRCWidth = (frcLevel: number): LineWidth => {
  const defaultWidth = widths["2"];
  return widths["" + frcLevel] || defaultWidth;
};

export const getFRCOutlineWidth = (frcLevel: number): LineWidth => {
  const width = getFRCWidth(frcLevel);
  return width + 6;
};

export const getFeatureOutlineWidth = (feature: IncidentFeature): LineWidth => {
  const frcLevel = getFeatureFRC(feature);
  return getFRCOutlineWidth(frcLevel);
};

export const getArrowWidth = (frcLevel: number): LineWidth => {
  const lineWidth = getFRCOutlineWidth(frcLevel);
  return lineWidth - 1;
};

export const getFeatureArrowWidth = (feature: IncidentFeature) => {
  const frcLevel = getFeatureFRC(feature);
  return getArrowWidth(frcLevel);
};

export const getFeatureLineWidth = (feature: IncidentFeature): LineWidth => {
  const frcLevel = getFeatureFRC(feature);
  return getFRCWidth(frcLevel);
};
