import { BottomMenuItem } from "core/components/BottomMenuItem";
import { VerticalBox } from "core/components/CommonStyles";
import { SimpleSelect } from "core/components/Select/SimpleSelect";
import { Feed } from "core/domain/feed.types";
import { Label } from "tombac";
import { useFeed } from "viewer/contexts/FeedContext";
import { feedCfg } from "api/feed";
import styled from "styled-components";

interface Option {
  value: Feed;
  label: string;
}

const options: Option[] = feedCfg.map(({ id, label }) => ({
  value: id,
  label,
}));

export const FeedSelector = () => {
  const { feed, changeFeed } = useFeed();
  return (
    <BottomMenuItem>
      <VerticalBox>
        <Label>Feed</Label>
        <Container>
          <SimpleSelect
            options={options}
            value={feed}
            onChange={changeFeed}
            selectProps={{
              menuPlacement: "top",
            }}
          />
        </Container>
      </VerticalBox>
    </BottomMenuItem>
  );
};

const Container = styled.div`
  & > .TombacSelect {
    width: 13.8rem;
  }
`;
