import { IncidentFeature } from "core/domain/snapshots.types";
import { FeatureCollection } from "@turf/turf";
import { useLoadedSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { getFeatureStyleProps } from "../utils/geoStyle";
import { useCurrentFilter } from "viewer/contexts/FiltersContext";

export const useIncidentToDisplay = (
  selectedIncident: IncidentFeature | undefined
): FeatureCollection => {
  const { snapshot, decodedErrors } = useLoadedSelectedSnapshot();

  const currentFilter = useCurrentFilter();

  return {
    type: "FeatureCollection",
    features: snapshot.features
      .filter(
        (feature) => feature.properties.id !== selectedIncident?.properties.id
      )
      .filter((feature) => {
        if (currentFilter) {
          return currentFilter(feature);
        }
        return true;
      })
      .map((feature, i) => {
        const geoProps = getFeatureStyleProps(feature);
        const hasError =
          decodedErrors && decodedErrors.has(feature.properties.id);

        return {
          ...feature,
          id: i,
          properties: {
            ...feature.properties,
            ...geoProps,
            hasError,
          },
        };
      }),
  };
};
