import { css } from "styled-components";
import { propStyling, tombac } from "tombac";

export type selectCssProps = {
  size?: string;
};

export const selectCss = css<selectCssProps>`
  all: initial;
  display: inline-block;
  position: relative;
  width: ${tombac.space(20)};
  height: ${({ size }) => tombac.space(size === "s" ? 4 : 5)};
  ${propStyling}
`;
