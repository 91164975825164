import styled from "styled-components";
import { Button } from "tombac";
import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { useIncidentsInViewport } from "./hooks/useIncidentsInViewport";

const refreshLimit = 400;

const getIncidentsWithinLimit = (messageIds: string[]) => {
  return messageIds.length && messageIds.length < refreshLimit;
};

export const MapRefresh = ({ layerIds }: { layerIds: string[] }) => {
  const { snapshotState, refreshDecoded } = useSelectedSnapshot();
  const incidentsInViewport = useIncidentsInViewport(layerIds);

  const withinLimit = getIncidentsWithinLimit(incidentsInViewport);
  const isDisabled = snapshotState.state !== "loaded" || !withinLimit;

  const buttonHint = isDisabled
    ? "Too may or no incidents to load details"
    : "Decode geometry details";

  return (
    <Container>
      <Button
        variant="primary"
        title={buttonHint}
        disabled={isDisabled}
        onClick={() => {
          refreshDecoded(incidentsInViewport);
        }}
      >
        Load details
      </Button>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  z-index: 90;
  top: 10px;
  right: 50%;
`;
