import { HorizontalBox, VerticalBox } from "core/components/CommonStyles";
import { IncidentFeature } from "core/domain/snapshots.types";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Box, Button, Heading, Input, Text } from "tombac";
import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { bbox as calculateBbox } from "@turf/turf";
import { useGlobalMap } from "viewer/contexts/GlobalMap";
import { useSelectedIncidentId } from "viewer/contexts/SelectedIncident";
import { CancelIcon } from "tombac-icons";

export const SearchIncident = () => {
  const [searchId, setSearchId] = useState<string>("");
  const [version, setVersion] = useState<string>("");
  const [foundedIncident, setFoundedIncident] = useState<IncidentFeature>();
  const { snapshotState } = useSelectedSnapshot();
  const { changeSelectedIncident } = useSelectedIncidentId();
  const map = useGlobalMap();

  const shouldDisableButton = !foundedIncident || (!searchId && !version);

  const createHandleChange =
    (setFunc: (val: string) => void): ChangeEventHandler<HTMLInputElement> =>
    (ev) => {
      setFunc(ev.target.value);
    };

  useEffect(() => {
    if (snapshotState.state === "loaded") {
      setFoundedIncident(
        snapshotState.snapshot.features.find(
          (feature) =>
            (searchId !== ""
              ? Number(searchId) === feature.properties.messageManagement.Id
              : true) &&
            (version !== ""
              ? Number(version) === feature.properties.messageManagement.version
              : true)
        )
      );
    }
  }, [searchId, snapshotState, version]);

  const handleClick = () => {
    if (foundedIncident && map) {
      const bbox = calculateBbox(foundedIncident.geometry) as [
        number,
        number,
        number,
        number
      ];
      map.fitBounds(bbox, { duration: 250, padding: 150 });
      changeSelectedIncident(foundedIncident.properties.id);
    }
  };

  return (
    <Box $borderBottom="1px solid #e5e5e5" $p="12px 24px">
      <Heading level={3} $mb="12px">
        Search incident
      </Heading>
      <VerticalBox $gap="8px">
        <HorizontalBox $gap="8px" $alignItems="center">
          <Input
            type="number"
            value={searchId}
            onChange={createHandleChange(setSearchId)}
            placeholder={"Message id..."}
            $width="100%"
          />

          <Button
            shape="circle"
            prepend={<CancelIcon />}
            size="s"
            variant="flat"
            onClick={() => setSearchId("")}
          />
        </HorizontalBox>
        <HorizontalBox $gap="8px" $alignItems="center">
          <Input
            type="number"
            value={version}
            onChange={createHandleChange(setVersion)}
            placeholder={"Version.."}
            $width="100%"
          />
          <Button
            shape="circle"
            prepend={<CancelIcon />}
            size="s"
            variant="flat"
            onClick={() => setVersion("")}
          />
        </HorizontalBox>
      </VerticalBox>
      <Box $mt="12px">
        <Button
          variant="ghost"
          disabled={shouldDisableButton}
          onClick={handleClick}
          $width="100%"
        >
          Go
        </Button>
      </Box>
      {(searchId !== "" || version !== "") && !foundedIncident && (
        <Box $mt="4px">
          <Text>No incident with this ID and version</Text>
        </Box>
      )}
    </Box>
  );
};
