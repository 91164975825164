import { AppError } from "api/errors";
import { SnapshotsApi } from "api/snapshots.api";
import { Feed } from "core/domain/feed.types";
import { Snapshots } from "core/domain/snapshots.types";
import { subMilliseconds } from "date-fns";
import { addHours } from "date-fns/esm";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type SnapshotsState =
  | { state: "loading" }
  | { state: "loaded"; snapshots: Snapshots }
  | { state: "not-selected" }
  | { state: "error"; error: AppError };

interface SnapshotsContext {
  snapshotsState: SnapshotsState;
  startDate: Date | null;
  endDate: Date | null;
  changeStartISODate: (date: Date) => void;
}

const SnapshotsContext = createContext<SnapshotsContext | null>(null);

export const useSnapshots = () => {
  const val = useContext(SnapshotsContext);
  if (!val) {
    throw Error("Component is't in SnapshotsContextProvider");
  }
  return val;
};

interface Props {
  children: ReactNode;
  feed: Feed;
}

export const SnapshotsProvider = ({ children, feed }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [snapshotsState, setSnapshotsState] = useState<SnapshotsState>({
    state: "not-selected",
  });

  const endDate = useMemo(() => {
    if (startDate) {
      return subMilliseconds(addHours(startDate, 1), 1);
    }
    return null;
  }, [startDate]);

  const value = useMemo(
    () => ({
      startDate,
      endDate,
      snapshotsState,
      changeStartISODate: (date: Date) => {
        if (
          date.getMinutes() !== 0 ||
          date.getSeconds() !== 0 ||
          date.getMilliseconds() !== 0
        ) {
          throw Error("provided wrong date value");
        }
        setStartDate(date);
      },
    }),
    [startDate, endDate, snapshotsState]
  );

  useEffect(() => {
    if (startDate && endDate) {
      setSnapshotsState({ state: "loading" });
      SnapshotsApi.getSnapshotsInRange({ feed, startDate, endDate }).then(
        (snapshots) => setSnapshotsState({ state: "loaded", snapshots }),
        (error: AppError) =>
          setSnapshotsState({
            state: "error",
            error: { ...error, details: `Couldn't load snapshots list` },
          })
      );
    } else {
      setSnapshotsState({ state: "not-selected" });
    }
  }, [startDate, endDate, feed]);

  return (
    <SnapshotsContext.Provider value={value}>
      {children}
    </SnapshotsContext.Provider>
  );
};
