import styled from "styled-components";
import { Box } from "tombac";

export const Medium = styled.span`
  font-weight: 500;
`;

export const HorizontalBox = styled(Box)`
  display: flex;
`;

export const VerticalBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const PointerBox = styled(Box)`
  cursor: Pointer;
`;
