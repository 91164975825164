export enum EffectCode {
  EFFECT_CODE_INVALID = "EFFECT_CODE_INVALID",
  EFFECT_CODE_UNKNOWN_TRAFFIC_FLOW = "EFFECT_CODE_UNKNOWN_TRAFFIC_FLOW",
  EFFECT_CODE_FREE_TRAFFIC_FLOW = "EFFECT_CODE_FREE_TRAFFIC_FLOW",
  EFFECT_CODE_HEAVY_TRAFFIC = "EFFECT_CODE_HEAVY_TRAFFIC",
  EFFECT_CODE_SLOW_TRAFFIC = "EFFECT_CODE_SLOW_TRAFFIC",
  EFFECT_CODE_QUEUING_TRAFFIC = "EFFECT_CODE_QUEUING_TRAFFIC",
  EFFECT_CODE_STATIONARY_TRAFFIC = "EFFECT_CODE_STATIONARY_TRAFFIC",
  EFFECT_CODE_CLOSED = "EFFECT_CODE_CLOSED",
}

export const AllEffectCodes = Object.values(EffectCode);
