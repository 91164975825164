import { Layer } from "core/components/MapBoxComponents/Layer";
import { MapIconImage } from "core/components/MapBoxComponents/MapIconImage";
import { Source } from "core/components/MapBoxComponents/Source";
import { Expression } from "mapbox-gl";
import { useHoverIncident } from "./hooks/useHoverIncident";
import { useIncidentToDisplay } from "./hooks/useIncidentToDisplay";
import { useSelectIncident } from "./hooks/useSelectIncident";
import arrowTriangle from "./arrow-triangle-alpha.svg";
// import { SelectedIncident } from "./SelectedIncident/SelectedIncident";
import { SelectedIncidents } from "./SelectedIncident/SelectedIncidents";
import { featureCollection, FeatureCollection } from "@turf/turf";

// import { useLoadedSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { MapRefresh } from "./MapRefresh";

const createInterpolation = (property: string) =>
  [
    "interpolate",
    ["linear"],
    ["zoom"],
    2.5,
    0,
    20,
    ["get", property],
  ] as Expression;

const layoutProps = {
  "line-cap": "round",
  "line-join": "round",
  "line-round-limit": 1.05,
} as const;

const radiusStops = [
  [2, 2],
  [8, 4],
  [11, 8],
  [16, 20],
];

const strokeWidthStops = [
  [2, 0],
  [8, 1],
  [12, 2],
];

interface CollectionFeatureType {
  [geoType: string]: FeatureCollection;
}
const divideCollectionByFeatureType = (
  snapshot: FeatureCollection
): CollectionFeatureType => {
  return snapshot.features.reduce((divided: CollectionFeatureType, feature) => {
    const fType = feature.geometry.type as string;
    if (divided[fType] === undefined) {
      divided[fType] = featureCollection([]);
    }

    divided[fType].features.push(feature);
    return divided;
  }, {});
};

const pointLayers = ["incidents-preview-layer-0"];
const lineLayers = ["incidents-layer-0", "incidents-layer-1"];
const relevantLayers = [...pointLayers, ...lineLayers];

export const SnapshotMergedView = () => {
  const { selectedIncident, selectedIncidents } =
    useSelectIncident(relevantLayers);

  const featureCollection = useIncidentToDisplay(selectedIncident);

  const fCollectionByType = divideCollectionByFeatureType(featureCollection);
  const { Point: pointFeatureCollection, LineString: lineFeatureCollection } =
    fCollectionByType;

  useHoverIncident(relevantLayers);
  return (
    <>
      <MapRefresh layerIds={[...pointLayers, "selected-incident-layer-6"]} />
      {selectedIncident && (
        <SelectedIncidents
          selectedIncident={selectedIncident}
          selectedIncidents={selectedIncidents}
        />
      )}
      {pointFeatureCollection && (
        <Source feature={pointFeatureCollection} id="incidents-preview">
          <Layer
            index={0}
            type="circle"
            paint={{
              "circle-color": [
                "case",
                ["==", ["feature-state", "hover"], 1],
                "#222",
                ["==", ["get", "hasError"], true],
                "#aaa",
                ["get", "circleColor"],
              ],
              "circle-stroke-color": "white",
              "circle-stroke-width": { stops: strokeWidthStops },
              "circle-radius": {
                stops: radiusStops,
              },
            }}
          />
        </Source>
      )}
      {lineFeatureCollection && (
        <Source feature={lineFeatureCollection} id="incidents">
          <Layer
            index={0}
            type="line"
            layout={layoutProps}
            paint={{
              "line-color": "white",
              "line-width": createInterpolation("outlineWidth"),
            }}
          />
          <Layer
            index={1}
            layout={layoutProps}
            type="line"
            paint={{
              "line-color": [
                "case",
                ["==", ["feature-state", "hover"], 1],
                "#222",
                ["get", "lineColor"],
              ],
              "line-width": createInterpolation("lineWidth"),
            }}
          />
        </Source>
      )}
      {selectedIncident && (
        <Source feature={selectedIncident} id="selected-incident">
          {selectedIncident.geometry.type === "Point" ? (
            <Layer
              index={6}
              type="circle"
              paint={{
                "circle-color": "white",
                "circle-stroke-color": ["get", "circleColor"],
                "circle-stroke-width": 3,
                "circle-radius": {
                  stops: radiusStops,
                },
              }}
            />
          ) : (
            <>
              <MapIconImage id="arrow-triangle" src={arrowTriangle} />
              <Layer
                index={0}
                layout={layoutProps}
                type="line"
                paint={{
                  "line-color": ["get", "outlineColor"],
                  "line-width": createInterpolation("outlineWidth"),
                }}
              />
              <Layer
                index={1}
                layout={layoutProps}
                type="line"
                paint={{
                  "line-color": ["get", "lineColor"],
                  "line-width": createInterpolation("lineWidth"),
                }}
              />
              <Layer
                type="symbol"
                index={2}
                layout={{
                  "icon-size": [
                    "max",
                    ["min", ["/", ["get", "lineWidth"], 8], 1.0],
                  ],
                  "icon-image": "arrow-triangle",
                  "symbol-avoid-edges": true,
                  "symbol-placement": "line",
                  "symbol-spacing": 10,
                }}
              />
            </>
          )}
        </Source>
      )}{" "}
    </>
  );
};
