import styled from "styled-components";

export const BottomMenuItem = styled.div<{ disableBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: ${({ disableBorder }) =>
    disableBorder ? "none" : "1px solid #e5e5e5"};
  padding: 0 12px;
  z-index: 9999;
`;
