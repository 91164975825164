import styled from "styled-components";

// export const PropFilterContainer = styled.div`

// `;

export const CheckboxLabel = styled.div`
  display: inline-block;
  font-size: 12px;
  margin-left: 4px;
`;
