export interface ResponseErrorCode {
  status: number;
  label: string;
}
export interface AppError {
  label: string;
  statusText?: string;
  status?: number;
  details?: string;
  name?: string;
}

const RESPONSE_ERROR_CODES: ResponseErrorCode[] = [
  { status: 400, label: "Bad Request" },
  { status: 403, label: "Forbidden" },
  { status: 408, label: "Request Timeout" },
  { status: 500, label: "Internal Server Error" },
  { status: 503, label: "Service Unavailable" },
];
const DEFAULT_ERROR_LABEL = "Error";
const UNKNOWN_ERROR = {
  label: "Something went wrong",
};

export const getError = (response?: Response): AppError => {
  if (!response) {
    return UNKNOWN_ERROR;
  }
  const { status, statusText } = response;
  const responseCfg = RESPONSE_ERROR_CODES.find((e) => e.status === status);
  const label = responseCfg ? responseCfg.label : DEFAULT_ERROR_LABEL;

  return {
    status,
    label,
    statusText,
  };
};
