import { useCallback, useEffect, useState } from "react";

export const useActiveDropZone = () => {
  const [isActive, setIsActive] = useState(false);

  const open = useCallback(() => setIsActive(true), []);
  const close = useCallback((ev: DragEvent) => {
    if (!ev.clientX && !ev.clientY) {
      setIsActive(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("dragenter", open);
    window.addEventListener("dragleave", close);
    return () => {
      window.removeEventListener("dragenter", open);
      window.removeEventListener("dragleave", close);
    };
  }, [open, close]);

  return [isActive, () => setIsActive(false)] as const;
};
