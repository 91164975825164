import styled from "styled-components";

export const SelectorContainer = styled.div`
  position: relative;
  padding: 0 12px;
  border-right: 1px solid #e5e5e5;
  min-width: 140px;
`;

export const DateTimeSelectorContainer = styled.div`
  position: absolute;
  bottom: 72px;
  left: 0;
  background-color: white;
  z-index: 100;
`;

export const DaySelectorContainer = styled.div`
  max-height: 500px;
  overflow: auto;
  padding: 0 24px;
`;

export const DaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
`;

export const Day = styled.div<{ selected?: boolean }>`
  box-sizing: border-box;
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: ${({ selected }) =>
    selected ? "hsl(204, 67%, 50%)" : "1px solid #e5e5e5"};
  color: ${({ selected }) => (selected ? "white" : "black")};
  background-color: ${({ selected }) =>
    selected ? "hsl(206, 74%, 85%)" : "white"};

  &:hover {
    background-color: ${({ selected }) =>
      selected ? "hsl(206, 70%, 74%)" : "#f2f2f2"};
  }
`;

export const SelectedRange = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: hsla(0, 0%, 0%, 0.4);
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
