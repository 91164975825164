import styled from "styled-components";
import { GlMap } from "core/components/Map/GlMap";
import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { MapLoading } from "./MapLoading";
import { Search } from "./Search";
import { SnapshotMergedView } from "./SnapshotMergedView";
import { timestampToDateString } from "core/utils/formatDate";
import { Error } from "core/components/Error";

export const MapView = () => {
  const { snapshotState, selectedSnapshotTimestamp } = useSelectedSnapshot();

  const showLoading =
    snapshotState.state === "loading" || snapshotState.state === "decoding";
  const showSnapshot =
    snapshotState.state === "loaded" || snapshotState.state === "decoding";

  return (
    <GlMap controlLocation="bottom-left">
      {(snapshotState.state === "error" || snapshotState.state === "loaded") &&
        snapshotState.error && (
          <Error
            error={snapshotState.error}
            type={snapshotState.state === "error" ? "danger" : "alert"}
            id="snapshot-error"
          />
        )}
      <Search />
      {showLoading && <MapLoading />}
      {showSnapshot && <SnapshotMergedView />}
      {snapshotState.state === "loaded" &&
        selectedSnapshotTimestamp !== null && (
          <SnapshotInfo>
            Snapshot: {timestampToDateString(selectedSnapshotTimestamp)}
          </SnapshotInfo>
        )}
    </GlMap>
  );
};

const SnapshotInfo = styled.div`
  position: absolute;
  z-index: 90;
  bottom: 8px;
  left: 60px;
  font-family: Noway;
  font-size: 12px;
`;
