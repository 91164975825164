import { SnapshotsApi } from "api/snapshots.api";
import { Feed } from "core/domain/feed.types";
import { AppError } from "api/errors";
import { Snapshot, SnapshotTimestamp } from "core/domain/snapshots.types";
import {
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { createSnapshotsActions } from "./createSnapshotsActions";
import { createSnapshotsWithId } from "./createSnapshotsWithId";
import { createSnapshotsFromProto } from "./createSnapshotsFromProto";
import { timestampToDateString } from "core/utils/formatDate";

export type SnapshotState =
  | { state: "not-selected" }
  | { state: "loading" }
  | {
      state: "loaded";
      snapshot: Snapshot;
      decodedErrors?: Set<string>;
      error?: AppError;
    }
  | {
      state: "decoding";
      snapshot: Snapshot;
      decodedErrors?: Set<string>;
    }
  | {
      state: "error";
      error: AppError;
    };

export interface CurrentSnapshots {
  source: Source;
  snapshot: SnapshotTimestamp | undefined;
  prevSnapshot: SnapshotTimestamp | undefined;
  nextSnapshot: SnapshotTimestamp | undefined;
}

type Source = "APP" | "EXTERNAL";
interface ContextValue {
  selectedSnapshotTimestamp: SnapshotTimestamp | null;
  isExternalSource: boolean;
  changeCurrentSnapshots: (params: Omit<CurrentSnapshots, "source">) => void;
  changeLoadedSnapshot: (snapshot: Snapshot) => void;
  goNext: (newNext: SnapshotTimestamp | undefined) => void;
  goPrev: (newPev: SnapshotTimestamp | undefined) => void;
  clear: VoidFunction;
  snapshotState: SnapshotState;
  refreshDecoded: (messageIds: string[]) => void;
}

const SelectedSnapshotContext = createContext<ContextValue | null>(null);

export const useSelectedSnapshot = () => {
  const val = useContext(SelectedSnapshotContext);
  if (!val) {
    throw Error("Component isn't in selected snapshot provider");
  }
  return val;
};

export const useLoadedSelectedSnapshot = () => {
  const val = useContext(SelectedSnapshotContext);
  if (
    val?.snapshotState.state === "loaded" ||
    val?.snapshotState.state === "decoding"
  ) {
    return val.snapshotState;
  }

  throw new Error("Snapshot isn't loaded");
};

export const useExternalSnapshot = () => {
  const val = useContext(SelectedSnapshotContext);
  if (!val?.isExternalSource) {
    throw new Error("Snapshot isn't from external source");
  }
  if (
    val?.snapshotState?.state === "loaded" ||
    val?.snapshotState?.state === "decoding"
  ) {
    return { snapshot: val.snapshotState.snapshot, clear: val.clear };
  }

  throw new Error("Snapshot isn't loaded yet ");
};

interface Props {
  children: ReactNode;
  feed: Feed;
}

const mergeSnapshots = (
  preview: Snapshot,
  decoded: Snapshot | undefined
): Snapshot => {
  if (decoded === undefined || decoded.features.length === 0) {
    return preview;
  }

  const mergedFeatures = preview.features.map((pFeature) => {
    const decodedFeature = decoded.features.find(
      (dFeature) =>
        dFeature.properties.messageManagement.Id ===
        pFeature.properties.messageManagement.Id
    );
    return decodedFeature || pFeature;
  });

  return {
    ...preview,
    features: mergedFeatures,
  };
};

export const SelectedSnapshotProvider = ({ children, feed }: Props) => {
  const nextSnapshot = useRef<SnapshotState>({ state: "not-selected" });
  const prevSnapshot = useRef<SnapshotState>({ state: "not-selected" });

  const [currentSnapshots, setCurrentSnapshots] = useState<CurrentSnapshots>({
    source: "APP",
    prevSnapshot: undefined,
    nextSnapshot: undefined,
    snapshot: undefined,
  });

  const [snapshotState, setSnapshotState] = useState<SnapshotState>({
    state: "not-selected",
  });

  const goNext = useCallback((newNext: SnapshotTimestamp | undefined) => {
    setSnapshotState(nextSnapshot.current);
    setCurrentSnapshots((prevState) => {
      const snapshot = prevState.nextSnapshot;
      const prevSnapshot = prevState.snapshot;
      return { ...prevState, prevSnapshot, snapshot, nextSnapshot: newNext };
    });
  }, []);

  const changeCurrentSnapshots = useCallback(
    (snapshots: Omit<CurrentSnapshots, "source">) => {
      setCurrentSnapshots({ ...snapshots, source: "APP" });
    },
    []
  );

  const goPrev = useCallback((newPrev: SnapshotTimestamp | undefined) => {
    setSnapshotState(prevSnapshot.current);

    setCurrentSnapshots((prevState) => {
      const snapshot = prevState.prevSnapshot;
      const nextSnapshot = prevState.snapshot;
      return { ...prevState, nextSnapshot, snapshot, prevSnapshot: newPrev };
    });
  }, []);

  const clear = () => {
    setSnapshotState({ state: "not-selected" });
    setCurrentSnapshots({
      nextSnapshot: undefined,
      prevSnapshot: undefined,
      snapshot: undefined,
      source: "APP",
    });
  };

  // useEffect(clear, [feed]);
  useEffect(() => {
    if (currentSnapshots.source === "APP") {
      clear();
    }
  }, [feed]);

  useEffect(() => {
    const abortController = new AbortController();
    if (currentSnapshots.source === "APP") {
      const actions = createSnapshotsActions({
        currentSnapshots,
        changeNextSnapshot: (state) => (nextSnapshot.current = state),
        changePrevSnapshot: (state) => (prevSnapshot.current = state),
        changeSnapshot: (state) => setSnapshotState(state),
      });

      const notSelectedSnapshots = actions.filter(({ value }) => !value);
      notSelectedSnapshots.forEach(({ setAction }) =>
        setAction({ state: "not-selected" })
      );

      const snapshotsToFetch = actions.filter(({ value }) => !!value);
      snapshotsToFetch.forEach(({ setAction }) =>
        setAction({ state: "loading" })
      );

      snapshotsToFetch.map(({ value, setAction }) =>
        SnapshotsApi.getSnaphotProtoByTimestamp({
          feed,
          timestamp: value,
          signal: abortController.signal,
        }).then(
          async (data) => {
            const snapshotPreview = await createSnapshotsFromProto(data);
            const snapshotDecoded = SnapshotsApi.getDecodedSnapshotCached({
              feed,
              timestamp: value,
            });
            const mergedSnapshot = mergeSnapshots(
              snapshotPreview,
              snapshotDecoded && createSnapshotsWithId(snapshotDecoded)
            );
            const decodedErrors = SnapshotsApi.getDecodedErrors({
              feed,
              timestamp: value,
            });

            return setAction({
              state: "loaded",
              snapshot: mergedSnapshot,
              decodedErrors,
            });
          },
          (error: AppError) => {
            if (error.name && error.name === "AbortError") {
              return;
            }

            return setAction({
              state: "error",
              error: {
                ...error,
                details: `Couldn't fetch snapshot ${timestampToDateString(
                  value
                )}`,
              },
            });
          }
        )
      );
    }
    return () => {
      if (currentSnapshots.source === "APP") abortController.abort();
    };
  }, [currentSnapshots, feed]);

  const refreshDecoded = (messageIds: string[]) => {
    if (
      messageIds.length === 0 ||
      !currentSnapshots?.snapshot ||
      snapshotState?.state !== "loaded"
    ) {
      return;
    }

    const timestamp = currentSnapshots?.snapshot;
    // const feedId =
    //   currentSnapshots.source === "EXTERNAL"
    //     ? snapshotState?.snapshot.metadata.feedId
    //     : feed;
    const feedId = feed;

    setSnapshotState({ ...snapshotState, state: "decoding" });
    SnapshotsApi.getDecodedSnapshot({
      feed: feedId,
      timestamp,
      messageIds,
    }).then(
      (data) => {
        if (data) {
          const snapshotsWithId = createSnapshotsWithId(data);
          const mergedSnapshot = mergeSnapshots(
            snapshotState.snapshot,
            snapshotsWithId
          );
          const decodedErrors = SnapshotsApi.getDecodedErrors({
            feed,
            timestamp,
          });

          setSnapshotState({
            state: "loaded",
            snapshot: mergedSnapshot,
            decodedErrors,
          });
        }
      },
      (error: AppError) => {
        setSnapshotState({
          ...snapshotState,
          state: "loaded",
          error: {
            ...error,
            details: `Couldn't load details`,
          },
        });
      }
    );
  };

  const changeLoadedSnapshot = (snapshot: Snapshot) => {
    setSnapshotState({
      state: "loaded",
      snapshot: snapshot,
    });
    setCurrentSnapshots({
      nextSnapshot: undefined,
      prevSnapshot: undefined,
      snapshot: snapshot.metadata.creationTime,
      source: "EXTERNAL",
    });
  };

  return (
    <SelectedSnapshotContext.Provider
      value={{
        changeLoadedSnapshot,
        selectedSnapshotTimestamp: currentSnapshots?.snapshot || null,
        changeCurrentSnapshots,
        goNext,
        goPrev,
        clear,
        snapshotState,
        isExternalSource: currentSnapshots.source === "EXTERNAL",
        refreshDecoded,
      }}
    >
      {children}
    </SelectedSnapshotContext.Provider>
  );
};
