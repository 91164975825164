export const createGetFeaturesUnderMouse =
  (layers: string[], checkLayers = false) =>
  (e: mapboxgl.MapMouseEvent, map: mapboxgl.Map) => {
    const width = map.getZoom() / 2;
    const bbox = [
      [e.point.x - width, e.point.y - width],
      [e.point.x + width, e.point.y + width],
    ] as any;
    const layersToInspect = checkLayers
      ? layers.filter((layerId) => map.getLayer(layerId))
      : layers;
    const features = map.queryRenderedFeatures(bbox, {
      layers: layersToInspect,
    });
    return features;
  };
