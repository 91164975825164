import {
  Feature,
  FeatureCollection as FeatureCollectionType,
} from "@turf/turf";
import { GeoJSONSource } from "mapbox-gl";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMap } from "../Map/GlMap";

interface Props {
  feature: Feature | FeatureCollectionType;
  id: string;
  children: ReactNode;
}

interface ContextValue {
  id: string;
  sourceId: string;
}

const SourceContext = createContext<ContextValue | null>(null);

export const useSource = () => {
  const val = useContext(SourceContext);
  if (val === null) {
    throw new Error("Feature is not provided");
  }

  return val;
};

const createSourceId = (id: string) => `${id}-source`;

export const Source = ({ feature, id, children }: Props) => {
  const map = useMap();
  const [sourceId, setSourceId] = useState("");
  useEffect(() => {
    const sourceId = createSourceId(id);

    map.addSource(sourceId, {
      type: "geojson",
      data: feature as any,
    });

    setSourceId(sourceId);

    return () => {
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, []);

  useEffect(() => {
    const source = map.getSource(sourceId) as GeoJSONSource;
    if (source) {
      source.setData(feature as any);
    }
  }, [feature]);

  return (
    <SourceContext.Provider value={{ id, sourceId }}>
      {children}
    </SourceContext.Provider>
  );
};
