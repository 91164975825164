const reineStyle = {
    layers: [
      {
        id: 'Secondary road REINE - Impassable',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Secondary road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: { 'line-join': 'round', 'line-cap': 'square' },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
          'line-dasharray': [2, 3],
        },
      },
      {
        id: 'Motorway REINE - Impassable',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Motorway',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'Minor local road REINE - Impassable',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Minor local road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
          'line-gap-width': 0,
        },
      },
      {
        id: 'International road Reine - Impassable',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'International road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': 1,
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'Major road Reine - Impassable',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Major road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'Local road REINE - Impassable',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Local road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [14, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'Motorway REINE - Lines',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Motorway',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(211, 180, 157, 1)',
          'line-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'Motorway REINE - Nodes',
        type: 'circle',
        source: 'reine-edges-end',
        'source-layer': 'Motorway',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-stroke-color': 'rgba(211, 180, 157, 1)',
          'circle-color': 'rgba(255, 241, 219, 1)',
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
              [23, 5],
            ],
          },
        },
      },
      {
        id: 'International road Reine - Lines',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'International road',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(211, 180, 157, 1)',
          'line-width': 1,
          'line-dasharray': [1],
        },
      },
      {
        id: 'International road Reine - Nodes',
        type: 'circle',
        source: 'reine-edges-end',
        'source-layer': 'International road',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: { visibility: 'visible' },
        paint: {
          'circle-color': 'rgba(255, 244, 200, 1)',
          'circle-stroke-color': 'rgba(211, 180, 157, 1)',
          'circle-stroke-width': 1,
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
        },
      },
      {
        id: 'Secondary road REINE - Lines',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Secondary road',
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'Secondary road REINE - Nodes',
        type: 'circle',
        source: 'reine-edges-end',
        'source-layer': 'Secondary road',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-color': 'rgba(255, 255, 255, 1)',
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
          'circle-stroke-opacity': 1,
          'circle-opacity': 1,
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'Major road Reine - Lines',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Major road',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [1],
        },
      },
      {
        id: 'Major road Reine - Nodes',
        type: 'circle',
        source: 'reine-edges-end',
        'source-layer': 'Major road',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-color': 'rgba(255, 255, 255, 1)',
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-stroke-width': 1,
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
        },
      },
      {
        id: 'Local road REINE - Lines',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Local road',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [14, 1],
              [15, 1],
              [16, 1],
              [17, 1],
              [18, 1],
            ],
          },
          'line-dasharray': [1],
        },
      },
      {
        id: 'Local road REINE - Nodes',
        type: 'circle',
        source: 'reine-edges-end',
        'source-layer': 'Local road',
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-color': 'rgba(255, 255, 255, 1)',
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'Minor local road REINE - Lines',
        type: 'line',
        source: 'reine-edges',
        'source-layer': 'Minor local road',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [1],
        },
      },
      {
        id: 'Minor local road REINE - Nodes',
        type: 'circle',
        source: 'reine-edges-end',
        'source-layer': 'Minor local road',
        minzoom: 13,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
          'circle-color': 'rgba(255, 255, 255, 1)',
        },
      },
      {
        id: 'japan Secondary road REINE - Impassable',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Secondary road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: { 'line-join': 'round', 'line-cap': 'square' },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
          'line-dasharray': [2, 3],
        },
      },
      {
        id: 'japan Motorway REINE - Impassable',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Motorway',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'japan Minor local road REINE - Impassable',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Minor local road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
          'line-gap-width': 0,
        },
      },
      {
        id: 'japan International road Reine - Impassable',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'International road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': 1,
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'japan Major road Reine - Impassable',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Major road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'square',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'japan Local road REINE - Impassable',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Local road',
        filter: ['any', ['==', 'navigable', false], ['==', 'underConstruction', true]],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(223, 27, 18, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [14, 1],
            ],
          },
          'line-dasharray': [2, 3],
          'line-opacity': {
            stops: [
              [13, 0.4],
              [22, 1],
            ],
          },
        },
      },
      {
        id: 'japan Motorway REINE - Lines',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Motorway',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(211, 180, 157, 1)',
          'line-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'japan Motorway REINE - Nodes',
        type: 'circle',
        source: 'japan-reine-edges-end',
        'source-layer': 'Motorway',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-stroke-color': 'rgba(211, 180, 157, 1)',
          'circle-color': 'rgba(255, 241, 219, 1)',
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
              [23, 5],
            ],
          },
        },
      },
      {
        id: 'japan International road Reine - Lines',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'International road',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(211, 180, 157, 1)',
          'line-width': 1,
          'line-dasharray': [1],
        },
      },
      {
        id: 'japan International road Reine - Nodes',
        type: 'circle',
        source: 'japan-reine-edges-end',
        'source-layer': 'International road',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: { visibility: 'visible' },
        paint: {
          'circle-color': 'rgba(255, 244, 200, 1)',
          'circle-stroke-color': 'rgba(211, 180, 157, 1)',
          'circle-stroke-width': 1,
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
        },
      },
      {
        id: 'japan Secondary road REINE - Lines',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Secondary road',
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'japan Secondary road REINE - Nodes',
        type: 'circle',
        source: 'japan-reine-edges-end',
        'source-layer': 'Secondary road',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-color': 'rgba(255, 255, 255, 1)',
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
          'circle-stroke-opacity': 1,
          'circle-opacity': 1,
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'japan Major road Reine - Lines',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Major road',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [1],
        },
      },
      {
        id: 'japan Major road Reine - Nodes',
        type: 'circle',
        source: 'japan-reine-edges-end',
        'source-layer': 'Major road',
        minzoom: 13,
        maxzoom: 24,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-color': 'rgba(255, 255, 255, 1)',
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-stroke-width': 1,
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
        },
      },
      {
        id: 'japan Local road REINE - Lines',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Local road',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [14, 1],
              [15, 1],
              [16, 1],
              [17, 1],
              [18, 1],
            ],
          },
          'line-dasharray': [1],
        },
      },
      {
        id: 'japan Local road REINE - Nodes',
        type: 'circle',
        source: 'japan-reine-edges-end',
        'source-layer': 'Local road',
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-color': 'rgba(255, 255, 255, 1)',
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
        },
      },
      {
        id: 'japan Minor local road REINE - Lines',
        type: 'line',
        source: 'japan-reine-edges',
        'source-layer': 'Minor local road',
        filter: ['==', 'navigable', true],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
          'line-round-limit': 1.05,
        },
        paint: {
          'line-color': 'rgba(190, 190, 190, 1)',
          'line-width': {
            stops: [
              [13, 0.1],
              [13.5, 1],
            ],
          },
          'line-dasharray': [1],
        },
      },
      {
        id: 'japan Minor local road REINE - Nodes',
        type: 'circle',
        source: 'japan-reine-edges-end',
        'source-layer': 'Minor local road',
        minzoom: 13,
        filter: ['==', 'navigable', true],
        layout: {},
        paint: {
          'circle-radius': {
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          },
          'circle-stroke-color': 'rgba(190, 190, 190, 1)',
          'circle-stroke-width': {
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          },
          'circle-color': 'rgba(255, 255, 255, 1)',
        },
      },
    ],
  };
  export default reineStyle;