import { Map as MapboxMap, NavigationControl } from "mapbox-gl";
// import SearchBox from "vector-maps/dist/features/search/SearchBox";
import { createContext, ReactNode, useContext, useEffect, useRef } from "react";
import { useGlobalMap } from "viewer/contexts/GlobalMap";
import { MapContainer, MapCopyrightContainer } from "./Map.style";
import { useJapanFilter } from "./useJapanFIlter";

import { useFeed } from "viewer/contexts/FeedContext";
import { getFeedBBox } from "api/feed";

type ControlLocation =
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left";
interface Props {
  children?: ReactNode;
  controlLocation?: ControlLocation;
}
const currentYear = new Date().getFullYear();

const MapContext = createContext<null | MapboxMap>(null);

export const useMap = (): MapboxMap => {
  const map = useContext(MapContext);
  if (!map) {
    throw new Error("Component must be children of Map");
  }

  return map;
};

export const GlMap = ({ children, controlLocation = "top-right" }: Props) => {
  const globalMap = useGlobalMap();
  const containerRef = useRef<HTMLDivElement>(null);
  const { feed } = useFeed();

  useEffect(() => {
    if (globalMap) {
      containerRef.current?.appendChild(globalMap.getContainer());
      window.dispatchEvent(new Event("resize"));
    }
  }, [globalMap]);

  useEffect(() => {
    const control = new NavigationControl();
    if (globalMap) {
      globalMap.addControl(control, controlLocation);
    }

    return () => {
      if (globalMap) {
        globalMap.removeControl(control);
      }
    };
  }, [controlLocation, globalMap]);

  useEffect(() => {
    if (!globalMap) {
      return;
    }

    const feedBBox = getFeedBBox(feed);
    if (feedBBox) {
      globalMap.fitBounds(feedBBox, { padding: 20, linear: true });
    }
  }, [feed, globalMap]);

  useJapanFilter(globalMap);

  return (
    <MapContainer ref={containerRef}>
      {globalMap && (
        <MapContext.Provider value={globalMap}>{children}</MapContext.Provider>
      )}
      <MapCopyrightContainer>
        <a
          href="https://www.tomtom.com/en_gb/thirdpartyproductterms/"
          title="TomTom's copyrights"
          target="_blank"
          rel="noreferrer"
        >
          © TomTom 1992 – {currentYear}
        </a>
        <a
          href="https://www.maptiler.com/copyright/"
          target="_blank"
          rel="noreferrer"
        >
          © MapTiler
        </a>
        <a
          href="https://www.openstreetmap.org/copyright"
          target="_blank"
          rel="noreferrer"
        >
          © OpenStreetMap contributors
        </a>
      </MapCopyrightContainer>
    </MapContainer>
  );
};
