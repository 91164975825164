import { Feed } from "core/domain/feed.types";
import { empty } from "ramda";
import { createContext, ReactNode, useContext, useState } from "react";
import { feedCfg } from "api/feed";

interface ContextValue {
  feed: Feed;
  changeFeed: (feed: Feed) => void;
}

const FeedContext = createContext<ContextValue>({
  feed: feedCfg[0].id,
  changeFeed: empty,
});

export const useFeed = () => useContext(FeedContext);

interface Props {
  children: ReactNode;
}

export const FeedProvider = ({ children }: Props) => {
  const [feed, changeFeed] = useState<Feed>(feedCfg[0].id);

  return (
    <FeedContext.Provider value={{ feed, changeFeed }}>
      {children}
    </FeedContext.Provider>
  );
};
