import styled, { css } from "styled-components";

import { Heading } from "tombac";

export const SelectedIncidentsContainer = styled.div`
  position: absolute;
  top: 12px;
  z-index: 100;
  right: 12px;
  background-color: white;
  border-radius: 4px;
  min-width: 300px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 80vh;
`;

export const SelectedIncidentContainer = styled.div`
  border-top: 1px solid #ddd;
`;

export const SectionHeader = styled(Heading)<{ dropdown: boolean }>`
  ${({ dropdown }) =>
    dropdown &&
    css`
      display: flex;
      gap: 6px;
      position: relative;
      right: 30px;
      align-items: center;
    `}
`;

export const OverviewSectionContainer = styled.div<{ dropdown: boolean }>`
  cursor: ${({ dropdown }) => (dropdown ? "pointer" : "")};
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-bottom: 1px solid #e5e5e5;
`;

export const LabelsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Value = styled.span`
  font-size: 12px;
`;

export const ErrorValue = styled(Value)`
  color: #de1c12;
`;
