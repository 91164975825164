import { DateTimeSelectorContainer } from "./RangeSelector.styles";
import { Box, Button, Label, Heading } from "tombac";
import {
  HorizontalBox,
  VerticalBox,
} from "../../../core/components/CommonStyles";
import { useState } from "react";
import { DateSelector } from "./DateSelector";
import { HourSelector } from "./HourSelector";

interface Props {
  onSelect: (day: Date, hour: number) => void;
  initHour: number;
  initDate: Date | null;
}

export const DateTimeSelector = ({ onSelect, initHour, initDate }: Props) => {
  const [hour, setHour] = useState(initHour);
  const [selectedDate, setSelectedDate] = useState<Date | null>(initDate);

  const handleSelectClick = () => {
    if (selectedDate) onSelect(selectedDate, hour);
  };

  return (
    <DateTimeSelectorContainer>
      <Heading level={3} $p="1.5sp 3sp">
        Select Date
      </Heading>
      <DateSelector
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <HorizontalBox $justifyContent="space-between" $gap="2sp" $p="1sp 3sp">
        <VerticalBox>
          <Label>Hour</Label>
          <HourSelector
            selectedHour={hour}
            setSelectedHour={setHour}
            selectedDate={selectedDate}
          />
        </VerticalBox>
        <Box $alignSelf="flex-end">
          <Button
            variant="flat"
            onClick={handleSelectClick}
            disabled={!selectedDate}
          >
            Select
          </Button>
        </Box>
      </HorizontalBox>
    </DateTimeSelectorContainer>
  );
};
