import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Map } from "mapbox-gl";
import mapConfig from "core/configs/mapConfig";

const GlobalMapContext = createContext<Map | null>(null);

interface Props {
  children: ReactNode;
}

export const useGlobalMap = () => useContext(GlobalMapContext);

export const GlobalMap = ({ children }: Props) => {
  const [globalMap, setGlobalMap] = useState<Map | null>(null);

  useEffect(() => {
    setGlobalMap(
      new Map({
        container: document.createElement("div"),
        style: mapConfig.style as any,
        ...(mapConfig.defaults as any),
      })
    );
  }, []);

  return (
    <GlobalMapContext.Provider value={globalMap}>
      {children}
    </GlobalMapContext.Provider>
  );
};
