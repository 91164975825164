import { createContext, ReactNode, useContext, useState } from "react";

interface ContextValue {
  selectedIncidentId: string | undefined;
  changeSelectedIncident: (id: string | undefined) => void;
}

const SelectedIncidentContext = createContext<ContextValue | null>(null);

export const useSelectedIncidentId = () => {
  const val = useContext(SelectedIncidentContext);
  if (!val) {
    throw new Error("Component isn't in context provider");
  }

  return val;
};

interface Props {
  children: ReactNode;
}

export const SelectedIncidentProvider = ({ children }: Props) => {
  const [selectedIncidentId, setSelectedIncidentId] = useState<string>();

  return (
    <SelectedIncidentContext.Provider
      value={{
        selectedIncidentId,
        changeSelectedIncident: setSelectedIncidentId,
      }}
    >
      {children}
    </SelectedIncidentContext.Provider>
  );
};
