import styled from "styled-components";
import { SpinnerIcon } from "tombac-icons";

export const MapLoading = () => (
  <Container>
    <SpinnerIcon spin size="lg" color="red" />
  </Container>
);

const Container = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
`;
