import styled from "styled-components";

export const DropContainer = styled.div<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  pointer-events: ${({ isActive }) => (isActive ? "all" : "none")};
  & > input {
    width: 100vw;
    height: 100vh;
  }
`;

export const DropInfo = styled.div`
  margin: 32px;
  border: 4px dashed hsl(3, 85%, 47%);
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  height: calc(100vh - 64px);
  font-size: 32px;
  color: white;
  font-weight: 500;
`;
