import { Feed } from "core/domain/feed.types";
import { ARCHIVE_LENGTH } from "core/constants/ArchiveLength";
import { eachDayOfInterval, isAfter, subDays } from "date-fns";
import { getFeedStartDate, getFeedEndDate } from "api/feed";

export interface GroupedDays {
  month: number;
  days: Date[];
}

// const getStartDayByFeed = (feed: Feed): Date => {
//   switch (feed) {
//     case "DE": {
//       return new Date("2022-09-07");
//     }
//     case "JP": {
//       return new Date(0);
//     }
//   }
// };

const createDateRange = (feed: Feed) => {
  const today = new Date();
  const archiveLimit = subDays(today, ARCHIVE_LENGTH);
  const limitDate = getFeedStartDate(feed);
  const endLimitDate = getFeedEndDate(feed);

  return eachDayOfInterval({
    start: isAfter(limitDate, archiveLimit) ? limitDate : archiveLimit,
    end: endLimitDate || today,
  });
};

const groupDaysByMonth = (dates: Date[]) =>
  dates.reduce((grouped, day) => {
    const month = day.getMonth();
    const groupedMonth = grouped.find((it) => it.month === month);
    if (groupedMonth) {
      groupedMonth.days.push(day);
    } else {
      grouped.push({ month, days: [day] });
    }

    return grouped;
  }, [] as GroupedDays[]);

export const createGroupedDateRange = (feed: Feed) =>
  groupDaysByMonth(createDateRange(feed));
