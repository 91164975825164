import { useEffect } from "react";
import { polygon, booleanPointInPolygon, point } from "@turf/turf";
import { Map } from "mapbox-gl";

export const useJapanFilter = (map: Map | null): void => {
  useEffect(() => {
    const japanPolygon = polygon([
      [
        [123.82335072878072, 22.870897580462014],
        [122.78200591353999, 25.393899882518596],
        [127.34141836401284, 30.533053302288934],
        [127.42115532304064, 33.29237284171087],
        [128.19690217899915, 33.73046218111152],
        [130.3950945463655, 35.83820754777882],
        [137.20118251643692, 41.47464157220597],
        [141.06954532867246, 45.83404598993661],
        [151.52650238931017, 46.64250634326723],
        [142.89338881166321, 24.00474948920443],
        [123.82335072878072, 22.870897580462014],
      ],
    ]);

    function lngFromMercatorX(x: number) {
      return x * 360 - 180;
    }

    function latFromMercatorY(y: number) {
      const y2 = 180 - y * 360;
      return (360 / Math.PI) * Math.atan(Math.exp((y2 * Math.PI) / 180)) - 90;
    }

    function tileInJapan(tileId: any): boolean {
      const { x, y, z } = tileId.canonical;

      const minZoomLevel = 7;
      if (z < minZoomLevel) return false;

      const worldSize = Math.pow(2, z);

      // Coordinate of the center of the tile
      const lng = lngFromMercatorX((x + 0.5) / worldSize);
      const lat = latFromMercatorY((y + 0.5) / worldSize);

      return booleanPointInPolygon(point([lng, lat]), japanPolygon);
    }

    function setTileFilter(sourceName: string, fn: (tile: any) => boolean) {
      if (map) {
        const source = map?.getSource(sourceName);

        if (source) {
          (source as any).hasTile = fn;
        }
      }
    }

    if (map) {
      setTileFilter("openmaptiles", (tile) => tileInJapan(tile));
      setTileFilter("vectorTiles", (tile) => !tileInJapan(tile));

      setTileFilter("reine-edges", (tile) => !tileInJapan(tile));
      setTileFilter("reine-edges-end", (tile) => !tileInJapan(tile));
      setTileFilter("japan-reine-edges", (tile) => tileInJapan(tile));
      setTileFilter("japan-reine-edges-end", (tile) => tileInJapan(tile));
    }
  }, [map]);
};
