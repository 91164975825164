import { Viewer } from "./viewer/Viewer";
import { ToastsProvider } from "tombac";
import ReactModal from "react-modal";
import { GlobalMap } from "viewer/contexts/GlobalMap";
import "mapbox-gl/dist/mapbox-gl.css";

ReactModal.setAppElement("#root");

function App() {
  return (
    <GlobalMap>
      <div style={{ height: "100vh" }}>
        <ToastsProvider zIndex={300}>
          <Viewer />
        </ToastsProvider>
      </div>
    </GlobalMap>
  );
}

export default App;
