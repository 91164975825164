import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalLayout,
  useTombac,
} from "tombac";
import { SpinnerIcon } from "tombac-icons";

interface Props {
  isOpen: boolean;
}

export const ConvertingModal = ({ isOpen }: Props) => {
  const tombac = useTombac();
  return (
    <Modal isOpen={isOpen}>
      <ModalLayout>
        <ModalHeader>Converting...</ModalHeader>
        <ModalContent
          $alignItems="center"
          $display="flex"
          $justifyContent="center"
        >
          <SpinnerIcon color={tombac.color("primary", 500)} spin size={2} />
        </ModalContent>
      </ModalLayout>
    </Modal>
  );
};
