import styled from "styled-components";
import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { Download } from "./Download";
import { FeedSelector } from "./FeedSelector";
import { RangeSelector } from "./RangeSelector/RangeSelector";
import { SnapshotSelector } from "./SnapshotSelector";
import { SnapshotSwitcher } from "./SnapshotSwitcher";
import { Upload } from "./Upload/Upload";
import { UploadedSnapshot } from "./UploadedSnapshot";

export const BottomMenu = () => {
  const { snapshotState, isExternalSource } = useSelectedSnapshot();

  return (
    <Container>
      {isExternalSource ? (
        <>
          <UploadedSnapshot />
          <Upload />
        </>
      ) : (
        <>
          <FeedSelector />
          <RangeSelector />
          <SnapshotSwitcher />
          <SnapshotSelector />

          {snapshotState.state === "loaded" && <Download />}
          <Upload />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  font-family: Noway;
  min-height: 72px;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;
`;
