import { MapboxGeoJSONFeature, MapMouseEvent } from "mapbox-gl";
import { useCallback, useEffect, useRef } from "react";
import { createGetFeaturesUnderMouse } from "core/utils/getFeaturesUnderMouse";
import { useMap } from "core/components/Map/GlMap";

export const useHoverIncident = (layerIds: string[]) => {
  const getFeaturesUnderMouse = createGetFeaturesUnderMouse(layerIds, true);
  const hoveredFeature = useRef<MapboxGeoJSONFeature>();
  const map = useMap();

  const handleHover = useCallback(
    (ev: MapMouseEvent) => {
      const features = getFeaturesUnderMouse(ev, map);

      if (features.length) {
        map.getCanvas().style.cursor = "pointer";
        if (hoveredFeature.current) {
          map.setFeatureState(hoveredFeature.current, { hover: 0 });
        }
        const featureToHover = features[0];
        hoveredFeature.current = featureToHover;
        map.setFeatureState(featureToHover, { hover: 1 });
      } else if (!features.length && hoveredFeature.current) {
        map.getCanvas().style.cursor = "";

        map.setFeatureState(hoveredFeature.current, { hover: 0 });
        hoveredFeature.current = undefined;
      }
      // }
    },
    [map, layerIds]
  );

  useEffect(() => {
    map.on("mousemove", handleHover);
    return () => {
      map.off("mousemove", handleHover);
    };
  }, [handleHover]);
};
