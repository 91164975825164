import { SimpleSelect } from "core/components/Select/SimpleSelect";
import { Snapshots, SnapshotTimestamp } from "core/domain/snapshots.types";
import { formatDate } from "core/utils/formatDate";
import { useMemo } from "react";
import { Label } from "tombac";
import { useSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";
import { useSnapshots } from "viewer/contexts/SnapshotsContext";
import { SpinnerIcon } from "tombac-icons";
import { BottomMenuItem } from "core/components/BottomMenuItem";
import { Error } from "core/components/Error";

const createOptions = (snapshots: Snapshots) =>
  snapshots.map((snapshot) => ({
    value: snapshot,
    label: formatDate(new Date(new Date(snapshot * 1000).toUTCString())),
  }));

export const SnapshotSelector = () => {
  const { snapshotsState } = useSnapshots();
  const { changeCurrentSnapshots, selectedSnapshotTimestamp } =
    useSelectedSnapshot();

  const options = useMemo(() => {
    return snapshotsState.state === "loaded"
      ? createOptions(snapshotsState.snapshots)
      : [];
  }, [snapshotsState]);

  const handleChangeCurrentSnapshots = (snapshot: SnapshotTimestamp) => {
    if (snapshotsState.state === "loaded") {
      const index = snapshotsState.snapshots.findIndex(
        (timestamp) => timestamp === snapshot
      );
      changeCurrentSnapshots({
        prevSnapshot: snapshotsState.snapshots[index - 1],
        snapshot: snapshotsState.snapshots[index],
        nextSnapshot: snapshotsState.snapshots[index + 1],
      });
    }
  };

  if (snapshotsState.state === "not-selected") {
    return null;
  }

  return (
    <BottomMenuItem>
      {snapshotsState.state === "error" && (
        <Error error={snapshotsState.error} id="snapshots-error" />
      )}
      <Label>Snapshot</Label>
      {snapshotsState.state === "loading" && <SpinnerIcon spin />}
      {snapshotsState.state !== "loading" && options && (
        <SimpleSelect
          value={selectedSnapshotTimestamp}
          options={options}
          selectProps={{ menuPlacement: "top" }}
          onChange={handleChangeCurrentSnapshots}
          $width="200px"
          placeholder={
            snapshotsState.state === "error"
              ? "Error loading snapshots"
              : "Select snapshot..."
          }
        />
      )}
    </BottomMenuItem>
  );
};
