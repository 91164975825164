import { IncidentFeature } from "core/domain/snapshots.types";
import { useSelectedIncidentId } from "viewer/contexts/SelectedIncident";
import { SelectedIncidentsContainer } from "./SelectedIncident.style";
import { Button, ButtonGroup } from "tombac";
import { SelectedIncident } from "./SelectedIncident";

interface Props {
  selectedIncidents: IncidentFeature[] | undefined;
  selectedIncident: IncidentFeature;
}

export const SelectedIncidents = ({
  selectedIncidents,
  selectedIncident,
}: Props) => {
  const { selectedIncidentId, changeSelectedIncident } =
    useSelectedIncidentId();

  return (
    <SelectedIncidentsContainer>
      {selectedIncidents && selectedIncidents.length > 1 && (
        <ButtonGroup>
          {selectedIncidents.map((incident) => {
            const id = incident.properties.id;
            const isSelected = id === selectedIncidentId;
            return (
              <Button
                variant={isSelected ? "accent" : "flat"}
                onClick={() => changeSelectedIncident(id)}
                key={id}
              >
                {id}
              </Button>
            );
          })}
        </ButtonGroup>
      )}
      {selectedIncident && (
        <SelectedIncident selectedIncident={selectedIncident} />
      )}
    </SelectedIncidentsContainer>
  );
};
