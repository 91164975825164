export type LineStop = [number, number];

export type LineWidth = number;

export interface WidthMap {
  [level: string]: LineWidth;
}

export const widths: WidthMap = {
  "0": 20,
  "1": 19,
  "2": 19,
  "3": 18,
  "4": 17,
  "5": 16,
  "6": 15,
  "7": 14,
};
