export enum MainCause {
  MAIN_CAUSE_INVALID = "MAIN_CAUSE_INVALID",
  MAIN_CAUSE_TRAFFIC_CONGESTION = "MAIN_CAUSE_TRAFFIC_CONGESTION",
  MAIN_CAUSE_ACCIDENT = "MAIN_CAUSE_ACCIDENT",
  MAIN_CAUSE_ROADWORKS = "MAIN_CAUSE_ROADWORKS",
  MAIN_CAUSE_NARROW_LANES = "MAIN_CAUSE_NARROW_LANES",
  MAIN_CAUSE_IMPASSABILITY = "MAIN_CAUSE_IMPASSABILITY",
  MAIN_CAUSE_SLIPPERY_ROAD = "MAIN_CAUSE_SLIPPERY_ROAD",
  MAIN_CAUSE_AQUAPLANING = "MAIN_CAUSE_AQUAPLANING",
  MAIN_CAUSE_FIRE = "MAIN_CAUSE_FIRE",
  MAIN_CAUSE_HAZARDOUS_DRIVING_CONDITIONS = "MAIN_CAUSE_HAZARDOUS_DRIVING_CONDITIONS",
  MAIN_CAUSE_OBJECTS_ON_THE_ROAD = "MAIN_CAUSE_OBJECTS_ON_THE_ROAD",
  MAIN_CAUSE_ANIMALS_ON_ROADWAY = "MAIN_CAUSE_ANIMALS_ON_ROADWAY",
  MAIN_CAUSE_PEOPLE_ON_ROADWAY = "MAIN_CAUSE_PEOPLE_ON_ROADWAY",
  MAIN_CAUSE_BROKEN_DOWN_VEHICLES = "MAIN_CAUSE_BROKEN_DOWN_VEHICLES",
  MAIN_CAUSE_VEHICLE_ON_WRONG_CARRIAGEWAY = "MAIN_CAUSE_VEHICLE_ON_WRONG_CARRIAGEWAY",
  MAIN_CAUSE_RESCUE_AND_RECOVERY_WORK_IN_PROGRESS = "MAIN_CAUSE_RESCUE_AND_RECOVERY_WORK_IN_PROGRESS",
  MAIN_CAUSE_REGULATORY_MEASURE = "MAIN_CAUSE_REGULATORY_MEASURE",
  MAIN_CAUSE_EXTREME_WEATHER_CONDITIONS = "MAIN_CAUSE_EXTREME_WEATHER_CONDITIONS",
  MAIN_CAUSE_VISIBILITY_REDUCE = "MAIN_CAUSE_VISIBILITY_REDUCE",
  MAIN_CAUSE_PRECIPITATION = "MAIN_CAUSE_PRECIPITATION",
  MAIN_CAUSE_RECKLESS_PERSONS = "MAIN_CAUSE_RECKLESS_PERSONS",
  MAIN_CAUSE_OVERHEIGHT_WARNING_SYSTEM_TRIGGERED = "MAIN_CAUSE_OVERHEIGHT_WARNING_SYSTEM_TRIGGERED",
  MAIN_CAUSE_TRAFFIC_REGULATIONS_CHANGED = "MAIN_CAUSE_TRAFFIC_REGULATIONS_CHANGED",
  MAIN_CAUSE_MAJOR_EVENT = "MAIN_CAUSE_MAJOR_EVENT",
  MAIN_CAUSE_SERVICE_NOT_OPERATING = "MAIN_CAUSE_SERVICE_NOT_OPERATING",
  MAIN_CAUSE_SERVICE_NOT_USEABLE = "MAIN_CAUSE_SERVICE_NOT_USEABLE",
  MAIN_CAUSE_SLOW_MOVING_VEHICLES = "MAIN_CAUSE_SLOW_MOVING_VEHICLES",
  MAIN_CAUSE_DANGEROUS_END_OF_QUEUE = "MAIN_CAUSE_DANGEROUS_END_OF_QUEUE",
  MAIN_CAUSE_RISK_OF_FIRE = "MAIN_CAUSE_RISK_OF_FIRE",
  MAIN_CAUSE_TIME_DELAY = "MAIN_CAUSE_TIME_DELAY",
  MAIN_CAUSE_POLICE_CHECKPOINT = "MAIN_CAUSE_POLICE_CHECKPOINT",
  MAIN_CAUSE_MALFUNCTIONING_ROADSIDE_EQUIPMENT = "MAIN_CAUSE_MALFUNCTIONING_ROADSIDE_EQUIPMENT",
  MAIN_CAUSE_TEST_MESSAGE = "MAIN_CAUSE_TEST_MESSAGE",
  MAIN_CAUSE_CLOSURE = "MAIN_CAUSE_CLOSURE",
  MAIN_CAUSE_UNDECODABLE_CAUSE = "MAIN_CAUSE_UNDECODABLE_CAUSE",
}

export const AllMainCauses = Object.values(MainCause);
