import { useMap } from "core/components/Map/GlMap";
import SearchBox from "vector-maps/dist/features/search/SearchBox";

export const Search = () => {
  const map = useMap();

  return (
    <SearchBox
      searchOptions={{
        key: "1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv",
      }}
      onSearch={(item) => {
        if (item) {
          const { lng, lat } = item;
          map.setCenter({ lng, lat });
          map.setZoom(9);
        }
      }}
    />
  );
};

// export const Search = () => {
//   const { feed } = useFeed();

//   return (
//     <SearchAndZoom
//       searchConfig={{
//         countrySet: feed,
//         key: "1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv",
//       }}
//     />
//   );
// };
