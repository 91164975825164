import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "tombac-icons/react/style.css";
import {
  LegolandApp,
  useMeQuery,
  RequireProduct,
} from "legoland-sdk/dist/experimental";
import { TombacApp } from "tombac";
import styled from "styled-components";

const LoggedUserContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 30px;
  font-size: 14px;
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const CurrentUser: any = () => {
  const meQuery: any = useMeQuery();

  if (meQuery.isLoading) return "Loading...";
  if (meQuery.isError) return "Error!";
  if (meQuery.data.me === null) return "Not logged in";

  return (
    <LoggedUserContainer>
      Logged in as <strong>{meQuery.data.me.email}</strong>
    </LoggedUserContainer>
  );
};

root.render(
  <TombacApp theme={{ settings: { modalZIndex: 200 } }}>
    <LegolandApp>
      <React.StrictMode>
        <RequireProduct autoRedirect productId="tv">
          <App />
          <CurrentUser />
        </RequireProduct>
      </React.StrictMode>
    </LegolandApp>
  </TombacApp>
);
