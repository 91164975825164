import { useEffect, useState } from "react";
import styled from "styled-components";

import { AllMainCauses } from "core/domain/mainCause.types";
import { AllEffectCodes } from "core/domain/effectCode.types";
import { AllWarningLevels } from "core/domain/warningLevel.types";

import { IncidentFeature, Snapshot } from "core/domain/snapshots.types";

import { IncidentPropFilter } from "./IncidentPropFilters";
import {
  getMainCausesFromFeature,
  getEffectFromFeature,
  getWarningLevelsFromFeature,
} from "core/domain/getters";

import {
  formatMainCause,
  formatEffectCode,
  formatWarningLevel,
} from "core/domain/formatters";
import { MainCause } from "core/domain/mainCause.types";
import {
  IncidentFilter,
  useFilterActions,
} from "viewer/contexts/FiltersContext";
import { SearchIncident } from "./SearchIncident";

interface Props {
  snapshot: Snapshot;
}

export const FiltersSettings = ({ snapshot }: Props) => {
  const { setFilter } = useFilterActions();

  const onFilter = (combinedFilter: IncidentFilter) => {
    setFilter(combinedFilter);
  };

  const [causesFilter, setCausesFilter] = useState(undefined);
  const [effectFilter, setEffectFilter] = useState(undefined);
  const [warningLevelFilter, setWarningLevelFilter] = useState(undefined);

  useEffect(() => {
    const combinedFilter = (incident: IncidentFeature) => {
      const filters = [causesFilter, effectFilter, warningLevelFilter].filter(
        (f) => f !== undefined
      ) as any[];

      for (const filter of filters) {
        if (!(filter.f as any)(incident)) {
          return false;
        }
      }
      return true;
    };

    onFilter(combinedFilter);
  }, [causesFilter, effectFilter, warningLevelFilter]);

  return (
    <Container>
      <SearchIncident />
      <IncidentPropFilter
        snapshot={snapshot}
        availableProperties={AllMainCauses}
        propertyGetter={getMainCausesFromFeature}
        propertyFormatter={(c) => formatMainCause(c as MainCause)}
        title="Main Causes"
        onFilter={(filter) => setCausesFilter({ f: filter } as any)}
      />
      <IncidentPropFilter
        snapshot={snapshot}
        availableProperties={AllEffectCodes}
        propertyGetter={(f) => [getEffectFromFeature(f)]}
        propertyFormatter={formatEffectCode as any}
        title="Effect Code"
        onFilter={(filter) => setEffectFilter({ f: filter } as any)}
      />
      <IncidentPropFilter
        snapshot={snapshot}
        availableProperties={AllWarningLevels}
        propertyGetter={getWarningLevelsFromFeature}
        propertyFormatter={formatWarningLevel}
        title="Warning level"
        onFilter={(filter) => setWarningLevelFilter({ f: filter } as any)}
      />
    </Container>
  );
};

const Container = styled.div`
  height: max-content;
`;
