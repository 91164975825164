import { BottomMenuItem } from "core/components/BottomMenuItem";
import { HorizontalBox } from "core/components/CommonStyles";
import { formatDate } from "core/utils/formatDate";
import { Box, Button, Label, Text } from "tombac";
import { useExternalSnapshot } from "viewer/contexts/SelectedSnapshotContext";

export const UploadedSnapshot = () => {
  const { snapshot, clear } = useExternalSnapshot();

  return (
    <BottomMenuItem>
      <HorizontalBox $gap="16px">
        <Box>
          <Label>Uploaded snapshot</Label>
          <Text fontSize={14} color="#e5e5e5">
            {formatDate(
              new Date(
                new Date(snapshot.metadata.creationTime * 1000).toUTCString()
              )
            )}
          </Text>
        </Box>
        <Button variant="primary" onClick={clear}>
          Clear
        </Button>
      </HorizontalBox>
    </BottomMenuItem>
  );
};
