import { createCache, createSnapshotCache } from "./cache";
import { API_KEY } from "./apiKey";
import { Feed } from "core/domain/feed.types";
import { Snapshot } from "core/domain/snapshots.types";
import { getFeedSettings, FeedCfg } from "./feed";
import { getError, AppError } from "./errors";

const createUrl = (feedCfgId: Feed, adhocDecode = false, sourceUrl = false) => {
  const { feedId, envId, customerId } = getFeedSettings(feedCfgId) as FeedCfg;
  const adhocPart = adhocDecode ? "adhoc/" : "";
  const sourcePart = sourceUrl ? "source/" : "";
  return `https://api.trafficviewer.tomtom.com/${adhocPart}${sourcePart}${envId}/feeds/${feedId}/customers/${customerId}/snapshots`;
  // return `https://preprod.api.trafficviewer.tomtom.com/${adhocPart}${sourcePart}${envId}/feeds/${feedId}/customers/${customerId}/snapshots`;
};

interface FeedParam {
  feed: Feed;
}

interface GetSnapshotsInRangeParams extends FeedParam {
  startDate: Date;
  endDate: Date;
}

interface GetSnapshot extends FeedParam {
  timestamp: number;
  signal: AbortSignal;
}

interface GetDecodedSnapshot extends FeedParam {
  timestamp: number;
  messageIds: string[];
}

interface GetProtobufSnapshot extends FeedParam {
  timestamp: number;
}

export type ApiResponse<T> = {
  data?: T;
  error?: AppError;
};
type SnapshotsResponse = number[];

const apiCall = (url: string, init?: RequestInit) => {
  const defaultsOptions = {
    method: "GET",
    credentials: "include",
    headers: {
      "x-api-key": API_KEY,
      accept: " application/json",
      "Content-Type": "application/json",
    },
  };

  const fetchOptions: any = !init
    ? defaultsOptions
    : {
        ...defaultsOptions,
        ...init,
        headers: { ...defaultsOptions.headers, ...init?.headers },
        credentials: "include",
      };

  return fetch(url, fetchOptions);
};

const snapshotCache = createCache();
const snapshotsCache = createCache();
const snapshotDecodedCache = createCache();

export const SnapshotsApi = {
  getSnapshotsInRange: async ({
    feed,
    startDate,
    endDate,
  }: GetSnapshotsInRangeParams): Promise<SnapshotsResponse> => {
    const key = `${feed}/${startDate.toISOString()}/${endDate.toISOString()}}`;

    if (snapshotsCache.has(key)) {
      return snapshotsCache.get(key);
    }

    const baseUrl = createUrl(feed, false, true);
    const urlWithParams = `${baseUrl}?start=${startDate.toISOString()}&end=${endDate.toISOString()}`;
    try {
      const res = await apiCall(urlWithParams);
      if (!res.ok) {
        return Promise.reject(getError(res));
      }

      const json = await res.json();
      snapshotsCache.set(key, json);
      return json;
    } catch (error) {
      return Promise.reject(getError());
    }
  },

  getSnaphotProtoByTimestamp: async ({
    feed,
    timestamp,
    signal,
  }: GetSnapshot): Promise<ArrayBufferLike> => {
    const key = `${feed}/${timestamp}`;
    if (snapshotCache.has(key)) {
      return snapshotCache.get(key);
    }
    const baseUrl = createUrl(feed);

    const urlWithParams = `${baseUrl}/${timestamp}/`;
    try {
      const res = await apiCall(urlWithParams, {
        headers: {
          accept: "application/octet-stream",
        },
        credentials: "include",
        signal,
      });
      if (!res.ok) {
        return Promise.reject(getError(res));
      }

      const data = await res.arrayBuffer();
      snapshotCache.set(key, data);
      return data;
    } catch (error: any) {
      if (error.name) {
        return Promise.reject(error);
      }

      return Promise.reject(getError());
    }
  },

  getDecodedSnapshot: async ({
    feed,
    timestamp,
    messageIds,
  }: GetDecodedSnapshot): Promise<Snapshot | undefined> => {
    const key = `${feed}/${timestamp}`;
    if (!snapshotDecodedCache.has(key)) {
      snapshotDecodedCache.set(key, createSnapshotCache());
    }

    const toDecode = messageIds;
    const baseUrl = createUrl(feed, true);
    const urlWithParams = `${baseUrl}/${timestamp}/`;

    const res = await apiCall(urlWithParams, {
      method: "POST",
      body: JSON.stringify({
        messagesIds: toDecode.map((id) => parseInt(id, 10)),
      }),
    });
    if (!res.ok) {
      return Promise.reject(getError(res));
    }
    const json = (await res.json()) as Snapshot;
    snapshotDecodedCache.get(key).set(toDecode, json);
    return snapshotDecodedCache.get(key).get();
  },

  getDecodedSnapshotCached: ({
    feed,
    timestamp,
  }: Omit<GetDecodedSnapshot, "messageIds">): Snapshot | undefined => {
    const key = `${feed}/${timestamp}`;
    if (!snapshotDecodedCache.has(key)) {
      return;
    }

    return snapshotDecodedCache.get(key).get();
  },

  getDecodedErrors: ({
    feed,
    timestamp,
  }: Omit<GetDecodedSnapshot, "messageIds">): Set<string> => {
    const key = `${feed}/${timestamp}`;
    if (!snapshotDecodedCache.has(key)) {
      return new Set();
    }

    return snapshotDecodedCache.get(key).getErrors() as Set<string>;
  },

  getProdobufSnapshot: async ({
    feed,
    timestamp,
  }: GetProtobufSnapshot): Promise<Blob> => {
    const baseUrl = createUrl(feed);
    const response = await apiCall(`${baseUrl}/${timestamp}`, {
      headers: {
        accept: "application/octet-stream",
      },
    });

    return await response.blob();
  },
};
