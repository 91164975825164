import { useOnClickOutside } from "core/hooks/useClickOutside";
import { formatDate } from "core/utils/formatDate";
import { hourWithoutTimezone } from "core/utils/hourWithoutTimezone";
import { startOfDay } from "date-fns";
import { useState } from "react";
import { Box } from "tombac";
import { ChevronDownIcon, ChevronUpIcon } from "tombac-icons";
import { useSnapshots } from "viewer/contexts/SnapshotsContext";
import { Medium, PointerBox } from "../../../core/components/CommonStyles";
import { DateTimeSelector } from "./DateTimeSelector";
import { SelectedRange, SelectorContainer } from "./RangeSelector.styles";

export const RangeSelector = () => {
  const { changeStartISODate, startDate, endDate } = useSnapshots();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen((prev) => !prev);
  const ref = useOnClickOutside<HTMLDivElement>(() => {
    setIsOpen(false);
  });

  const handleSelectDate = (day: Date, hour: number) => {
    const date = new Date(
      `${day.getFullYear()}-${(day.getMonth() + 1)
        .toString()
        .padStart(2, "0")
        .toString()
        .padStart(2, "0")}-${day.getDate().toString().padStart(2, "0")}T${hour
        .toString()
        .padStart(2, "0")}:00:00.000Z`
    );

    changeStartISODate(date);
    setIsOpen(false);
  };

  return (
    <SelectorContainer ref={ref}>
      <PointerBox
        onClick={handleClick}
        $display="flex"
        $alignItems="center"
        $gap="8px"
        $height="100%"
      >
        <Box>{isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}</Box>
        <Box>
          <Box>
            <Medium>Range</Medium>
          </Box>
          <Box>
            {startDate && endDate ? (
              <SelectedRange>
                <Box>{formatDate(startDate)}</Box>
                <Box>{formatDate(endDate)}</Box>
              </SelectedRange>
            ) : (
              "select range"
            )}
          </Box>
        </Box>
      </PointerBox>
      {isOpen && (
        <DateTimeSelector
          onSelect={handleSelectDate}
          initHour={startDate ? hourWithoutTimezone(startDate) : 0}
          initDate={startDate && startOfDay(startDate)}
        />
      )}
    </SelectorContainer>
  );
};
