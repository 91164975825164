import { Theme } from "react-select";
import { BoundTombac } from "tombac";

export function reactSelectTheme(tombac: BoundTombac) {
  return (theme: Theme) => ({
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary: tombac.color("accent", 600),
      primary75: tombac.color("accent", 700),
      primary50: tombac.color("accent", 500),
      primary25: tombac.color("accent", 200),
      neutral0: tombac.color("neutral", 100),
      neutral5: tombac.color("neutral", 200),
      neutral10: tombac.color("neutral", 300),
      neutral20: tombac.color("neutral", 400),
    },
  });
}
