import { useCallback, useEffect, useState } from "react";
import { useMap } from "core/components/Map/GlMap";
import { useLoadedSelectedSnapshot } from "viewer/contexts/SelectedSnapshotContext";

const MIN_QUERY_ZOOM = 6;

export const useIncidentsInViewport = (layersToInspect: string[]) => {
  const [incidentsInViewport, setIncidentsInViewport] = useState<string[]>([]);
  const { snapshot, decodedErrors } = useLoadedSelectedSnapshot();
  const map = useMap();

  const handleViewportChange = useCallback((): void => {
    const existingLayers = layersToInspect.filter((layer) =>
      map.getLayer(layer)
    );
    if (existingLayers.length === 0 || map.getZoom() < MIN_QUERY_ZOOM) {
      setIncidentsInViewport([]);
      return;
    }
    const idsInView = map
      .queryRenderedFeatures(undefined, {
        layers: existingLayers,
      })
      .map((feature) => feature?.properties?.id);

    const validIdsInView =
      decodedErrors && decodedErrors.size > 0
        ? idsInView.filter((id) => !decodedErrors.has(id))
        : idsInView;

    // console.log("VALID IN VIEW", validIdsInView.length);

    setIncidentsInViewport(validIdsInView);
  }, [map, decodedErrors, snapshot]);

  useEffect(() => {
    map.on("zoomend", handleViewportChange);
    map.on("dragend", handleViewportChange);
    map.on("data", handleViewportChange);
    return () => {
      map.off("zoomend", handleViewportChange);
      map.off("dragend", handleViewportChange);
      map.off("data", handleViewportChange);
    };
  }, [handleViewportChange]);

  return incidentsInViewport;
};
