const marianStyle = {
  glyphs: 'https://api.tomtom.com/map/1/glyph/21.2.1-0/{fontstack}/{range}.pbf?key=1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv',
  id: 'cn7rm9t7j',
  layers: [
    {
      id: 'background',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'background-color': 'rgba(246, 241, 224, 1)',
      },
      type: 'background',
    },
    {
      id: 'landcover_cropland',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(243, 235, 195, 1)',
        'fill-opacity': {
          base: 1,
          stops: [
            [4, 1],
            [8, 0],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'crop']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      'source-layer': 'globallandcover',
    },
    {
      id: 'landcover_grassland',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(226, 236, 197, 1)',
        'fill-opacity': {
          base: 1,
          stops: [
            [4, 1],
            [8, 0],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'grass']],
      source: 'openmaptiles',
      'source-layer': 'globallandcover',
    },
    {
      id: 'landcover_scrubland',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(199, 230, 179, 1)',
        'fill-opacity': {
          base: 1,
          stops: [
            [4, 1],
            [8, 0],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'scrub']],
      source: 'openmaptiles',
      'source-layer': 'globallandcover',
    },
    {
      id: 'landcover_treeland',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(190, 222, 173, 1)',
        'fill-opacity': {
          base: 1,
          stops: [
            [4, 1],
            [8, 0],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'tree']],
      source: 'openmaptiles',
      'source-layer': 'globallandcover',
    },
    {
      id: 'landcover_forestland',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(171, 217, 170, 1)',
        'fill-opacity': {
          base: 1,
          stops: [
            [4, 1],
            [8, 0],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'forest']],
      source: 'openmaptiles',
      'source-layer': 'globallandcover',
    },
    {
      id: 'landcover_snowland',
      type: 'fill',
      paint: {
        'fill-color': 'hsl(0, 0%, 100%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [4, 1],
            [8, 0],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'snow']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      'source-layer': 'globallandcover',
    },
    {
      id: 'park_outline',
      type: 'line',
      paint: {
        'line-color': 'rgba(228, 241, 215, 1)',
        'line-opacity': 1,
        'line-dasharray': [1, 1.5],
      },
      filter: ['all'],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      'source-layer': 'park',
    },
    {
      id: 'landuse_residential',
      type: 'fill',
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [9, 'rgba(233, 227, 210, 0.80)'],
            [12, 'rgba(233, 227, 210, 0.55)'],
            [16, 'rgba(233, 227, 210, 0.35)'],
          ],
        },
      },
      filter: ['all', ['in', 'class', 'residential', 'suburbs', 'neighbourhood']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      maxzoom: 24,
      metadata: {},
      'source-layer': 'landuse',
    },
    {
      id: 'landcover_wood',
      type: 'fill',
      paint: {
        'fill-color': 'hsla(98, 61%, 72%, 0.7)',
        'fill-opacity': 0.4,
        'fill-antialias': false,
      },
      filter: ['all', ['==', 'class', 'wood']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landcover',
    },
    {
      id: 'landcover_grass',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(224, 232, 201, 1)',
        'fill-opacity': 0.3,
        'fill-antialias': false,
      },
      filter: ['all', ['==', 'class', 'grass']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landcover',
    },
    {
      id: 'landuse_cemetery',
      type: 'fill',
      paint: {
        'fill-color': 'hsl(75, 37%, 81%)',
      },
      filter: ['==', 'class', 'cemetery'],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landuse',
    },
    {
      id: 'landuse_hospital',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(249, 225, 220, 1)',
      },
      filter: ['==', 'class', 'hospital'],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landuse',
    },
    {
      id: 'landuse_school',
      type: 'fill',
      paint: {
        'fill-color': 'rgb(236,238,204)',
      },
      filter: ['==', 'class', 'school'],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landuse',
    },
    {
      id: 'landuse_stadium',
      type: 'fill',
      paint: {
        'fill-color': 'rgb(236,238,204)',
      },
      filter: ['all', ['in', 'class', 'stadium', 'pitch', 'track']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landuse',
    },
    {
      id: 'landuse_garages',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(236, 236, 236, 1)',
      },
      filter: ['all', ['in', 'class', 'garages']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landuse',
    },
    {
      id: 'landuse_dam',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(209, 212, 190, 1)',
      },
      filter: ['all', ['in', 'class', 'dam']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landuse',
    },
    {
      id: 'waterway_tunnel',
      type: 'line',
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.3,
          stops: [
            [13, 0.5],
            [20, 6],
          ],
        },
        'line-dasharray': [2, 4],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel']],
      layout: {
        'line-cap': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 14,
      'source-layer': 'waterway',
    },
    {
      id: 'waterway_river',
      type: 'line',
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.2,
          stops: [
            [11, 0.5],
            [20, 6],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'river'], ['!=', 'brunnel', 'tunnel'], ['!=', 'intermittent', 1]],
      layout: {
        'line-cap': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'waterway',
    },
    {
      id: 'waterway_river_intermittent',
      type: 'line',
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.2,
          stops: [
            [11, 0.5],
            [20, 6],
          ],
        },
        'line-dasharray': [3, 2],
      },
      filter: ['all', ['==', 'class', 'river'], ['!=', 'brunnel', 'tunnel'], ['==', 'intermittent', 1]],
      layout: {
        'line-cap': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'waterway',
    },
    {
      id: 'waterway_other',
      type: 'line',
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.3,
          stops: [
            [13, 0.5],
            [20, 6],
          ],
        },
      },
      filter: ['all', ['!=', 'class', 'river'], ['!=', 'brunnel', 'tunnel'], ['!=', 'intermittent', 1]],
      layout: {
        'line-cap': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'waterway',
    },
    {
      id: 'waterway_other_intermittent',
      type: 'line',
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.3,
          stops: [
            [13, 0.5],
            [20, 6],
          ],
        },
        'line-dasharray': [4, 3],
      },
      filter: ['all', ['!=', 'class', 'river'], ['!=', 'brunnel', 'tunnel'], ['==', 'intermittent', 1]],
      layout: {
        'line-cap': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'waterway',
    },
    {
      id: 'water_intermittent',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(172, 218, 251, 1)',
        'fill-opacity': 0.85,
      },
      filter: ['all', ['==', 'intermittent', 1]],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'water',
    },
    {
      id: 'water',
      type: 'fill',
      paint: {
        'fill-color': {
          stops: [
            [3, 'rgba(183, 214, 231, 1)'],
            [5, 'rgba(172, 209, 234, 1)'],
            [9, 'rgba(169, 209, 235, 1)'],
          ],
        },
      },
      filter: ['all', ['!=', 'intermittent', 1], ['!=', 'brunnel', 'tunnel']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'water',
    },
    {
      id: 'landcover_sand',
      type: 'fill',
      paint: {
        'fill-color': '#f2e9ac',
        'fill-opacity': 1,
        'fill-antialias': false,
      },
      filter: ['all', ['in', 'class', 'sand']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landcover',
    },
    {
      id: 'landcover_sand_outline',
      type: 'line',
      paint: {
        'line-color': '#f2e9ac',
        'line-width': 2,
      },
      filter: ['all', ['in', 'class', 'sand']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'landcover',
    },
    {
      id: 'aeroway_fill',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(229, 228, 224, 1)',
        'fill-opacity': 0.7,
      },
      filter: ['==', '$type', 'Polygon'],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 11,
      metadata: {},
      'source-layer': 'aeroway',
    },
    {
      id: 'aeroway_runway',
      type: 'line',
      paint: {
        'line-color': '#f0ede9',
        'line-width': {
          base: 1.2,
          stops: [
            [11, 3],
            [20, 16],
          ],
        },
      },
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'runway']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 11,
      metadata: {},
      'source-layer': 'aeroway',
    },
    {
      id: 'aeroway_taxiway',
      type: 'line',
      paint: {
        'line-color': '#f0ede9',
        'line-width': {
          base: 1.2,
          stops: [
            [11, 0.5],
            [20, 6],
          ],
        },
      },
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'taxiway']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 11,
      metadata: {},
      'source-layer': 'aeroway',
    },
    {
      id: 'ferry',
      type: 'line',
      paint: {
        'line-color': 'rgba(108, 159, 182, 1)',
        'line-width': 1.1,
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['in', 'class', 'ferry']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_motorway_link_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [0.5, 0.25],
      },
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_service_track_casing',
      type: 'line',
      paint: {
        'line-color': '#cfcdca',
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1],
            [16, 4],
            [20, 11],
          ],
        },
        'line-dasharray': [0.5, 0.25],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_link_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'ramp', '1'], ['==', 'brunnel', 'tunnel'], ['>', 'layer', 0]],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_street_casing',
      type: 'line',
      paint: {
        'line-color': '#cfcdca',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 0.5],
            [13, 1],
            [14, 4],
            [20, 15],
          ],
        },
        'line-opacity': {
          stops: [
            [12, 0],
            [12.5, 1],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'street', 'street_limited']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_secondary_tertiary_casing',
      type: 'line',
      paint: {
        'line-color': 'rgba(195, 189, 187, 1)',
        'line-width': {
          base: 1.2,
          stops: [
            [8, 1.5],
            [20, 17],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [0.5, 0.25],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_trunk_primary_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.7],
            [7, 1.75],
            [20, 22],
          ],
        },
        'line-dasharray': [0.5, 0.25],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_motorway_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.7],
            [7, 1.75],
            [20, 22],
          ],
        },
        'line-dasharray': [0.5, 0.25],
      },
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'brunnel', 'tunnel']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_path_pedestrian',
      type: 'line',
      paint: {
        'line-color': 'rgba(204, 196, 176, 0.45)',
        'line-width': {
          base: 1.2,
          stops: [
            [14, 0.5],
            [20, 6],
          ],
        },
        'line-dasharray': [1, 0.75],
      },
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'brunnel', 'tunnel'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_motorway_link',
      type: 'line',
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'motorway_link'], ['==', 'ramp', 1], ['==', 'brunnel', 'tunnel']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_service_track',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [15.5, 0],
            [16, 2],
            [20, 7.5],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_service_track_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [15.5, 0],
            [16, 2],
            [20, 7.5],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'service_construction', 'track_construction']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_link',
      type: 'line',
      paint: {
        'line-color': '#fff4c6',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
      filter: ['all', ['==', 'ramp', '1'], ['==', 'brunnel', 'tunnel'], ['>', 'layer', 0]],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_minor',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 11.5],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'minor']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_minor_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 11.5],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'minor_construction']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_secondary_tertiary',
      type: 'line',
      paint: {
        'line-color': 'rgba(245, 245, 243, 1)',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [8, 0.5],
            [20, 13],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_secondary_tertiary_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [8, 0.5],
            [20, 13],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'secondary_construction', 'tertiary_construction']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_trunk_primary',
      type: 'line',
      paint: {
        'line-color': '#fff4c6',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_trunk_primary_construction',
      type: 'line',
      paint: {
        'line-color': '#fff4c6',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'primary_construction', 'trunk_construction']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_motorway',
      type: 'line',
      paint: {
        'line-color': '#ffdaa6',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'brunnel', 'tunnel']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_motorway_construction',
      type: 'line',
      paint: {
        'line-color': '#ffdaa6',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'class', 'motorway_construction'], ['==', 'brunnel', 'tunnel']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_major_rail',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [15, 0.75],
            [20, 2],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'rail']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'tunnel_major_rail_hatching',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 3],
            [20, 8],
          ],
        },
        'line-dasharray': [0.2, 8],
      },
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'rail']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_area_pier',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(246, 241, 229, 1)',
        'fill-antialias': true,
      },
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'pier']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_pier',
      type: 'line',
      paint: {
        'line-color': 'rgba(246, 241, 229, 1)',
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1],
            [17, 4],
          ],
        },
      },
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'pier']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_area_bridge',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(246, 241, 229, 0.6)',
        'fill-antialias': true,
      },
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'brunnel', 'bridge']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_area_pattern',
      type: 'fill',
      paint: {
        'fill-pattern': 'pedestrian_polygon',
      },
      filter: ['all', ['==', '$type', 'Polygon'], ['!has', 'brunnel'], ['!in', 'class', 'bridge', 'pier']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_service_track_casing',
      type: 'line',
      paint: {
        'line-color': '#cfcdca',
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1],
            [16, 4],
            [20, 11],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_link_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
        'line-opacity': 1,
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['!in', 'class', 'motorway', 'trunk', 'primary', 'pedestrian', 'path', 'track', 'service'],
        ['==', 'ramp', 1],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 13,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_trunk_primary_link_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 0.7],
            [20, 14],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'ramp', 1], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_motorway_link_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['==', 'ramp', 1]],
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 12,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_minor_casing',
      type: 'line',
      paint: {
        'line-color': '#cfcdca',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 0.5],
            [13, 1],
            [14, 4],
            [20, 20],
          ],
        },
        'line-opacity': {
          stops: [
            [12, 0],
            [12.5, 1],
          ],
        },
      },
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'minor'],
        ['!=', 'ramp', '1'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_secondary_tertiary_casing-copy',
      type: 'line',
      paint: {
        'line-color': 'rgba(195, 189, 187, 1)',
        'line-width': {
          base: 1.2,
          stops: [
            [8, 1.5],
            [20, 17],
          ],
        },
        'line-opacity': 1,
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'secondary', 'tertiary'],
        ['!=', 'ramp', 1],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_trunk_primary_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.7],
            [7, 1.75],
            [20, 22],
          ],
        },
        'line-opacity': 1,
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['!=', 'ramp', 1],
        ['in', 'class', 'primary', 'trunk'],
        ['!=', 'ramp', 1],
      ],
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_motorway_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.7],
            [7, 1.75],
            [20, 22],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['!=', 'ramp', 1]],
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_path_pedestrian',
      type: 'line',
      paint: {
        'line-color': 'rgba(204, 196, 176, 0.45)',
        'line-width': {
          base: 1.2,
          stops: [
            [14, 1],
            [20, 6],
          ],
        },
        'line-dasharray': [1, 1],
      },
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 14,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_link',
      type: 'line',
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['==', 'ramp', 1],
        ['!in', 'class', 'motorway', 'trunk', 'primary', 'pedestrian', 'path', 'track', 'service'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 13,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_trunk_primary_link',
      type: 'line',
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 0.5],
            [20, 10],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'ramp', 1], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_motorway_link',
      type: 'line',
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['==', 'ramp', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 12,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_service_track',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [15.5, 0],
            [16, 2],
            [20, 7.5],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_service_track_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [15.5, 0],
            [16, 2],
            [20, 7.5],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'service_construction', 'track_construction'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_minor',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 18],
          ],
        },
        'line-opacity': 1,
      },
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'minor']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_minor_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 18],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [2, 2],
      },
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'minor_construction']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_secondary_tertiary',
      type: 'line',
      paint: {
        'line-color': 'rgba(245, 245, 243, 1)',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [8, 0.5],
            [20, 13],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_secondary_tertiary_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [8, 0.5],
            [20, 13],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'secondary_construction', 'tertiary_construction'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_trunk_primary',
      type: 'line',
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['!=', 'ramp', 1], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_trunk_primary_construction',
      type: 'line',
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['!=', 'ramp', 1],
        ['in', 'class', 'primary_construction', 'trunk_construction'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_motorway',
      type: 'line',
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [5, 'hsl(26, 87%, 62%)'],
            [6, '#fc8'],
          ],
        },
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway'], ['!=', 'ramp', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_motorway_construction',
      type: 'line',
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [5, 'hsl(26, 87%, 62%)'],
            [6, '#fc8'],
          ],
        },
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['==', 'class', 'motorway_construction'],
        ['!=', 'ramp', 1],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_major_rail',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [15, 0.75],
            [20, 2],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'rail']],
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_major_rail_hatching',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 3],
            [20, 8],
          ],
        },
        'line-dasharray': [0.2, 8],
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'rail']],
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_minor_rail',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [15, 0.75],
            [20, 2],
          ],
        },
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'subclass', 'tram', 'light_rail']],
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'road_minor_rail_hatching',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 2],
            [20, 6],
          ],
        },
        'line-dasharray': [0.2, 4],
      },
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'subclass', 'tram', 'light_rail']],
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'building',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(189, 185, 181, 0.3)',
        'fill-outline-color': {
          base: 1,
          stops: [
            [13, 'hsla(35, 6%, 79%, 0.32)'],
            [14, 'hsl(35, 6%, 79%)'],
          ],
        },
      },
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      maxzoom: 14,
      minzoom: 13,
      metadata: {},
      'source-layer': 'building',
    },
    {
      id: 'building-3d',
      type: 'fill-extrusion',
      paint: {
        'fill-extrusion-base': {
          type: 'identity',
          property: 'render_min_height',
        },
        'fill-extrusion-color': 'rgba(189, 185, 181, 1)',
        'fill-extrusion-height': {
          type: 'identity',
          property: 'render_height',
        },
        'fill-extrusion-opacity': 0.3,
      },
      filter: ['all', ['!has', 'hide_3d']],
      layout: {
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 14,
      metadata: {},
      'source-layer': 'building',
    },
    {
      id: 'waterway-bridge-case',
      type: 'line',
      paint: {
        'line-color': '#bbbbbb',
        'line-width': {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 5],
          ],
        },
        'line-gap-width': {
          base: 1.3,
          stops: [
            [13, 0.5],
            [20, 6],
          ],
        },
      },
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'brunnel', 'bridge']],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
      },
      source: 'openmaptiles',
      'source-layer': 'waterway',
    },
    {
      id: 'waterway-bridge',
      type: 'line',
      paint: {
        'line-color': 'rgba(134, 204, 250, 1)',
        'line-width': {
          base: 1.3,
          stops: [
            [13, 0.5],
            [20, 6],
          ],
        },
      },
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'brunnel', 'bridge']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      source: 'openmaptiles',
      'source-layer': 'waterway',
    },
    {
      id: 'bridge_motorway_link_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'ramp', 1], ['==', 'brunnel', 'bridge']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_service_track_casing',
      type: 'line',
      paint: {
        'line-color': '#cfcdca',
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1],
            [16, 4],
            [20, 11],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_link_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'class', 'link'], ['==', 'brunnel', 'bridge']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_street_casing',
      type: 'line',
      paint: {
        'line-color': 'hsl(36, 6%, 74%)',
        'line-width': {
          base: 1.2,
          stops: [
            [12, 0.5],
            [13, 1],
            [14, 4],
            [20, 25],
          ],
        },
        'line-opacity': {
          stops: [
            [12, 0],
            [12.5, 1],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'street', 'street_limited']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_path_pedestrian_casing',
      type: 'line',
      paint: {
        'line-color': 'hsl(35, 6%, 80%)',
        'line-width': {
          base: 1.2,
          stops: [
            [14, 1.5],
            [20, 18],
          ],
        },
        'line-dasharray': [1, 0],
      },
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'brunnel', 'bridge'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      layout: {
        'line-join': 'miter',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_secondary_tertiary_casing',
      type: 'line',
      paint: {
        'line-color': 'rgba(195, 189, 187, 1)',
        'line-width': {
          base: 1.2,
          stops: [
            [8, 1.5],
            [20, 17],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_trunk_primary_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.7],
            [7, 1.75],
            [20, 22],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_motorway_casing',
      type: 'line',
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.7],
            [7, 1.75],
            [20, 22],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'brunnel', 'bridge'], ['!=', 'ramp', 1]],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_path_pedestrian',
      type: 'line',
      paint: {
        'line-color': 'hsl(0, 0%, 100%)',
        'line-width': {
          base: 1.2,
          stops: [
            [14, 0.5],
            [20, 10],
          ],
        },
        'line-dasharray': [1, 0.3],
      },
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['==', 'brunnel', 'bridge'],
        ['in', 'class', 'path', 'pedestrian'],
      ],
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_motorway_link',
      type: 'line',
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'ramp', 1], ['==', 'brunnel', 'bridge']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_service_track',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [15.5, 0],
            [16, 2],
            [20, 7.5],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_service_track_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [15.5, 0],
            [16, 2],
            [20, 7.5],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'service_construction', 'track_construction']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_link',
      type: 'line',
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'link'], ['==', 'brunnel', 'bridge']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_minor',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 18],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'minor']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_minor_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 18],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'minor_construction']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_secondary_tertiary',
      type: 'line',
      paint: {
        'line-color': 'rgba(245, 245, 243, 1)',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [8, 0.5],
            [20, 13],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_secondary_tertiary_construction',
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [8, 0.5],
            [20, 13],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'secondary_construction', 'tertiary_construction']],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_trunk_primary',
      type: 'line',
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_trunk_primary_construction',
      type: 'line',
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'primary_construction', 'trunk_construction']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_motorway',
      type: 'line',
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'motorway'], ['==', 'brunnel', 'bridge'], ['!=', 'ramp', 1]],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_motorway_construction',
      type: 'line',
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [7, 1],
            [20, 18],
          ],
        },
        'line-dasharray': [2, 2],
      },
      filter: ['all', ['==', 'class', 'motorway_construction'], ['==', 'brunnel', 'bridge'], ['!=', 'ramp', 1]],
      layout: {
        'line-join': 'round',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_major_rail',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [15, 0.75],
            [20, 2],
          ],
        },
      },
      filter: ['all', ['==', 'class', 'rail'], ['==', 'brunnel', 'bridge']],
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'bridge_major_rail_hatching',
      type: 'line',
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 3],
            [20, 8],
          ],
        },
        'line-dasharray': [0.2, 8],
      },
      filter: ['all', ['==', 'class', 'rail'], ['==', 'brunnel', 'bridge']],
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation',
    },
    {
      id: 'cablecar',
      type: 'line',
      paint: {
        'line-color': 'hsl(0, 0%, 70%)',
        'line-width': {
          base: 1,
          stops: [
            [11, 1],
            [19, 2.5],
          ],
        },
      },
      filter: ['==', 'class', 'aerialway'],
      layout: {
        'line-cap': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 13,
      'source-layer': 'transportation',
    },
    {
      id: 'cablecar-dash',
      type: 'line',
      paint: {
        'line-color': 'hsl(0, 0%, 70%)',
        'line-width': {
          base: 1,
          stops: [
            [11, 3],
            [19, 5.5],
          ],
        },
        'line-dasharray': [2, 3],
      },
      filter: ['==', 'class', 'aerialway'],
      layout: {
        'line-cap': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 13,
      'source-layer': 'transportation',
    },
    {
      id: 'boundary_3',
      type: 'line',
      paint: {
        'line-color': '#9e9cab',
        'line-width': {
          base: 1,
          stops: [
            [4, 0.4],
            [5, 0.7],
            [12, 1.6],
          ],
        },
        'line-dasharray': [5, 3],
      },
      filter: ['all', ['in', 'admin_level', 3, 4], ['==', 'maritime', 0]],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 3,
      metadata: {},
      'source-layer': 'boundary',
    },
    {
      id: 'boundary_2_z0-4',
      type: 'line',
      paint: {
        'line-color': 'rgba(139, 139, 139, 1)',
        'line-width': {
          base: 1,
          stops: [
            [3, 1],
            [5, 1.2],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'admin_level', 2], ['==', 'maritime', 0], ['!has', 'claimed_by']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      maxzoom: 5,
      minzoom: 0,
      metadata: {},
      'source-layer': 'boundary',
    },
    {
      id: 'boundary_2_z0-4_maritime',
      type: 'line',
      paint: {
        'line-color': 'rgba(98,185,229,1)',
        'line-width': {
          base: 1,
          stops: [
            [3, 1],
            [5, 1.2],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'admin_level', 2], ['!has', 'claimed_by'], ['==', 'maritime', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      maxzoom: 5,
      minzoom: 0,
      metadata: {},
      'source-layer': 'boundary',
    },
    {
      id: 'boundary_2_z5',
      type: 'line',
      paint: {
        'line-color': 'rgba(139, 139, 139, 1)',
        'line-width': {
          base: 1,
          stops: [
            [5, 1.2],
            [12, 3],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 0], ['==', 'maritime', 0]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'boundary',
    },
    {
      id: 'boundary_2_z5_disputed',
      type: 'line',
      paint: {
        'line-color': 'rgba(120, 120, 120, 1)',
        'line-width': {
          base: 1,
          stops: [
            [5, 1.2],
            [12, 3],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [4, 3],
      },
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 1], ['==', 'maritime', 0]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'boundary',
    },
    {
      id: 'boundary_2_z5_disputed_maritime',
      type: 'line',
      paint: {
        'line-color': 'rgba(98,185,229,1)',
        'line-width': {
          base: 1,
          stops: [
            [5, 1.2],
            [12, 3],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [4, 3],
      },
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 1], ['==', 'maritime', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'boundary',
    },
    {
      id: 'boundary_2_z5_maritime',
      type: 'line',
      paint: {
        'line-color': 'rgba(98,185,229,1)',
        'line-width': {
          base: 1,
          stops: [
            [5, 1.2],
            [12, 3],
          ],
        },
        'line-opacity': 1,
      },
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 0], ['==', 'maritime', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'boundary',
    },
    {
      id: 'water_name_line',
      type: 'symbol',
      paint: {
        'text-color': '#5d60be',
        'text-halo-color': 'rgba(255,255,255,0.7)',
        'text-halo-width': 1,
      },
      filter: ['all', ['==', '$type', 'LineString']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-max-width': 5,
        'symbol-placement': 'line',
      },
      source: 'openmaptiles',
      minzoom: 0,
      metadata: {},
      'source-layer': 'water_name',
    },
    {
      id: 'water_name_point',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(76, 125, 173, 1)',
        'text-halo-color': 'rgba(255,255,255,0)',
        'text-halo-width': 1,
      },
      filter: ['all', ['==', '$type', 'Point'], ['!=', 'class', 'ocean']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-max-width': 5,
      },
      source: 'openmaptiles',
      maxzoom: 24,
      minzoom: 2,
      metadata: {},
      'source-layer': 'water_name',
    },
    {
      id: 'water_ocean_name_point',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(76, 125, 173, 1)',
        'text-halo-color': 'rgba(255,255,255,0)',
        'text-halo-width': 1,
      },
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'ocean']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-max-width': 5,
      },
      source: 'openmaptiles',
      minzoom: 0,
      metadata: {},
      'source-layer': 'water_name',
    },
    {
      id: 'poi_z16_subclass',
      type: 'symbol',
      paint: {
        'text-color': '#666',
        'text-halo-blur': 0.5,
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
      },
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['>=', 'rank', 20],
        [
          'any',
          [
            'all',
            ['in', 'class', 'pitch'],
            ['in', 'subclass', 'soccer', 'tennis', 'baseball', 'basketball', 'swimming', 'golf'],
          ],
        ],
        ['any', ['!has', 'level'], ['==', 'level', 0]],
      ],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'icon-image': '{subclass}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-anchor': 'top',
        'text-offset': [0, 0.6],
        'text-padding': 2,
        'text-max-width': 9,
      },
      source: 'openmaptiles',
      minzoom: 16,
      metadata: {},
      'source-layer': 'poi',
    },
    {
      id: 'poi_z16',
      type: 'symbol',
      paint: {
        'text-color': '#666',
        'text-halo-blur': 0.5,
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
      },
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['>=', 'rank', 20],
        [
          'none',
          [
            'all',
            ['in', 'class', 'pitch'],
            ['in', 'subclass', 'soccer', 'tennis', 'baseball', 'basketball', 'swimming', 'golf'],
          ],
        ],
        ['any', ['!has', 'level'], ['==', 'level', 0]],
      ],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-anchor': 'top',
        'text-offset': [0, 0.6],
        'text-padding': 2,
        'text-max-width': 9,
      },
      source: 'openmaptiles',
      minzoom: 16,
      metadata: {},
      'source-layer': 'poi',
    },
    {
      id: 'poi_z15',
      type: 'symbol',
      paint: {
        'text-color': '#666',
        'text-halo-blur': 0.5,
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
      },
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['>=', 'rank', 7],
        ['<', 'rank', 20],
        ['any', ['!has', 'level'], ['==', 'level', 0]],
      ],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-anchor': 'top',
        'text-offset': [0, 0.6],
        'text-padding': 2,
        'text-max-width': 9,
      },
      source: 'openmaptiles',
      minzoom: 15,
      metadata: {},
      'source-layer': 'poi',
    },
    {
      id: 'poi_z14',
      type: 'symbol',
      paint: {
        'text-color': '#666',
        'text-halo-blur': 0.5,
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
      },
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['any', ['<', 'rank', 7]],
        ['any', ['!has', 'level'], ['==', 'level', 0]],
      ],
      layout: {
        'icon-size': 0.9,
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-anchor': 'top',
        'text-offset': [0, 0.6],
        'text-padding': 2,
        'text-max-width': 9,
      },
      source: 'openmaptiles',
      minzoom: 14.2,
      metadata: {},
      'source-layer': 'poi',
    },
    {
      id: 'poi_transit',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(102, 102, 102, 1)',
        'text-halo-blur': 0.5,
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
      },
      filter: ['all', ['in', 'class', 'bus', 'railway', 'airport'], ['==', 'subclass', 'station']],
      layout: {
        'icon-size': 0.9,
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-anchor': 'left',
        'text-offset': [0.9, 0],
        'text-padding': 2,
        'text-max-width': 9,
      },
      source: 'openmaptiles',
      minzoom: 15,
      metadata: {},
      'source-layer': 'poi',
    },
    {
      id: 'road_oneway',
      type: 'symbol',
      paint: {
        'icon-opacity': 0.5,
      },
      filter: [
        'all',
        ['==', 'oneway', 1],
        ['in', 'class', 'motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'minor', 'service'],
      ],
      layout: {
        'icon-size': {
          stops: [
            [15, 0.5],
            [19, 1],
          ],
        },
        'icon-image': 'oneway',
        visibility: 'visible',
        'icon-rotate': 90,
        'icon-padding': 2,
        'symbol-spacing': 75,
        'symbol-placement': 'line',
        'icon-rotation-alignment': 'map',
      },
      source: 'openmaptiles',
      minzoom: 15,
      'source-layer': 'transportation',
    },
    {
      id: 'road_oneway_opposite',
      type: 'symbol',
      paint: {
        'icon-opacity': 0.5,
      },
      filter: [
        'all',
        ['==', 'oneway', -1],
        ['in', 'class', 'motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'minor', 'service'],
      ],
      layout: {
        'icon-size': {
          stops: [
            [15, 0.5],
            [19, 1],
          ],
        },
        'icon-image': 'oneway',
        'icon-rotate': -90,
        'icon-padding': 2,
        'symbol-spacing': 75,
        'symbol-placement': 'line',
        'icon-rotation-alignment': 'map',
      },
      source: 'openmaptiles',
      minzoom: 15,
      'source-layer': 'transportation',
    },
    {
      id: 'road_label',
      type: 'symbol',
      paint: {
        'text-color': '#765',
        'text-halo-blur': 0.5,
        'text-halo-width': 1,
      },
      filter: ['all'],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          base: 1,
          stops: [
            [13, 12],
            [14, 13],
          ],
        },
        'text-field': '{name:latin} {name:nonlatin}',
        'text-anchor': 'center',
        'text-offset': [0, 0.15],
        'symbol-placement': 'line',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'transportation_name',
    },
    {
      id: 'highway-shield',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(37, 36, 36, 1)',
      },
      filter: [
        'all',
        ['<=', 'ref_length', 6],
        ['==', '$type', 'LineString'],
        ['!in', 'network', 'us-interstate', 'us-highway', 'us-state'],
      ],
      layout: {
        'icon-size': 1,
        'text-font': ['Noto-Regular'],
        'text-size': 10,
        'icon-image': 'road_{ref_length}',
        'text-field': '{ref}',
        'symbol-spacing': 200,
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'symbol-avoid-edges': true,
        'icon-rotation-alignment': 'viewport',
        'text-rotation-alignment': 'viewport',
      },
      source: 'openmaptiles',
      minzoom: 8,
      'source-layer': 'transportation_name',
    },
    {
      id: 'highway-shield-us-interstate',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(255, 255, 255, 1)',
      },
      filter: ['all', ['<=', 'ref_length', 6], ['==', '$type', 'LineString'], ['in', 'network', 'us-interstate']],
      layout: {
        'icon-size': 1,
        'text-font': ['Noto-Regular'],
        'text-size': 9,
        'icon-image': '{network}_{ref_length}',
        'text-field': '{ref}',
        'symbol-spacing': 200,
        'symbol-placement': {
          base: 1,
          stops: [
            [7, 'point'],
            [7, 'line'],
            [8, 'line'],
          ],
        },
        'symbol-avoid-edges': true,
        'icon-rotation-alignment': 'viewport',
        'text-rotation-alignment': 'viewport',
      },
      source: 'openmaptiles',
      minzoom: 7,
      'source-layer': 'transportation_name',
    },
    {
      id: 'highway-shield-us-other',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(37, 36, 36, 1)',
      },
      filter: [
        'all',
        ['<=', 'ref_length', 6],
        ['==', '$type', 'LineString'],
        ['in', 'network', 'us-highway', 'us-state'],
      ],
      layout: {
        'icon-size': 1,
        'text-font': ['Noto-Regular'],
        'text-size': 9,
        'icon-image': '{network}_{ref_length}',
        'text-field': '{ref}',
        'symbol-spacing': 200,
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'symbol-avoid-edges': true,
        'icon-rotation-alignment': 'viewport',
        'text-rotation-alignment': 'viewport',
      },
      source: 'openmaptiles',
      minzoom: 9,
      'source-layer': 'transportation_name',
    },
    {
      id: 'airport-label-major',
      type: 'symbol',
      paint: {
        'text-color': '#666',
        'text-halo-blur': 0.5,
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
      },
      filter: ['all', ['has', 'iata']],
      layout: {
        'icon-size': 1,
        'text-font': ['Noto-Regular'],
        'text-size': 12,
        'icon-image': 'airport_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-anchor': 'top',
        'text-offset': [0, 0.6],
        'text-padding': 2,
        'text-optional': true,
        'text-max-width': 9,
      },
      source: 'openmaptiles',
      minzoom: 10,
      'source-layer': 'aerodrome_label',
    },
    {
      id: 'airport_gate',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(135, 135, 135, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 1)',
        'text-halo-width': 1.5,
      },
      filter: ['all', ['==', 'class', 'gate']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          stops: [
            [17, 9],
            [19, 15],
          ],
        },
        'text-field': '{ref}',
      },
      source: 'openmaptiles',
      minzoom: 16.5,
      'source-layer': 'aeroway',
    },
    {
      id: 'place_other',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(66, 62, 62, 1)',
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 1.2,
      },
      filter: ['all', ['in', 'class', 'hamlet', 'island', 'islet', 'neighbourhood', 'suburb']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [12, 10],
            [15, 14],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        visibility: 'visible',
        'text-max-width': 9,
        'text-transform': 'uppercase',
        'text-letter-spacing': 0.1,
      },
      source: 'openmaptiles',
      minzoom: 8,
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'place_village',
      type: 'symbol',
      paint: {
        'text-color': '#333',
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 1.2,
      },
      filter: ['all', ['==', 'class', 'village']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [10, 12],
            [15, 22],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-max-width': 8,
      },
      source: 'openmaptiles',
      minzoom: 8,
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'place_town',
      type: 'symbol',
      paint: {
        'text-color': '#333',
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 1.2,
      },
      filter: ['all', ['==', 'class', 'town']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [7, 12],
            [11, 16],
          ],
        },
        'icon-image': {
          base: 1,
          stops: [
            [0, 'circle-stroked_16'],
            [10, ''],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-anchor': 'bottom',
        'text-offset': [0, 0],
        'text-max-width': 8,
      },
      source: 'openmaptiles',
      minzoom: 6,
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'place_city',
      type: 'symbol',
      paint: {
        'text-color': '#333',
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 1.2,
      },
      filter: ['all', ['==', 'class', 'city']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [7, 14],
            [11, 24],
          ],
        },
        'icon-image': {
          base: 1,
          stops: [
            [0, 'circle-stroked_16'],
            [10, ''],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-anchor': 'bottom',
        'text-offset': [0, 0],
        'icon-optional': false,
        'text-max-width': 8,
        'icon-allow-overlap': true,
      },
      source: 'openmaptiles',
      minzoom: 5,
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'place_state',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(74, 72, 66, 1)',
        'text-halo-color': 'rgba(255,255,255,0.7)',
        'text-halo-width': 0.8,
      },
      filter: ['all', ['==', 'class', 'state']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          stops: [
            [3, 10],
            [6, 14],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-padding': 2,
        'text-transform': 'uppercase',
        'text-letter-spacing': 0.1,
      },
      source: 'openmaptiles',
      maxzoom: 6,
      minzoom: 3,
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'country_other',
      type: 'symbol',
      paint: {
        'text-color': '#334',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 0.8,
      },
      filter: ['all', ['==', 'class', 'country'], ['!has', 'iso_a2']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          stops: [
            [3, 9],
            [7, 15],
          ],
        },
        'text-field': '{name:latin}',
        'text-max-width': 6.25,
        'text-transform': 'none',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'country_3',
      type: 'symbol',
      paint: {
        'text-color': '#334',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 0.8,
      },
      filter: ['all', ['>=', 'rank', 3], ['==', 'class', 'country'], ['has', 'iso_a2']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          stops: [
            [3, 11],
            [7, 17],
          ],
        },
        'text-field': '{name:latin}',
        'text-max-width': 6.25,
        'text-transform': 'none',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'country_2',
      type: 'symbol',
      paint: {
        'text-color': '#334',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 0.8,
      },
      filter: ['all', ['==', 'rank', 2], ['==', 'class', 'country'], ['has', 'iso_a2']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          stops: [
            [2, 11],
            [5, 17],
          ],
        },
        'text-field': '{name:latin}',
        'text-max-width': 6.25,
        'text-transform': 'none',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'country_1',
      type: 'symbol',
      paint: {
        'text-color': '#334',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255,255,255,0.8)',
        'text-halo-width': 0.8,
      },
      filter: ['all', ['==', 'rank', 1], ['==', 'class', 'country'], ['has', 'iso_a2']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': {
          stops: [
            [1, 11],
            [4, 17],
            [6, 19],
          ],
        },
        'text-field': '{name:latin}',
        'text-max-width': 6.25,
        'text-transform': 'none',
      },
      source: 'openmaptiles',
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'continent',
      type: 'symbol',
      paint: {
        'text-color': '#633',
        'text-halo-color': 'rgba(255,255,255,0.7)',
        'text-halo-width': 1,
      },
      filter: ['all', ['==', 'class', 'continent']],
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 13,
        'text-field': '{name:latin}',
        'text-justify': 'center',
        'text-transform': 'uppercase',
      },
      source: 'openmaptiles',
      maxzoom: 1,
      metadata: {},
      'source-layer': 'place',
    },
    {
      id: 'housenumber',
      type: 'symbol',
      paint: {
        'text-color': 'rgba(119, 102, 85, 0.69)',
      },
      layout: {
        'text-font': ['Noto-Regular'],
        'text-size': 10,
        'text-field': '{housenumber}',
      },
      source: 'openmaptiles',
      minzoom: 17.5,
      'source-layer': 'housenumber',
    },
    {
      id: 'Beach or dune',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(246, 240, 206, 1)',
        'fill-opacity': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Beach or dune',
      type: 'fill',
    },
    {
      filter: [
        'any',
        ['==', 'category', 'woody vegetation'],
        ['==', 'category', 'cropland'],
        ['==', 'category', 'herbaceous vegetation'],
        ['==', 'category', 'bare soil'],
        ['==', 'category', 'sand'],
        ['==', 'category', 'ice and snow'],
      ],
      id: 'Earth Cover 0-4',
      layout: {
        visibility: 'visible',
      },
      maxzoom: 4,
      metadata: 'group:area',
      minzoom: 0,
      paint: {
        'fill-antialias': false,
        'fill-color': {
          property: 'category',
          stops: [
            ['woody vegetation', 'rgba(195, 224, 192, 1)'],
            ['cropland', 'rgba(212, 235, 186, 1)'],
            ['herbaceous vegetation', 'rgba(190, 226, 189, 1)'],
            ['bare soil', 'rgba(246, 241, 224, 1)'],
            ['sand', 'rgba(248, 239, 189, 1)'],
            ['ice and snow', 'rgba(245, 255, 255, 1)'],
          ],
          type: 'categorical',
        },
        'fill-opacity': {
          stops: [
            [3.5, 1],
            [4, 0.5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Earth Cover',
      type: 'fill',
    },
    {
      filter: [
        'any',
        ['==', 'category', 'woody vegetation'],
        ['==', 'category', 'cropland'],
        ['==', 'category', 'herbaceous vegetation'],
        ['==', 'category', 'bare soil'],
        ['==', 'category', 'sand'],
        ['==', 'category', 'ice and snow'],
      ],
      id: 'Earth Cover 4-9',
      layout: {
        visibility: 'visible',
      },
      maxzoom: 9,
      metadata: 'group:area',
      minzoom: 4,
      paint: {
        'fill-antialias': false,
        'fill-color': {
          property: 'category',
          stops: [
            ['woody vegetation', 'rgba(219, 233, 206, 1)'],
            ['cropland', 'rgba(229, 238, 204, 1)'],
            ['herbaceous vegetation', 'rgba(219, 233, 206, 1)'],
            ['bare soil', 'rgba(246, 241, 224, 1)'],
            ['sand', 'rgba(246, 240, 206, 1)'],
            ['ice and snow', 'rgba(245, 255, 255, 1)'],
          ],
          type: 'categorical',
        },
        'fill-opacity': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Earth Cover',
      type: 'fill',
    },
    {
      filter: [
        'any',
        ['==', 'category', 'woody vegetation'],
        ['==', 'category', 'cropland'],
        ['==', 'category', 'herbaceous vegetation'],
        ['==', 'category', 'bare soil'],
        ['==', 'category', 'sand'],
        ['==', 'category', 'ice and snow'],
      ],
      id: 'Earth Cover 9-22',
      layout: {
        visibility: 'visible',
      },
      maxzoom: 22,
      metadata: 'group:area',
      minzoom: 9,
      paint: {
        'fill-antialias': false,
        'fill-color': {
          property: 'category',
          stops: [
            ['woody vegetation', 'rgba(227, 235, 211, 1)'],
            ['cropland', 'rgba(233, 239, 210, 1)'],
            ['herbaceous vegetation', 'rgba(227, 235, 211, 1)'],
            ['bare soil', 'rgba(246, 241, 224, 1)'],
            ['sand', 'rgba(248, 239, 189, 1)'],
            ['ice and snow', 'rgba(245, 255, 255, 1)'],
          ],
          type: 'categorical',
        },
        'fill-opacity': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Earth Cover',
      type: 'fill',
    },
    {
      id: 'Built-up area',
      layout: {
        visibility: 'visible',
      },
      maxzoom: 22,
      metadata: 'group:area',
      minzoom: 0,
      paint: {
        'fill-color': {
          stops: [
            [11.8, 'rgba(240, 238, 234, 1)'],
            [12, 'rgba(235, 237, 240, 1)'],
          ],
        },
        'fill-opacity': {
          stops: [
            [7, 0],
            [7.5, 1],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Built-up area',
      type: 'fill',
    },
    {
      filter: ['all', ['==', 'category', 'forest']],
      id: 'National Forest',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      minzoom: 0,
      paint: {
        'fill-color': 'rgba(218, 231, 195, 1)',
        'fill-opacity': {
          stops: [
            [11, 0.3],
            [12, 0],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'National park',
      type: 'fill',
    },
    {
      filter: ['all', ['!=', 'category', 'forest']],
      id: 'National park',
      layout: {
        visibility: 'visible',
      },
      maxzoom: 22,
      metadata: 'group:area',
      minzoom: 0,
      paint: {
        'fill-color': {
          stops: [
            [6, 'rgba(189, 219, 166, 1)'],
            [8, 'rgba(199, 226, 180, 1)'],
          ],
        },
        'fill-opacity': {
          stops: [
            [8, 0.6],
            [9, 0.6],
            [11, 0.4],
            [12, 0.2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'National park',
      type: 'fill',
    },
    {
      id: 'National or state park',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      minzoom: 4,
      paint: {
        'fill-color': 'rgb(215, 235, 191)',
      },
      source: 'vectorTiles',
      'source-layer': 'National or state park',
      type: 'fill',
    },
    {
      id: 'Park',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(216, 228, 203, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Park',
      type: 'fill',
    },
    {
      id: 'State or province park',
      layout: {
        visibility: 'visible',
      },
      maxzoom: 22,
      metadata: 'group:area',
      minzoom: 4,
      paint: {
        'fill-color': {
          stops: [
            [6, 'rgba(189, 219, 166, 1)'],
            [8, 'rgba(199, 226, 180, 1)'],
          ],
        },
        'fill-opacity': {
          stops: [
            [8, 0.5],
            [11, 0.7],
            [12, 0.4],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'State or province park',
      type: 'fill',
    },
    {
      id: 'Forest',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(209, 228, 188, 1)',
        'fill-opacity': {
          stops: [
            [4, 0],
            [5, 0.5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Forest',
      type: 'fill',
    },
    {
      id: 'Regional park',
      metadata: 'group:area',
      minzoom: 4,
      paint: {
        'fill-color': {
          stops: [
            [6, 'rgba(203, 221, 186, 1)'],
            [7, 'rgba(201, 224, 199, 1)'],
            [10, 'rgba(209, 228, 188, 1)'],
          ],
        },
        'fill-opacity': {
          stops: [
            [4, 0],
            [5, 0.5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Regional park',
      type: 'fill',
    },
    {
      id: 'County park',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(209, 228, 188, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'County park',
      type: 'fill',
    },
    {
      filter: ['any', ['==', 'category', 'bare soil'], ['==', 'category', 'sand'], ['==', 'category', 'ice and snow']],
      id: 'Earth Cover Snow-Sand',
      layout: {
        visibility: 'visible',
      },
      maxzoom: 22,
      metadata: 'group:area',
      minzoom: 0,
      paint: {
        'fill-antialias': false,
        'fill-color': {
          default: 'rgba(0, 0, 0, 0)',
          property: 'category',
          stops: [
            ['bare soil', 'rgba(246, 241, 224, 1)'],
            ['sand', 'rgba(247, 241, 208, 1)'],
            ['ice and snow', 'rgba(245, 255, 255, 1)'],
          ],
          type: 'categorical',
        },
        'fill-opacity': 0.8,
      },
      source: 'vectorTiles',
      'source-layer': 'Earth Cover',
      type: 'fill',
    },
    {
      id: 'Military',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(219, 226, 219, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Military',
      type: 'fill',
    },
    {
      id: 'Town railway ground',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(236, 236, 236)',
      },
      source: 'vectorTiles',
      'source-layer': 'Town railway ground',
      type: 'fill',
    },
    {
      id: 'City park',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      minzoom: 2,
      paint: {
        'fill-color': 'rgba(203, 223, 174, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'City park',
      type: 'fill',
    },
    {
      id: 'Cemetery',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(199, 202, 182, 0.3)',
      },
      source: 'vectorTiles',
      'source-layer': 'Cemetery',
      type: 'fill',
    },
    {
      filter: ['all', ['!=', 'category', 'forest']],
      id: 'National Park-Outline',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      minzoom: 0,
      paint: {
        'line-blur': 2,
        'line-color': 'rgba(149, 173, 134, 1)',
        'line-width': {
          stops: [
            [9, 1],
            [10, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'National park',
      type: 'line',
    },
    {
      id: 'Industrial area',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(228, 230, 232, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Industrial area',
      type: 'fill',
    },
    {
      id: 'Industrial harbor area',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(228, 230, 232, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Industrial harbor area',
      type: 'fill',
    },
    {
      id: 'Hospital',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(242, 230, 230, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Hospital',
      type: 'fill',
    },
    {
      id: 'Town greens',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(218, 232, 208, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Town greens',
      type: 'fill',
    },
    {
      id: 'Golf',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(210, 233, 194, 1)',
        'fill-outline-color': 'rgba(141, 204, 95, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Golf',
      type: 'fill',
    },
    {
      id: 'Shopping',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(254, 247, 224, 1)',
        'fill-outline-color': 'rgba(253, 226, 152, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Shopping',
      type: 'fill',
    },
    {
      id: 'Airport',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(216, 221, 230, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Airport',
      type: 'fill',
    },
    {
      id: 'Runway',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(207, 212, 219, 1)',
        'fill-outline-color': 'rgba(202, 207, 214, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Runway',
      type: 'fill',
    },
    {
      id: 'Town paved area',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(236, 236, 236)',
      },
      source: 'vectorTiles',
      'source-layer': 'Town paved area',
      type: 'fill',
    },
    {
      id: 'Town garden path',
      metadata: 'group:area',
      paint: {
        'fill-color': {
          stops: [
            [14, 'rgba(224, 241, 205, 0.5)'],
            [16, 'rgb(224, 241, 205)'],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Town garden path',
      type: 'fill',
    },
    {
      id: 'Town school ground',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(244, 234, 213)',
      },
      source: 'vectorTiles',
      'source-layer': 'Town school ground',
      type: 'fill',
    },
    {
      id: 'University',
      metadata: 'group:area',
      paint: {
        'fill-color': {
          stops: [
            [14, 'rgba(242, 238, 222, 1)'],
            [15, 'rgba(236, 235, 227, 1)'],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'University',
      type: 'fill',
    },
    {
      id: 'School',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(244, 234, 213)',
      },
      source: 'vectorTiles',
      'source-layer': 'School',
      type: 'fill',
    },
    {
      id: 'Zoo',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(244, 240, 209)',
      },
      source: 'vectorTiles',
      'source-layer': 'Zoo',
      type: 'fill',
    },
    {
      id: 'Stadium',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(238, 241, 222)',
      },
      source: 'vectorTiles',
      'source-layer': 'Stadium',
      type: 'fill',
    },
    {
      id: 'Amusement park',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(244, 240, 209)',
      },
      source: 'vectorTiles',
      'source-layer': 'Amusement park',
      type: 'fill',
    },
    {
      id: 'Town grass',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(218, 232, 208, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Town grass',
      type: 'fill',
    },
    {
      id: 'Railway Station-Shadow',
      metadata: 'group:area',
      minzoom: 16,
      paint: {
        'fill-color': 'rgba(164, 177, 184, 1)',
        'fill-outline-color': 'rgba(164, 177, 184, 1)',
        'fill-translate': [3, 3],
      },
      source: 'vectorTiles',
      'source-layer': 'Railway Station',
      type: 'fill',
    },
    {
      id: 'Railway Station',
      metadata: 'group:area',
      minzoom: 16,
      paint: {
        'fill-color': 'rgb(210, 224, 232)',
        'fill-outline-color': 'rgb(199, 216, 224)',
      },
      source: 'vectorTiles',
      'source-layer': 'Railway Station',
      type: 'fill',
    },
    {
      id: 'Parking area',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(218, 229, 241, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Parking area',
      type: 'fill',
    },
    {
      id: 'Subway Station',
      metadata: 'group:area',
      minzoom: 16,
      paint: {
        'fill-color': 'rgb(210, 224, 232)',
        'fill-outline-color': 'rgb(199, 216, 224)',
      },
      source: 'vectorTiles',
      'source-layer': 'Subway Station',
      type: 'fill',
    },
    {
      id: 'Place of worship-shadow',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(212, 200, 171, 0.49)',
        'fill-outline-color': 'rgb(232, 218, 201)',
        'fill-translate': [2, 2],
      },
      source: 'vectorTiles',
      'source-layer': 'Place of worship',
      type: 'fill',
    },
    {
      id: 'Place of worship',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(245, 231, 197, 0.49)',
        'fill-outline-color': 'rgb(232, 218, 201)',
      },
      source: 'vectorTiles',
      'source-layer': 'Place of worship',
      type: 'fill',
    },
    {
      id: 'Other water',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'fill-color': {
          stops: [
            [6, 'rgba(172, 210, 235, 1)'],
            [9, 'rgba(169, 209, 235, 1)'],
          ],
        },
        'fill-outline-color': {
          stops: [
            [13, 'rgba(202, 231, 246, 1)'],
            [14, 'rgba(149, 202, 231, 1)'],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Other water',
      type: 'fill',
    },
    {
      id: 'Intermittent water',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(194, 219, 235, 1)',
        'fill-outline-color': 'rgba(202, 231, 246, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Intermittent water',
      type: 'fill',
    },
    {
      id: 'Lake',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      paint: {
        'fill-color': {
          stops: [
            [6, 'rgba(183, 213, 231, 1)'],
            [9, 'rgba(169, 209, 235, 1)'],
          ],
        },
        'fill-outline-color': {
          stops: [
            [13, 'rgba(202, 231, 246, 1)'],
            [14, 'rgba(149, 202, 231, 1)'],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Lake',
      type: 'fill',
    },
    {
      id: 'Ocean or sea',
      metadata: 'group:area',
      paint: {
        'fill-color': {
          stops: [
            [3, 'rgba(183, 214, 231, 1)'],
            [5, 'rgba(172, 209, 234, 1)'],
            [9, 'rgba(169, 209, 235, 1)'],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Ocean or sea',
      type: 'fill',
    },
    {
      id: 'River',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(158, 223, 252)',
      },
      source: 'vectorTiles',
      'source-layer': 'River',
      type: 'fill',
    },
    {
      id: 'Government Administration Office-shadow',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(211, 199, 184, 1)',
        'fill-outline-color': 'rgb(232, 218, 201)',
        'fill-translate': [2, 2],
      },
      source: 'vectorTiles',
      'source-layer': 'Government Administration Office',
      type: 'fill',
    },
    {
      id: 'Government Administration Office',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(245, 235, 197, 0.49)',
        'fill-outline-color': 'rgb(232, 218, 201)',
        'fill-translate': [0, 0],
      },
      source: 'vectorTiles',
      'source-layer': 'Government Administration Office',
      type: 'fill',
    },
    {
      id: 'Other building-shadow',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      minzoom: 12,
      paint: {
        'fill-color': 'rgba(208, 211, 212, 1)',
        'fill-outline-color': 'rgba(214, 211, 203, 1)',
        'fill-translate': [2, 2],
      },
      source: 'vectorTiles',
      'source-layer': 'Other building',
      type: 'fill',
    },
    {
      id: 'Other building',
      layout: {
        visibility: 'visible',
      },
      metadata: 'group:area',
      minzoom: 12,
      paint: {
        'fill-color': 'rgba(223, 228, 232, 1)',
        'fill-outline-color': 'rgba(214, 211, 203, 1)',
      },
      source: 'vectorTiles',
      'source-layer': 'Other building',
      type: 'fill',
    },
    {
      id: 'School building-shadow',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(214, 205, 188, 1)',
        'fill-outline-color': 'rgba(202, 202, 181, 0.51)',
        'fill-translate': [2, 2],
      },
      source: 'vectorTiles',
      'source-layer': 'School building',
      type: 'fill',
    },
    {
      id: 'School building',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(237, 227, 207)',
        'fill-outline-color': 'rgba(202, 202, 181, 0.51)',
      },
      source: 'vectorTiles',
      'source-layer': 'School building',
      type: 'fill',
    },
    {
      id: 'Institution',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(245, 231, 197, 0.49)',
        'fill-outline-color': 'rgb(232, 218, 201)',
      },
      source: 'vectorTiles',
      'source-layer': 'Institution',
      type: 'fill',
    },
    {
      id: 'Factory building',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(234, 241, 234, 0.51)',
        'fill-outline-color': 'rgba(195, 195, 195, 0.5)',
      },
      source: 'vectorTiles',
      'source-layer': 'Factory building',
      type: 'fill',
    },
    {
      id: 'Hospital building-shadow',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(214, 198, 190, 1)',
        'fill-outline-color': 'rgba(214, 198, 190, 0.5)',
        'fill-translate': [2, 2],
      },
      source: 'vectorTiles',
      'source-layer': 'Hospital building',
      type: 'fill',
    },
    {
      id: 'Hospital building',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(235, 215, 202)',
        'fill-outline-color': 'rgba(214, 198, 190, 0.5)',
      },
      source: 'vectorTiles',
      'source-layer': 'Hospital building',
      type: 'fill',
    },
    {
      id: 'Hotel building-shadow',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(192, 188, 164, 1)',
        'fill-outline-color': 'rgb(218, 214, 180)',
        'fill-translate': [2, 2],
      },
      source: 'vectorTiles',
      'source-layer': 'Hotel building',
      type: 'fill',
    },
    {
      id: 'Hotel building',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(234, 230, 200)',
        'fill-outline-color': 'rgb(218, 214, 180)',
      },
      source: 'vectorTiles',
      'source-layer': 'Hotel building',
      type: 'fill',
    },
    {
      id: 'Cultural Facility-shadow',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgba(207, 204, 177, 1)',
        'fill-outline-color': 'rgb(218, 214, 180)',
        'fill-translate': [2, 2],
      },
      source: 'vectorTiles',
      'source-layer': 'Cultural Facility',
      type: 'fill',
    },
    {
      id: 'Cultural Facility',
      metadata: 'group:area',
      paint: {
        'fill-color': 'rgb(234, 230, 200)',
        'fill-outline-color': 'rgb(218, 214, 180)',
      },
      source: 'vectorTiles',
      'source-layer': 'Cultural Facility',
      type: 'fill',
    },
    {
      id: 'Water line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      paint: {
        'line-color': 'rgba(172, 210, 235, 1)',
        'line-width': {
          stops: [
            [11, 0.6],
            [15, 1.2],
            [18, 1.8],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Water line',
      type: 'line',
    },
    {
      id: 'Ferry road',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgba(133, 180, 202, 1)',
        'line-dasharray': [2, 3],
        'line-width': {
          stops: [
            [13, 1],
            [16, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Ferry road',
      type: 'line',
    },
    {
      filter: ['==', ['get', 'category'], 'Rail'],
      id: 'Ferry road rail',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      paint: {
        'line-color': 'rgba(255, 242, 214, 1)',
        'line-dasharray': [3, 4],
        'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1.5, 16, 2.5],
      },
      source: 'vectorTiles',
      'source-layer': 'Ferry road',
      type: 'line',
    },
    {
      id: 'Toll local road t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [12, 4],
            [18, 20],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      id: 'Toll major local road t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [12, 4],
            [18, 20],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      id: 'Toll connecting road t',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(190, 191, 250, 1)',
        'line-width': {
          stops: [
            [10, 4],
            [13, 8],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      id: 'Toll secondary road t',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(190, 191, 250, 1)',
        'line-width': {
          stops: [
            [10, 4],
            [13, 8],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      id: 'Toll major road t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
        visibility: 'visible',
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(211, 168, 247, 1)',
        'line-width': {
          stops: [
            [7, 0],
            [9, 3],
            [13, 9],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      id: 'Toll international road t',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-blur': 0,
        'line-color': 'rgba(190, 191, 250, 1)',
        'line-width': {
          stops: [
            [10, 6],
            [13, 10],
            [18, 25],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      id: 'Toll motorway t',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgba(211, 168, 247, 1)',
        'line-width': {
          stops: [
            [8, 5],
            [13, 8],
            [18, 26],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      id: 'Toll minor local road tunnel t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [15, 10],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'line',
    },
    {
      id: 'Toll local road tunnel t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [12, 4],
            [18, 20],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'line',
    },
    {
      id: 'Toll major local road tunnel t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [12, 4],
            [18, 20],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'line',
    },
    {
      id: 'Toll connecting road tunnel t',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [10, 4],
            [13, 9],
            [18, 22],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'line',
    },
    {
      id: 'Toll secondary road tunnel t',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [10, 4],
            [13, 9],
            [18, 22],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'line',
    },
    {
      id: 'Toll major road tunnel t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': {
          stops: [
            [9, 5],
            [13, 9],
            [18, 23],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'line',
    },
    {
      id: 'Toll international road tunnel t',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(211, 168, 247, 1)',
        'line-width': {
          stops: [
            [10, 6],
            [13, 8],
            [18, 25],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'line',
    },
    {
      id: 'Toll motorway tunnel t',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 7,
      paint: {
        'line-color': 'rgba(211, 168, 247, 1)',
        'line-width': {
          stops: [
            [8, 1],
            [13, 5],
            [18, 26],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll minor local road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': ['step', ['zoom'], 2, 14, 4, 15, 10, 16, 14, 17, 16, 18, 18],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll local road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll major local road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(191, 187, 178)',
        'line-width': {
          stops: [
            [12, 2.5],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll connecting road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(209, 201, 184)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 7],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll secondary road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(212, 194, 167)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 6],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll major road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(201, 193, 143)',
        'line-width': {
          stops: [
            [8, 2.5],
            [13, 6],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll international road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(207, 173, 130)',
        'line-width': {
          stops: [
            [5, 1],
            [13, 7],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Toll motorway outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-width': {
          stops: [
            [8, 3],
            [13, 7],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Non public road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(204, 204, 201)',
        'line-width': {
          stops: [
            [14, 1.5],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Minor local road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Local road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Major local road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(191, 187, 178)',
        'line-width': {
          stops: [
            [12, 2.5],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Connecting road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(209, 201, 184)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 7],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Secondary road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [14, 'rgba(196, 164, 83, 1)'],
            [16, 'rgba(194, 194, 194, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 2.5],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Major road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(202, 126, 37, 1)'],
            [14, 'rgba(189, 152, 107, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 1],
            [10, 3],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'International road outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(235, 182, 122, 1)'],
            [10, 'rgba(216, 166, 108, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [7, 1],
            [8, 3],
            [10, 3],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Motorway outline - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-blur': {
          stops: [
            [5, 1],
            [8, 0],
          ],
        },
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [8, 3.5],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Non public road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(241, 243, 231)',
        'line-width': {
          stops: [
            [13, 0.5],
            [14, 1.5],
            [18, 14],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Non public road dots - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(155, 155, 155)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [15, 1],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Pedestrian road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(231, 232, 203)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 7],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Walkway road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(231, 232, 203)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 7],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Walkway road dots - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(202, 211, 200)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [14, 0.5],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -2],
      id: 'Parking road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(255, 255, 255)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 6],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll minor local road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': ['step', ['zoom'], 1, 14, 2, 15, 5, 16, 8, 17, 10, 18, 12],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll minor local road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [14, 2],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll minor local road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Minor local road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [13, 0.5],
            [15, 2],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Minor local road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Minor local road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll local road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll local road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll local road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Local road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Local road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Local road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll major local road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll major local road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major local road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 13,
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Major local road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Major local road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major local road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll connecting road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll connecting road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll connecting road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Connecting road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Connecting road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Connecting road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll secondary road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 223, 191)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll secondary road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll secondary road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Secondary road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(255, 247, 229, 1)'],
            [10, 'rgba(255, 255, 255, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [9, 1],
            [10, 2],
            [13, 2],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Secondary road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Secondary road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll major road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll major road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Major road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [11, 'rgba(255, 233, 207, 1)'],
            [12, 'rgba(255, 231, 199, 1)'],
            [14, 'rgba(255, 242, 217, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Major road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll international road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll international road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll international road road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'International road - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(250, 237, 202, 1)'],
            [7, 'rgba(230, 215, 176, 1)'],
            [9, 'rgba(255, 244, 200, 1)'],
            [10, 'rgba(255, 241, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'International road under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'International road arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Toll motorway - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Toll motorway under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll motorway arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true]],
      id: 'Motorway - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['==', 'under_construction', true]],
      id: 'Motorway under construction - level-2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Motorway arrow - level-2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll minor local road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': ['step', ['zoom'], 2, 14, 4, 15, 10, 16, 14, 17, 16, 18, 18],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll local road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll major local road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(191, 187, 178)',
        'line-width': {
          stops: [
            [12, 2.5],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll connecting road outline - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(209, 201, 184)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 7],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll secondary road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(212, 194, 167)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 6],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll major road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(201, 193, 143)',
        'line-width': {
          stops: [
            [8, 2.5],
            [13, 6],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll international road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(207, 173, 130)',
        'line-width': {
          stops: [
            [5, 1],
            [13, 7],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Toll motorway outline - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-width': {
          stops: [
            [8, 3],
            [13, 7],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Non public road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(204, 204, 201)',
        'line-width': {
          stops: [
            [14, 1.5],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Minor local road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [13, 1],
            [18, 14],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Local road outline - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [13, 0],
            [14, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 2],
            [15, 8],
            [18, 17],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Major local road outline - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1.5],
            [15, 8],
            [18, 17],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Connecting road outline - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 5],
            [18, 12],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Secondary road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [14, 'rgba(196, 164, 83, 1)'],
            [16, 'rgba(194, 194, 194, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 2.5],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Major road outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(202, 126, 37, 1)'],
            [14, 'rgba(189, 152, 107, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 1],
            [10, 3],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'International road outline - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(235, 182, 122, 1)'],
            [10, 'rgba(216, 166, 108, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [7, 1],
            [8, 3],
            [10, 3],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Motorway outline - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-blur': {
          stops: [
            [5, 1],
            [8, 0],
          ],
        },
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [8, 3.5],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Non public road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(241, 243, 231)',
        'line-width': {
          stops: [
            [13, 0.5],
            [14, 1.5],
            [18, 14],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Non public road dots - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(155, 155, 155)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [15, 1],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Pedestrian road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(231, 232, 203)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 7],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Walkway road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-dasharray': [1, 2],
        'line-width': {
          stops: [
            [14, 1],
            [18, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Walkway road dots - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'none',
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(202, 211, 200)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [14, 0.5],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', -1],
      id: 'Parking road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(255, 255, 255)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 6],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll minor local road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': ['step', ['zoom'], 1, 14, 2, 15, 5, 16, 8, 17, 10, 18, 12],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll minor local road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [14, 2],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll minor local road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Minor local road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [13, 0.5],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Minor local road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Minor local road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll local road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll local road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll local road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Local road - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-gap-width': 0,
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Local road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Local road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll major local road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll major local road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major local road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Major local road - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-gap-width': 0,
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Major local road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major local road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll connecting road - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll connecting road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll connecting road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Connecting road - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 4],
            [18, 10],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Connecting road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Connecting road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll secondary road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll secondary road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll secondary road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Secondary road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(255, 247, 229, 1)'],
            [10, 'rgba(255, 255, 255, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [9, 1],
            [10, 2],
            [13, 2],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Secondary road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Secondary road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll major road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll major road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Major road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [11, 'rgba(255, 233, 207, 1)'],
            [12, 'rgba(255, 231, 199, 1)'],
            [14, 'rgba(255, 242, 217, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Major road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll international road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(235, 192, 132, 1)'],
            [10, 'rgba(255, 244, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 1],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll international road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll international road road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'International road - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(250, 237, 202, 1)'],
            [7, 'rgba(230, 215, 176, 1)'],
            [9, 'rgba(255, 244, 200, 1)'],
            [10, 'rgba(255, 241, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'International road under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'International road arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Toll motorway - level-1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Toll motorway under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll motorway arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true]],
      id: 'Motorway - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['==', 'under_construction', true]],
      id: 'Motorway under construction - level-1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', -1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Motorway arrow - level-1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      id: 'Toll minor local road tunnel outline',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(184, 184, 180)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'line',
    },
    {
      id: 'Toll local road tunnel outline',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(184, 184, 180)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'line',
    },
    {
      id: 'Toll major local road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1.5],
            [15, 8],
            [18, 17],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'line',
    },
    {
      id: 'Toll connecting road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgb(184, 176, 162)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 7],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'line',
    },
    {
      id: 'Toll secondary road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(173, 158, 134)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 6],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'line',
    },
    {
      id: 'Toll major road tunnel outline',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(201, 193, 143)',
        'line-width': {
          stops: [
            [8, 2.5],
            [13, 6],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'line',
    },
    {
      id: 'Toll international road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgb(184, 154, 116)',
        'line-width': {
          stops: [
            [5, 1],
            [13, 7],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'line',
    },
    {
      id: 'Toll motorway tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 7,
      paint: {
        'line-color': 'rgb(176, 116, 76)',
        'line-width': {
          stops: [
            [8, 3],
            [13, 7],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'line',
    },
    {
      id: 'Minor local road tunnel outline',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'line',
    },
    {
      id: 'Local road tunnel outline',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'line',
    },
    {
      id: 'Major local road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1.5],
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'line',
    },
    {
      id: 'Connecting road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 5],
            [18, 12],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'line',
    },
    {
      id: 'Secondary road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 4],
            [13, 6],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'line',
    },
    {
      id: 'Major road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(201, 193, 143)',
        'line-width': {
          stops: [
            [8, 2.5],
            [13, 6],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'line',
    },
    {
      id: 'International road tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgba(175, 172, 169, 1)',
        'line-width': {
          stops: [
            [5, 1],
            [13, 7],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'line',
    },
    {
      id: 'Motorway tunnel outline',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 7,
      paint: {
        'line-color': 'rgba(175, 159, 148, 1)',
        'line-width': {
          stops: [
            [8, 3],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'line',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll minor local road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(228, 224, 217)',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll minor local road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(228, 224, 217)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll minor local road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Minor local road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgba(245, 244, 242, 1)',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Minor local road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(228, 224, 217)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Minor local road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll local road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(228, 224, 217)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll local road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(228, 224, 217)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll local road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Local road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgba(245, 244, 242, 1)',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Local road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(228, 224, 217)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Local road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll major local road tunnel',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgba(233, 230, 230, 1)',
        'line-gap-width': 0,
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll major local road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(199, 190, 171)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major local road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Major local road tunnel',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgba(233, 230, 230, 1)',
        'line-gap-width': 0,
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Major local road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(199, 190, 171)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major local road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll connecting road tunnel',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(228, 227, 225, 1)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll connecting road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgb(214, 206, 188)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll connecting road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Connecting road tunnel',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(233, 234, 236, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 4],
            [18, 10],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Connecting road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgb(214, 206, 188)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Connecting road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll secondary road tunnel',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(241, 241, 241, 1)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll secondary road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(209, 190, 163)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll secondary road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Secondary road tunnel',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgba(235, 230, 230, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Secondary road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(209, 190, 163)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Secondary road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll major road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(234, 223, 159)',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll major road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(234, 223, 159)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Major road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgba(255, 231, 198, 1)',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Major road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(234, 223, 159)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll international road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(235, 192, 132, 1)'],
            [10, 'rgba(236, 225, 200, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 1],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll international road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgb(214, 164, 124)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll international road tunnel road arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'International road tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgba(243, 230, 202, 1)',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'International road tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgb(214, 164, 124)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'International road tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Toll motorway tunnel',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 6,
      paint: {
        'line-color': 'rgba(230, 214, 166, 1)',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Toll motorway tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 6,
      paint: {
        'line-color': 'rgb(212, 142, 95)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll motorway tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['!=', 'under_construction', true],
      id: 'Motorway tunnel',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 6,
      paint: {
        'line-color': 'rgba(230, 214, 166, 1)',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'line',
    },
    {
      filter: ['==', 'under_construction', true],
      id: 'Motorway tunnel under construction',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 6,
      paint: {
        'line-color': 'rgb(212, 142, 95)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'line',
    },
    {
      filter: ['all', ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Motorway tunnel arrow',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'symbol',
    },
    {
      id: 'Railway outline-copy',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
        visibility: 'visible',
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(196, 196, 201, 1)',
        'line-width': {
          stops: [
            [13, 4],
            [18, 6],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Railway',
      type: 'line',
    },
    {
      id: 'Railway outline',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
        visibility: 'visible',
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 4],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Railway',
      type: 'line',
    },
    {
      id: 'Railway',
      layout: {
        'line-cap': 'butt',
        'line-join': 'bevel',
        'line-round-limit': 1.05,
        visibility: 'visible',
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(182, 182, 185, 1)',
        'line-dasharray': [0.1, 0.5],
        'line-translate': [0, 0],
        'line-width': {
          stops: [
            [13, 5],
            [15, 5],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Railway',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll minor local road outline - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': ['step', ['zoom'], 2, 14, 4, 15, 10, 16, 14, 17, 16, 18, 18],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll local road outline - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll major local road outline - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(191, 187, 178)',
        'line-width': {
          stops: [
            [12, 2.5],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll connecting road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 4],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll secondary road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 4],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll major road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgba(246, 200, 154, 1)',
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 2.5],
            [10, 3.5],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll international road outline - level0',
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 255, 255, 1)'],
            [10, 'rgba(247, 192, 134, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [10, 2],
            [13, 4.5],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Toll motorway outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-blur': {
          stops: [
            [5, 1],
            [8, 0],
          ],
        },
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [5.2, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [8, 3.5],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Non public road outline - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(204, 204, 201)',
        'line-width': {
          stops: [
            [14, 1.5],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Minor local road outline - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [13, 0],
            [14, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1],
            [15, 3],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Local road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [13, 0],
            [14, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 2],
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Major local road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1.5],
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Connecting road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 4],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Secondary road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      maxzoom: 22,
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': {
          stops: [
            [14, 'rgba(196, 164, 83, 1)'],
            [16, 'rgba(194, 194, 194, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [9, 0],
            [9.5, 1],
          ],
        },
        'line-width': {
          stops: [
            [9, 3],
            [10, 4],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Major road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(202, 126, 37, 1)'],
            [14, 'rgba(189, 152, 107, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [8.5, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 1],
            [10, 3],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'International road outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(235, 182, 122, 1)'],
            [10, 'rgba(216, 166, 108, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [7, 1],
            [8, 3],
            [10, 3],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Motorway outline - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-blur': {
          stops: [
            [5, 1],
            [8, 0],
          ],
        },
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [5.2, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [8, 3.5],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Non public road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [13, 0.5],
            [14, 1.5],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Non public road dots - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(155, 155, 155)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [15, 1],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Pedestrian road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgba(255, 255, 255, 0.46)',
        'line-dasharray': [1, 2],
        'line-width': {
          stops: [
            [14, 1],
            [18, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Walkway road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-dasharray': [1, 2],
        'line-width': {
          stops: [
            [14, 1],
            [18, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Walkway road dots - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'none',
      },
      metadata: 'group:road',
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [14, 0.5],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['!has', 'z_level'],
      id: 'Parking road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
        visibility: 'visible',
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(255, 255, 255)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll minor local road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': ['step', ['zoom'], 1, 14, 2, 15, 5, 16, 8, 17, 10, 18, 12],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll minor local road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [14, 2],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll minor local road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Minor local road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(248, 248, 248, 1)'],
            [14, 'rgba(255, 255, 255, 1)'],
          ],
        },
        'line-width': {
          stops: [
            [13, 0.5],
            [15, 2],
            [18, 8],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Minor local road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Minor local road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll local road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll local road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll local road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Local road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      maxzoom: 22,
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-gap-width': 0,
        'line-opacity': {
          stops: [
            [12, 0],
            [12.5, 1],
          ],
        },
        'line-width': {
          stops: [
            [11, 1],
            [13, 2],
            [15, 6],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Local road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Local road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll major local road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll major local road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major local road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Major local road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-gap-width': 0,
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Major local road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 12,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major local road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll connecting road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 3],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll connecting road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 11,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll connecting road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Connecting road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 3],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Connecting road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Connecting road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll secondary road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 3],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll secondary road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 10,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll secondary road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Secondary road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-opacity': {
          stops: [
            [9, 0],
            [9.2, 1],
          ],
        },
        'line-width': {
          stops: [
            [9, 2],
            [10, 3],
            [13, 3],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Secondary road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Secondary road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll major road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgba(255, 242, 214, 1)',
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 1],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll major road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 9,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Major road - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': {
          stops: [
            [11, 'rgba(255, 233, 207, 1)'],
            [12, 'rgba(255, 231, 199, 1)'],
            [14, 'rgba(255, 242, 217, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Major road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 8,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll international road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(235, 192, 132, 1)'],
            [10, 'rgba(255, 244, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [8, 1],
            [10, 1],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll international road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 6,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll international road road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'International road - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(250, 237, 202, 1)'],
            [7, 'rgba(230, 215, 176, 1)'],
            [9, 'rgba(255, 244, 200, 1)'],
            [10, 'rgba(255, 241, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'International road under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'International road arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Toll motorway - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [5.2, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Toll motorway under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 6,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll motorway arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true]],
      id: 'Motorway - level0',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [5.2, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['==', 'under_construction', true]],
      id: 'Motorway under construction - level0',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 5,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['!has', 'z_level'], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Motorway arrow - level0',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll minor local road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': ['step', ['zoom'], 2, 14, 4, 15, 10, 16, 14, 17, 16, 18, 18],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll local road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll major local road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(191, 187, 178)',
        'line-width': {
          stops: [
            [12, 2.5],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll connecting road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(209, 201, 184)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 7],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll secondary road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 4],
            [18, 12],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll major road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(246, 200, 154, 1)',
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 2.5],
            [10, 3.5],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll international road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(207, 173, 130)',
        'line-width': {
          stops: [
            [5, 1],
            [13, 7],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Toll motorway outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-width': {
          stops: [
            [8, 3],
            [13, 7],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Non public road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(204, 204, 201)',
        'line-width': {
          stops: [
            [14, 1.5],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Minor local road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [13, 0],
            [14, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1],
            [15, 3],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Local road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Major local road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1.5],
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Connecting road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 5],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Secondary road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [14, 'rgba(196, 164, 83, 1)'],
            [16, 'rgba(194, 194, 194, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 2.5],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Major road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(202, 126, 37, 1)'],
            [14, 'rgba(189, 152, 107, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 1],
            [10, 3],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'International road outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(235, 182, 122, 1)'],
            [10, 'rgba(216, 166, 108, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [7, 1],
            [8, 3],
            [10, 3],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Motorway outline - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-blur': {
          stops: [
            [5, 1],
            [8, 0],
          ],
        },
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [8, 3.5],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Non public road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [13, 0.5],
            [14, 1.5],
            [18, 14],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Non public road dots - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(155, 155, 155)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [15, 1],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Pedestrian road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(231, 232, 203)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 7],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Walkway road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-dasharray': [1, 2],
        'line-width': {
          stops: [
            [14, 1],
            [18, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Walkway road dots - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'none',
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(202, 211, 200)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [14, 0.5],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 1],
      id: 'Parking road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(255, 255, 255)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 6],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll minor local road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': ['step', ['zoom'], 1, 14, 2, 15, 5, 16, 8, 17, 10, 18, 12],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll minor local road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [14, 2],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll minor local road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Minor local road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(248, 248, 248, 1)'],
            [14, 'rgba(255, 255, 255, 1)'],
          ],
        },
        'line-width': {
          stops: [
            [13, 0.5],
            [15, 2],
            [18, 8],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Minor local road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Minor local road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll local road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll local road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll local road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Local road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Local road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Local road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll major local road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll major local road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major local road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Major local road - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-gap-width': 0,
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Major local road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major local road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll connecting road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll connecting road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll connecting road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Connecting road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 4],
            [18, 10],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Connecting road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Connecting road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll secondary road - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 3],
            [18, 10],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll secondary road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll secondary road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Secondary road - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(255, 247, 229, 1)'],
            [10, 'rgba(255, 255, 255, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [9, 1],
            [10, 2],
            [13, 2],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Secondary road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Secondary road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll major road - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 242, 214, 1)',
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 1],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll major road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Major road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [11, 'rgba(255, 233, 207, 1)'],
            [12, 'rgba(255, 231, 199, 1)'],
            [14, 'rgba(255, 242, 217, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Major road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll international road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(235, 192, 132, 1)'],
            [10, 'rgba(255, 244, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [8, 1],
            [10, 1],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll international road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll international road road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'International road - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(250, 237, 202, 1)'],
            [7, 'rgba(230, 215, 176, 1)'],
            [9, 'rgba(255, 244, 200, 1)'],
            [10, 'rgba(255, 241, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'International road under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'International road arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Toll motorway - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Toll motorway under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll motorway arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true]],
      id: 'Motorway - level+1',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['==', 'under_construction', true]],
      id: 'Motorway under construction - level+1',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 1], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Motorway arrow - level+1',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll minor local road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': ['step', ['zoom'], 2, 14, 4, 15, 10, 16, 14, 17, 16, 18, 18],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll local road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll major local road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(191, 187, 178)',
        'line-width': {
          stops: [
            [12, 2.5],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll connecting road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(209, 201, 184)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 7],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll secondary road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(212, 194, 167)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 6],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll major road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(201, 193, 143)',
        'line-width': {
          stops: [
            [8, 2.5],
            [13, 6],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll international road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(207, 173, 130)',
        'line-width': {
          stops: [
            [5, 1],
            [13, 7],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Toll motorway outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-width': {
          stops: [
            [8, 3],
            [13, 7],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Non public road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(204, 204, 201)',
        'line-width': {
          stops: [
            [14, 1.5],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Minor local road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Local road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Major local road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1.5],
            [15, 8],
            [18, 17],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Connecting road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 4],
            [18, 12],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Secondary road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [14, 'rgba(196, 164, 83, 1)'],
            [16, 'rgba(194, 194, 194, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 2.5],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Major road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(202, 126, 37, 1)'],
            [14, 'rgba(189, 152, 107, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 1],
            [10, 3],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'International road outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(235, 182, 122, 1)'],
            [10, 'rgba(216, 166, 108, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [7, 1],
            [8, 3],
            [10, 3],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Motorway outline - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-blur': {
          stops: [
            [5, 1],
            [8, 0],
          ],
        },
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [8, 3.5],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Non public road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(241, 243, 231)',
        'line-width': {
          stops: [
            [13, 0.5],
            [14, 1.5],
            [18, 14],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Non public road dots - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(155, 155, 155)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [15, 1],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Pedestrian road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(231, 232, 203)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 7],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Walkway road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(231, 232, 203)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 7],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Walkway road dots - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(202, 211, 200)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [14, 0.5],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 2],
      id: 'Parking road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(255, 255, 255)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 6],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll minor local road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': ['step', ['zoom'], 1, 14, 2, 15, 5, 16, 8, 17, 10, 18, 12],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll minor local road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [14, 2],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll minor local road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Minor local road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Minor local road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Minor local road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll local road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll local road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll local road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Local road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Local road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Local road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll major local road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll major local road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major local road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Major local road - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-gap-width': 0,
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Major local road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major local road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll connecting road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll connecting road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll connecting road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Connecting road - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 3],
            [18, 10],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Connecting road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Connecting road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll secondary road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 223, 191)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll secondary road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll secondary road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Secondary road - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(255, 247, 229, 1)'],
            [10, 'rgba(255, 255, 255, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [9, 1],
            [10, 2],
            [13, 2],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Secondary road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Secondary road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll major road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll major road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Major road - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [11, 'rgba(255, 233, 207, 1)'],
            [12, 'rgba(255, 231, 199, 1)'],
            [14, 'rgba(255, 242, 217, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Major road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll international road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(235, 192, 132, 1)'],
            [10, 'rgba(255, 244, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [8, 1],
            [10, 1],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll international road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll international road road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'International road - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(250, 237, 202, 1)'],
            [7, 'rgba(230, 215, 176, 1)'],
            [9, 'rgba(255, 244, 200, 1)'],
            [10, 'rgba(255, 241, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'International road under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'International road arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Toll motorway - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Toll motorway under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll motorway arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true]],
      id: 'Motorway - level+2',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['==', 'under_construction', true]],
      id: 'Motorway under construction - level+2',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 2], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Motorway arrow - level+2',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll minor local road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(190, 191, 250)',
        'line-width': ['step', ['zoom'], 2, 14, 4, 15, 10, 16, 14, 17, 16, 18, 18],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll local road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll major local road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(191, 187, 178)',
        'line-width': {
          stops: [
            [12, 2.5],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll connecting road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(209, 201, 184)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 7],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll secondary road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(212, 194, 167)',
        'line-width': {
          stops: [
            [10, 3],
            [13, 6],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll major road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(246, 200, 154, 1)',
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 2.5],
            [10, 3.5],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll international road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(207, 173, 130)',
        'line-width': {
          stops: [
            [5, 1],
            [13, 7],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Toll motorway outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-width': {
          stops: [
            [8, 3],
            [13, 7],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Non public road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(204, 204, 201)',
        'line-width': {
          stops: [
            [14, 1.5],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Minor local road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Local road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-width': {
          stops: [
            [15, 8],
            [18, 17],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Major local road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(194, 194, 194)',
        'line-opacity': {
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
        'line-width': {
          stops: [
            [13, 1.5],
            [15, 8],
            [18, 17],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Connecting road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(194, 194, 194, 1)',
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 4],
            [18, 12],
            [19, 55],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Secondary road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [14, 'rgba(196, 164, 83, 1)'],
            [16, 'rgba(194, 194, 194, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
        'line-width': {
          stops: [
            [10, 2.5],
            [13, 2.5],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Major road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [12, 'rgba(202, 126, 37, 1)'],
            [14, 'rgba(189, 152, 107, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 1],
            [10, 3],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'International road outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [7, 'rgba(235, 182, 122, 1)'],
            [10, 'rgba(216, 166, 108, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [7, 1],
            [8, 3],
            [10, 3],
            [13, 4],
            [18, 19],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Motorway outline - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-blur': {
          stops: [
            [5, 1],
            [8, 0],
          ],
        },
        'line-color': {
          stops: [
            [7, 'rgba(255, 147, 36, 1)'],
            [9, 'rgba(248, 161, 71, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [8, 3.5],
            [13, 6],
            [18, 21],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Non public road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(241, 243, 231)',
        'line-width': {
          stops: [
            [13, 0.5],
            [14, 1.5],
            [18, 14],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Non public road dots - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      metadata: 'group:road',
      minzoom: 15,
      paint: {
        'line-color': 'rgb(155, 155, 155)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [15, 1],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Pedestrian road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(231, 232, 203)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 7],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Walkway road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-dasharray': [1, 2],
        'line-width': {
          stops: [
            [14, 1],
            [18, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Walkway road dots - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'none',
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(202, 211, 200)',
        'line-dasharray': [0.1, 3],
        'line-width': {
          stops: [
            [14, 0.5],
            [18, 5],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'line',
    },
    {
      filter: ['==', 'z_level', 3],
      id: 'Parking road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(255, 255, 255)',
        'line-width': {
          stops: [
            [14, 1],
            [18, 6],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll minor local road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': ['step', ['zoom'], 1, 14, 2, 15, 5, 16, 8, 17, 10, 18, 12],
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll minor local road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [14, 2],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll minor local road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Minor local road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Minor local road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 1],
            [18, 12],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Minor local road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll local road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll local road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 14,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll local road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Local road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Local road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 245, 245)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [13, 2],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Local road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll major local road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll major local road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major local road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Major local road - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-gap-width': 0,
        'line-width': {
          stops: [
            [13, 1],
            [15, 6],
            [18, 13],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Major local road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(235, 229, 218)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [12, 1],
            [18, 13],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major local road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll connecting road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll connecting road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll connecting road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Connecting road - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [10, 2],
            [13, 3],
            [18, 10],
            [19, 50],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Connecting road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(250, 240, 220)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Connecting road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll secondary road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(245, 223, 191)',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll secondary road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll secondary road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Secondary road - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [8, 'rgba(255, 247, 229, 1)'],
            [10, 'rgba(255, 255, 255, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [9, 1],
            [10, 2],
            [13, 2],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Secondary road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(232, 211, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [10, 0.5],
            [13, 4],
            [18, 15],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Secondary road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll major road - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 242, 214, 1)',
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 1],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll major road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll major road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Major road - level+3',
      layout: {
        'line-cap': 'butt',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [11, 'rgba(255, 233, 207, 1)'],
            [12, 'rgba(255, 231, 199, 1)'],
            [14, 'rgba(255, 242, 217, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [8, 0],
            [9, 1],
          ],
        },
        'line-width': {
          stops: [
            [8, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Major road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(255, 244, 181)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [8, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Major road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll international road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgba(255, 244, 185, 1)',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll international road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll international road road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'International road - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(250, 237, 202, 1)'],
            [7, 'rgba(230, 215, 176, 1)'],
            [9, 'rgba(255, 244, 200, 1)'],
            [10, 'rgba(255, 241, 185, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [10, 2],
            [13, 3],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'International road under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(252, 212, 162)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 16],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'International road arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Toll motorway - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 1],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Toll motorway under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Toll motorway arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true]],
      id: 'Motorway - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': {
          stops: [
            [5, 'rgba(255, 201, 122, 1)'],
            [7, 'rgba(255, 224, 153, 1)'],
            [11, 'rgba(255, 228, 136, 1)'],
          ],
        },
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          stops: [
            [5, 0.5],
            [7, 1.5],
            [8, 2],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['==', 'under_construction', true]],
      id: 'Motorway under construction - level+3',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:road',
      minzoom: 13,
      paint: {
        'line-color': 'rgb(237, 166, 119)',
        'line-pattern': 'under-construction',
        'line-width': {
          stops: [
            [5, 0.5],
            [13, 4],
            [18, 18],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'line',
    },
    {
      filter: ['all', ['==', 'z_level', 3], ['!=', 'under_construction', true], ['has', 'direction']],
      id: 'Motorway arrow - level+3',
      layout: {
        'icon-image': ['match', ['get', 'direction'], 1, 'arrow', -1, 'arrow-revers', 'arrow'],
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 100,
      },
      metadata: 'group:road',
      minzoom: 15,
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      id: 'State border background',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      maxzoom: 10,
      metadata: 'group:border',
      minzoom: 7,
      paint: {
        'line-color': 'rgb(255, 255, 255)',
        'line-opacity': {
          stops: [
            [8, 0.5],
            [10, 0],
          ],
        },
        'line-width': {
          stops: [
            [7, 2.7],
            [8, 3],
            [18, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'State border',
      type: 'line',
    },
    {
      id: 'State border',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:border',
      minzoom: 3,
      paint: {
        'line-color': {
          stops: [
            [3, 'rgba(201, 201, 194, 1)'],
            [8, 'rgba(180, 184, 177, 1)'],
          ],
        },
        'line-dasharray': {
          stops: [
            [8, [1, 0]],
            [9, [1, 2]],
          ],
        },
        'line-width': {
          stops: [
            [3, 0.5],
            [10, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'State border',
      type: 'line',
    },
    {
      id: 'Country border background',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:border',
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          stops: [
            [4, 2],
            [10, 3],
            [11, 4],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Country border',
      type: 'line',
    },
    {
      id: 'Country border',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:border',
      paint: {
        'line-color': 'rgba(138, 136, 136, 1)',
        'line-width': {
          stops: [
            [4, 1],
            [10, 2],
            [11, 3],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Country border',
      type: 'line',
    },
    {
      id: 'Treaty country border',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:border',
      paint: {
        'line-color': 'rgb(123, 161, 232)',
        'line-dasharray': {
          stops: [
            [3, [2, 3]],
            [6, [2, 4]],
            [7, [2, 4]],
          ],
        },
        'line-width': {
          stops: [
            [3, 1],
            [6, 1.3],
            [7, 1.3],
            [8, 1.6],
            [18, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Treaty country border',
      type: 'line',
    },
    {
      id: 'Disputed country border',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        'line-round-limit': 1.05,
      },
      metadata: 'group:border',
      paint: {
        'line-color': 'rgb(217, 157, 119)',
        'line-dasharray': {
          stops: [
            [3, [2, 3]],
            [6, [2, 4]],
            [7, [2, 4]],
          ],
        },
        'line-width': {
          stops: [
            [3, 1],
            [6, 1.3],
            [7, 1.3],
            [8, 1.6],
            [18, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Disputed country border',
      type: 'line',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldFerry_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Ferry road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldFerry_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Ferry road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldFerry_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Ferry road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_minor_local_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_minor_local_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_minor_local_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMinor_local_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMinor_local_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMinor_local_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_local_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 14,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_local_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 14,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_local_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 14,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldLocal_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldLocal_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldLocal_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_major_local_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_major_local_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_major_local_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMajor_local_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMajor_local_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMajor_local_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_connecting_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_connecting_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_connecting_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldConnecting_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldConnecting_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldConnecting_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_secondary_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_secondary_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_secondary_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldSecondary_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldSecondary_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldSecondary_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_major_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_major_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_major_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMajor_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMajor_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMajor_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_international_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_international_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_international_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldInternational_road_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldInternational_road_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldInternational_road_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_motorway_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_motorway_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_motorway_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMotorway_tunnel2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMotorway_tunnel1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMotorway_tunnel0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldNon_public_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldNon_public_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldNon_public_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldPedestrian_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldPedestrian_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldPedestrian_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldWalkway_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldWalkway_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldWalkway_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldParking_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldParking_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldParking_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldRailway2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Railway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldRailway1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Railway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldRailway0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Railway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldCountry_border2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Country border',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldCountry_border1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Country border',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldCountry_border0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Country border',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldState_border2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'State border',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldState_border1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'State border',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldState_border0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'State border',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_minor_local_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_minor_local_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_minor_local_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_local_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 14,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_local_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 14,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_local_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 14,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_major_local_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_major_local_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_major_local_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_connecting_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_connecting_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_connecting_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 11,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_secondary_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_secondary_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_secondary_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_major_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_major_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_major_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_international_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_international_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_international_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldToll_motorway2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 7,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldToll_motorway1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 7,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldToll_motorway0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 7,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMinor_local_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMinor_local_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMinor_local_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldLocal_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldLocal_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldLocal_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMajor_local_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMajor_local_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMajor_local_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 12,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldConnecting_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 10,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldConnecting_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 10,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldConnecting_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 10,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldSecondary_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldSecondary_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldSecondary_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 9,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMajor_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMajor_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMajor_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 8,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldInternational_road2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 6,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldInternational_road1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 6,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldInternational_road0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 6,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_3'],
      id: 'ShieldMotorway3',
      layout: {
        'icon-image': '{shield_icon_3}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 350,
        'text-field': '{icon_text_3}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 7,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_3',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_2'],
      id: 'ShieldMotorway2',
      layout: {
        'icon-image': '{shield_icon_2}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 300,
        'text-field': '{icon_text_2}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 7,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_2',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_1'],
      id: 'ShieldMotorway1',
      layout: {
        'icon-image': '{shield_icon_1}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 250,
        'text-field': '{icon_text_1}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 7,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_1',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      filter: ['has', 'shield_icon_0'],
      id: 'ShieldMotorway0',
      layout: {
        'icon-image': '{shield_icon_0}',
        'icon-padding': 5,
        'icon-rotation-alignment': 'viewport',
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 200,
        'text-field': '{icon_text_0}',
        'text-font': ['Noto-Bold'],
        'text-rotation-alignment': 'viewport',
        'text-size': 10,
      },
      metadata: 'group:shield',
      minzoom: 7,
      paint: {
        'text-color': {
          property: 'shield_icon_text_color_0',
          type: 'identity',
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      id: 'Non public road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Non public road',
      type: 'symbol',
    },
    {
      id: 'Walkway road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgba(79, 88, 91, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Walkway road',
      type: 'symbol',
    },
    {
      id: 'Parking road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Parking road',
      type: 'symbol',
    },
    {
      id: 'Service road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Service road',
      type: 'symbol',
    },
    {
      id: 'Pedestrian road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 16,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Pedestrian road',
      type: 'symbol',
    },
    {
      id: 'Minor local road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgba(79, 88, 91, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road',
      type: 'symbol',
    },
    {
      id: 'Ferry road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [[14, 12]],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(74, 151, 191)',
        'text-halo-color': 'rgba(255, 255, 255, 0.5)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Ferry road',
      type: 'symbol',
    },
    {
      id: 'Local road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgba(79, 88, 91, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Local road',
      type: 'symbol',
    },
    {
      id: 'Major local road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [17, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgba(79, 88, 91, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road',
      type: 'symbol',
    },
    {
      id: 'Connecting road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [15, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgba(79, 88, 91, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road',
      type: 'symbol',
    },
    {
      id: 'Secondary road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [9, 8],
            [10, 12],
            [14, 13],
            [15, 14],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgba(79, 88, 91, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road',
      type: 'symbol',
    },
    {
      id: 'Major road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 11,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road',
      type: 'symbol',
    },
    {
      id: 'International road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 10,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road',
      type: 'symbol',
    },
    {
      id: 'Motorway label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 550,
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 10,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway',
      type: 'symbol',
    },
    {
      id: 'Minor local road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Minor local road tunnel',
      type: 'symbol',
    },
    {
      id: 'Local road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Local road tunnel',
      type: 'symbol',
    },
    {
      id: 'Major local road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [17, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Major local road tunnel',
      type: 'symbol',
    },
    {
      id: 'Connecting road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [15, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 11,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Connecting road tunnel',
      type: 'symbol',
    },
    {
      id: 'Secondary road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [9, 8],
            [10, 12],
            [14, 13],
            [15, 14],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Secondary road tunnel',
      type: 'symbol',
    },
    {
      id: 'Major road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 11,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Major road tunnel',
      type: 'symbol',
    },
    {
      id: 'International road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 10,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'International road tunnel',
      type: 'symbol',
    },
    {
      id: 'Motorway tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 10,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Motorway tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll minor local road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road',
      type: 'symbol',
    },
    {
      id: 'Toll local road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 14,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road',
      type: 'symbol',
    },
    {
      id: 'Toll major local road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [17, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road',
      type: 'symbol',
    },
    {
      id: 'Toll connecting road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [15, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road',
      type: 'symbol',
    },
    {
      id: 'Toll secondary road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [14, 13],
            [15, 14],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road',
      type: 'symbol',
    },
    {
      id: 'Toll major road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 11,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road',
      type: 'symbol',
    },
    {
      id: 'Toll international road label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 11,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road',
      type: 'symbol',
    },
    {
      id: 'Toll motorway label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 10,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway',
      type: 'symbol',
    },
    {
      id: 'Toll minor local road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Toll minor local road tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll local road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 14,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Toll local road tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll major local road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [17, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major local road tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll connecting road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [15, 13],
          ],
        },
      },
      metadata: 'group:road_label',
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll connecting road tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll secondary road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [14, 13],
            [15, 14],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 12,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll secondary road tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll major road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 11,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll major road tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll international road tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 11,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll international road tunnel',
      type: 'symbol',
    },
    {
      id: 'Toll motorway tunnel label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.05,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [10, 12],
            [13, 13],
            [14, 14],
            [15, 15],
          ],
        },
      },
      metadata: 'group:road_label',
      minzoom: 10,
      paint: {
        'text-color': 'rgb(0, 0, 0)',
        'text-halo-color': 'rgba(255, 255, 255, 0.75)',
        'text-halo-width': {
          stops: [
            [0, 1],
            [16, 1.5],
            [17, 2],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Toll motorway tunnel',
      type: 'symbol',
    },
    {
      filter: ['has', 'height'],
      id: 'Hospital building 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgba(228, 206, 199, 1)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Hospital building',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Subway Station 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      minzoom: 16,
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgb(210, 224, 232)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Subway Station',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Factory building 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgb(234, 241, 234)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Factory building',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Place of worship 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgb(245, 231, 197)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Place of worship',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'School building 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgba(253, 236, 224, 1)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'School building',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Hotel building 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgb(234, 230, 200)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Hotel building',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Cultural Facility 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgb(234, 230, 200)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Cultural Facility',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Railway Station 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      minzoom: 16,
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgb(210, 224, 232)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Railway Station',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Government Administration Office 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgb(245, 231, 197)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Government Administration Office',
      type: 'fill-extrusion',
    },
    {
      filter: ['has', 'height'],
      id: 'Other building 3D',
      layout: {
        visibility: 'none',
      },
      metadata: 'group:area_3d',
      paint: {
        'fill-extrusion-base': ['get', 'ground_height'],
        'fill-extrusion-color': 'rgba(229, 233, 236, 1)',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.6,
        'fill-extrusion-vertical-gradient': false,
      },
      source: 'vectorTiles',
      'source-layer': 'Other building',
      type: 'fill-extrusion',
    },
    {
      filter: [
        'all',
        [
          'any',
          ['all', ['<=', ['zoom'], 10], ['<=', ['get', 'priority'], 109]],
          ['all', ['==', ['zoom'], 11], ['<=', ['get', 'priority'], 119]],
          ['all', ['==', ['zoom'], 12], ['<=', ['get', 'priority'], 129]],
          ['all', ['==', ['zoom'], 13], ['<=', ['get', 'priority'], 139]],
          ['all', ['==', ['zoom'], 14], ['<=', ['get', 'priority'], 149]],
          ['all', ['==', ['zoom'], 15], ['<=', ['get', 'priority'], 159]],
          ['all', ['==', ['zoom'], 16], ['<=', ['get', 'priority'], 169]],
          ['all', ['==', ['zoom'], 17], ['<=', ['get', 'priority'], 179]],
          ['all', ['>=', ['zoom'], 18], ['<=', ['get', 'priority'], 189]],
        ],
        [
          'any',
          ['==', ['get', 'category'], 'Industrial Building'],
          ['==', ['get', 'category'], 'College/University'],
          ['==', ['get', 'category'], 'Fire Station/Brigade'],
          ['==', ['get', 'category'], 'Government Office'],
          ['==', ['get', 'category'], 'Library'],
          ['==', ['get', 'category'], 'Military Installation'],
          ['==', ['get', 'category'], 'Native Reservation'],
          ['==', ['get', 'category'], 'Non Governmental Organization'],
          ['==', ['get', 'category'], 'Police Station'],
          ['==', ['get', 'category'], 'Post Office'],
          ['==', ['get', 'category'], 'Prison/Correctional Facility'],
          ['==', ['get', 'category'], 'School'],
          ['==', ['get', 'category'], 'Hospital'],
          ['==', ['get', 'category'], 'Amusement Park'],
          ['==', ['get', 'category'], 'Museum'],
          ['==', ['get', 'category'], 'Park & Recreation Area'],
          ['==', ['get', 'category'], 'Zoo, Arboreta & Botanical Garden'],
          ['==', ['get', 'category'], 'Shopping Center'],
          ['==', ['get', 'category'], 'Golf Course'],
          ['==', ['get', 'category'], 'Stadium'],
          ['==', ['get', 'category'], 'Tourist Attraction'],
          ['==', ['get', 'category'], 'Place of Worship'],
          ['==', ['get', 'category'], 'Airport'],
          ['==', ['get', 'category'], 'Ferry Terminal'],
          ['==', ['get', 'category'], 'Public Transportation Stop'],
          ['==', ['get', 'category'], 'Railroad Station'],
          ['==', ['get', 'category'], 'Geographic Feature'],
        ],
      ],
      id: 'POI',
      layout: {
        'icon-image': '{icon}',
        'icon-padding': 5,
        'icon-size': {
          stops: [
            [10, 0.7],
            [18, 1],
          ],
        },
        'symbol-avoid-edges': true,
        'symbol-placement': 'point',
        'symbol-sort-key': ['get', 'priority'],
        'symbol-z-order': 'auto',
        'text-field': '{name}\n{subText}',
        'text-font': ['Noto-Regular'],
        'text-justify': 'auto',
        'text-letter-spacing': 0.1,
        'text-optional': true,
        'text-radial-offset': {
          stops: [
            [10, 1],
            [18, 1.3],
          ],
        },
        'text-size': [
          'step',
          ['zoom'],
          ['/', 16, ['log10', ['max', ['length', ['get', 'name']], 30]]],
          15,
          ['/', 17, ['log10', ['max', ['length', ['get', 'name']], 30]]],
          18,
          ['/', 18, ['log10', ['max', ['length', ['get', 'name']], 30]]],
        ],
        'text-variable-anchor': ['top', 'left', 'bottom', 'right'],
      },
      metadata: 'group:POI',
      paint: {
        'text-color': {
          property: 'category',
          stops: [
            ['Industrial Building', '#46475c'],
            ['College/University', '#5e452a'],
            ['Fire Station/Brigade', '#61525b'],
            ['Government Office', '#61525b'],
            ['Library', '#61525b'],
            ['Military Installation', '#61525b'],
            ['Native Reservation', '#215242'],
            ['Non Governmental Organization', '#61525b'],
            ['Police Station', '#61525b'],
            ['Post Office', '#7e4a87'],
            ['Prison/Correctional Facility', '#61525b'],
            ['School', '#5e452a'],
            ['Hospital', '#7a281c'],
            ['Amusement Park', '#73375b'],
            ['Museum', '#73375b'],
            ['Park & Recreation Area', '#215242'],
            ['Zoo, Arboreta & Botanical Garden', '#73375b'],
            ['Shopping Center', '#7e4a87'],
            ['Golf Course', '#215242'],
            ['Stadium', '#73375b'],
            ['Tourist Attraction', '#215242'],
            ['Place of Worship', '#61525b'],
            ['Airport', '#235082'],
            ['Ferry Terminal', '#235082'],
            ['Public Transportation Stop', '#235082'],
            ['Railroad Station', '#235082'],
            ['Geographic Feature', '#215242'],
          ],
          type: 'categorical',
        },
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Point of Interest',
      type: 'symbol',
    },
    {
      id: 'River label',
      layout: {
        'symbol-avoid-edges': true,
        'symbol-placement': 'line',
        'symbol-spacing': 400,
        'text-field': '{name}',
        'text-font': ['NotoSans-MediumItalic'],
        'text-keep-upright': true,
        'text-letter-spacing': 0.1,
        'text-rotation-alignment': 'map',
        'text-size': {
          stops: [
            [0, 8],
            [12, 12],
            [16, 16],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgba(91, 145, 197, 1)',
        'text-halo-color': '#e0ebf3',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'River label',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Woodland label',
      layout: {
        'icon-image': '169',
        'symbol-placement': 'point',
        'text-anchor': 'top',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.1,
        'text-offset': [0, 1.3],
        'text-size': {
          stops: [
            [12, 9],
            [17, 13],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgb(96, 111, 78)',
        'text-halo-color': 'rgb(255, 255, 255)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Woodland label',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Other water body label',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['NotoSans-MediumItalic'],
        'text-letter-spacing': 0.2,
        'text-size': {
          stops: [
            [12, 9],
            [17, 14],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgba(91, 145, 197, 1)',
        'text-halo-color': 'rgba(255, 255, 255, 0.5)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Other water body label',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Lake label',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.4,
        'text-size': {
          stops: [
            [12, 11],
            [17, 18],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgba(20, 97, 173, 1)',
        'text-halo-color': 'rgba(226, 235, 238, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Lake label',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Island label',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.25,
        'text-size': {
          stops: [
            [12, 11],
            [17, 24],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgb(77, 77, 77)',
        'text-halo-color': 'rgba(255, 255, 255, 0.5)',
        'text-halo-width': 2,
      },
      source: 'vectorTiles',
      'source-layer': 'Island label',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Intermittent water label',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.4,
        'text-size': {
          stops: [
            [12, 9],
            [17, 18],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgb(48, 144, 189)',
        'text-halo-color': 'rgba(255, 255, 255, 0.5)',
        'text-halo-width': 2,
      },
      source: 'vectorTiles',
      'source-layer': 'Intermittent water label',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Other label',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.05,
        'text-size': {
          stops: [
            [12, 9],
            [17, 13],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgb(77, 77, 77)',
        'text-halo-color': 'rgba(255, 255, 255, 0.5)',
        'text-halo-width': 2,
      },
      source: 'vectorTiles',
      'source-layer': 'Other label',
      type: 'symbol',
    },
    {
      filter: ['all', ['!=', 'name', ' '], ['==', 'category', 'hamlet']],
      id: 'Hamlet',
      layout: {
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.05,
        'text-size': 14.4,
      },
      maxzoom: 17,
      metadata: 'group:label',
      minzoom: 14,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 1.92,
      },
      source: 'vectorTiles',
      'source-layer': 'Village',
      type: 'symbol',
    },
    {
      filter: ['all', ['!=', 'name', ' '], ['==', 'category', 'neighborhood']],
      id: 'Neighborhood',
      layout: {
        'icon-padding': 2,
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Bold'],
        'text-letter-spacing': 0.05,
        'text-padding': 20,
        'text-size': 14,
      },
      maxzoom: 17,
      metadata: 'group:label',
      minzoom: 11,
      paint: {
        'text-color': 'rgba(143, 147, 151, 1)',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255, 255, 255, 1)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Village',
      type: 'symbol',
    },
    {
      filter: ['!has', 'category'],
      id: 'Village',
      layout: {
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.05,
        'text-size': ['interpolate', ['linear'], ['zoom'], 8, 10, 15, 17],
      },
      maxzoom: 17,
      metadata: 'group:label',
      minzoom: 11,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Village',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Town',
      layout: {
        'icon-padding': 2,
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Bold'],
        'text-letter-spacing': 0.05,
        'text-padding': 20,
        'text-size': {
          stops: [
            [11, 12],
            [13, 14],
          ],
        },
      },
      maxzoom: 17,
      metadata: 'group:label',
      minzoom: 9,
      paint: {
        'text-color': 'rgba(143, 147, 151, 1)',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255, 255, 255, 1)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Town',
      type: 'symbol',
    },
    {
      filter: ['<=', 'city_priority', 102],
      id: 'Small city icon',
      layout: {
        'icon-image': '{icon}',
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-justify': 'auto',
        'text-offset': [0, 0],
        'text-padding': 8,
        'text-size': 12,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      },
      maxzoom: 8,
      metadata: 'group:label',
      minzoom: 7,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Small city',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Small city',
      layout: {
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-padding': 10,
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          8,
          ['match', ['get', 'display_class'], 9, 13, 10, 12, 12],
          15,
          ['match', ['get', 'display_class'], 9, 20, 10, 19, 19],
        ],
      },
      maxzoom: 17,
      metadata: 'group:label',
      minzoom: 8,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Small city',
      type: 'symbol',
    },
    {
      filter: ['all', ['!=', 'name', ' '], ['has', 'icon'], ['==', 'display_class', 8]],
      id: 'Medium city poprank8',
      layout: {
        'icon-image': '{icon}',
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-justify': 'auto',
        'text-offset': [0, 0],
        'text-padding': 10,
        'text-size': ['step', ['zoom'], 12, 7, 13],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      },
      maxzoom: 8,
      metadata: 'group:label',
      minzoom: 6,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Medium city',
      type: 'symbol',
    },
    {
      filter: [
        'all',
        ['!=', 'name', ' '],
        ['has', 'icon'],
        ['any', ['==', 'display_class', 6], ['==', 'display_class', 7]],
      ],
      id: 'Medium city poprank67',
      layout: {
        'icon-image': '{icon}',
        'icon-size': 1,
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-justify': 'auto',
        'text-padding': 10,
        'text-radial-offset': 0,
        'text-size': ['step', ['zoom'], 12, 6, 14, 7, 15],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      },
      maxzoom: 8,
      metadata: 'group:label',
      minzoom: 5,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Medium city',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Medium city',
      layout: {
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-padding': 10,
        'text-size': 16,
      },
      maxzoom: 16,
      metadata: 'group:label',
      minzoom: 8,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Medium city',
      type: 'symbol',
    },
    {
      filter: ['all', ['!=', 'name', ' '], ['has', 'icon'], ['==', 'display_class', 5]],
      id: 'Large city popRank5',
      layout: {
        'icon-image': '{icon}',
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['step', ['zoom'], ['literal', ['Noto-Medium']], 5, ['literal', ['Noto-Medium']]],
        'text-justify': 'auto',
        'text-radial-offset': ['step', ['zoom'], 0.3375, 8, 0.45],
        'text-size': ['step', ['zoom'], 12, 6, 15, 7, 16],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      },
      maxzoom: 8,
      metadata: 'group:label',
      minzoom: 5,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255,255,255, 0.80)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Large city',
      type: 'symbol',
    },
    {
      filter: [
        'all',
        ['!=', 'name', ' '],
        ['has', 'icon'],
        ['any', ['==', 'display_class', 2], ['==', 'display_class', 3], ['==', 'display_class', 4]],
      ],
      id: 'Large city popRank234',
      layout: {
        'icon-image': '{icon}',
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['step', ['zoom'], ['literal', ['Noto-Medium']], 5, ['literal', ['Noto-Medium']]],
        'text-justify': 'auto',
        'text-letter-spacing': 0.1,
        'text-radial-offset': ['step', ['zoom'], 0.3375, 8, 0.45],
        'text-size': 14,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      },
      maxzoom: 8,
      metadata: 'group:label',
      minzoom: 4,
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)',
        'text-halo-color': 'rgba(255,255,255, 0.80)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Large city',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Large city',
      layout: {
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Medium'],
        'text-letter-spacing': 0.1,
        'text-padding': 10,
        'text-size': ['interpolate', ['linear'], ['zoom'], 8, 18, 15, 25],
      },
      maxzoom: 15,
      metadata: 'group:label',
      minzoom: 8,
      paint: {
        'text-color': '#4D4D4D',
        'text-halo-color': 'rgba(255,255,255, 0.80)',
        'text-halo-width': 2.04,
      },
      source: 'vectorTiles',
      'source-layer': 'Large city',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'State name short',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['Noto-Regular'],
        'text-letter-spacing': 0.1,
        'text-padding': 3,
        'text-size': {
          stops: [
            [0, 10],
            [4, 11],
            [5, 12],
            [7, 14],
            [8, 16],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgba(150, 148, 148, 1)',
        'text-halo-blur': 1,
        'text-halo-color': 'rgba(255, 255, 255, 1)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'State name short',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'State name',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['Noto-Bold'],
        'text-letter-spacing': 0.3,
        'text-padding': 10,
        'text-size': {
          stops: [
            [0, 10],
            [4, 11],
            [5, 12],
            [7, 14],
            [8, 16],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgba(156, 155, 154, 1)',
        'text-halo-blur': 0,
        'text-halo-color': 'rgba(255, 255, 255, 1)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'State name',
      type: 'symbol',
    },
    {
      filter: ['all', ['!=', 'name', ' '], ['has', 'icon']],
      id: 'Capital city node',
      layout: {
        'icon-image': '{icon}',
        'icon-size': ['step', ['zoom'], 0.8, 5, 0.9, 6, 1],
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'symbol-spacing': 250,
        'text-anchor': 'center',
        'text-field': '{name}',
        'text-font': ['Noto-Bold'],
        'text-justify': 'auto',
        'text-letter-spacing': 0.05,
        'text-offset': [0.5, 0.75],
        'text-size': ['step', ['zoom'], 14, 5, 14, 6, 17, 7, 17],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
      },
      maxzoom: 8,
      metadata: 'group:label',
      minzoom: 4,
      paint: {
        'text-color': 'rgba(56, 56, 56, 1)',
        'text-halo-color': 'rgba(255,255,255, 0.80)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Capital city',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Capital city',
      layout: {
        'symbol-placement': 'point',
        'symbol-sort-key': ['+', ['length', ['get', 'name']], ['*', ['get', 'city_priority'], 100]],
        'text-field': '{name}',
        'text-font': ['Noto-Bold'],
        'text-letter-spacing': 0,
        'text-size': ['interpolate', ['linear'], ['zoom'], 8, 18, 15, 25],
      },
      maxzoom: 15,
      metadata: 'group:label',
      minzoom: 8,
      paint: {
        'text-color': 'rgba(77, 77, 77, 1)',
        'text-halo-color': 'rgba(255,255,255, 0.80)',
        'text-halo-width': 2.16,
      },
      source: 'vectorTiles',
      'source-layer': 'Capital city',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Country name',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['Noto-Medium'],
        'text-letter-spacing': 0.05,
        'text-max-width': 4,
        'text-padding': 2,
        'text-size': {
          stops: [
            [0, 10],
            [4, 14],
            [5, 16],
            [6, 18],
            [7, 20],
            [8, 24],
          ],
        },
        'text-transform': 'uppercase',
      },
      metadata: 'group:label',
      paint: {
        'text-color': {
          stops: [
            [1, 'rgba(115, 117, 96, 1)'],
            [6, 'rgb(51, 51, 51)'],
            [10, 'rgb(102, 103, 74)'],
          ],
        },
        'text-halo-color': 'rgb(255, 255, 255)',
        'text-halo-width': {
          stops: [
            [1, 0.5],
            [5, 1],
          ],
        },
      },
      source: 'vectorTiles',
      'source-layer': 'Country name',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Sea label',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['NotoSans-MediumItalic'],
        'text-letter-spacing': 0.1,
        'text-padding': 1,
        'text-size': {
          stops: [
            [0, 8],
            [3, 9],
            [4, 11],
            [5, 15],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgba(47, 104, 160, 1)',
        'text-halo-color': 'rgba(226, 235, 238, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Sea label',
      type: 'symbol',
    },
    {
      filter: ['!=', 'name', ' '],
      id: 'Ocean label',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{name}',
        'text-font': ['NotoSans-MediumItalic'],
        'text-letter-spacing': 0.25,
        'text-size': {
          stops: [
            [0, 8],
            [3, 20],
            [4, 11],
            [5, 15],
          ],
        },
      },
      metadata: 'group:label',
      paint: {
        'text-color': 'rgba(47, 104, 160, 1)',
        'text-halo-color': 'rgba(226, 235, 238, 0.75)',
        'text-halo-width': 1,
      },
      source: 'vectorTiles',
      'source-layer': 'Ocean label',
      type: 'symbol',
    },
  ],
  metadata: {},
  sources: {
    vectorTiles: {
      maxzoom: 22,
      minzoom: 0,
      tiles: [
        'https://a.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv',
        'https://b.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv',
        'https://c.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv',
        'https://d.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.pbf?key=1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv',
      ],
      type: 'vector',
    },
    openmaptiles: {
      url: 'https://api.maptiler.com/tiles/v3/tiles.json?key=H72RRWBbJLn1c1hzE5uT',
      type: 'vector',
    },
  },
  sprite: 'https://api.tomtom.com/map/1/sprite/21.2.1-0/tomtom/sprite?key=1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv',
  version: 8,
};

export default marianStyle;