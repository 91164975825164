import {
  SnapshotSchemaMessage,
  MessageSchema,
  Snapshot,
  Feature,
  FeatureCollection,
  Cause,
} from "core/domain/snapshots.types";
import { EffectCode } from "core/domain/effectCode.types";
import { MainCause } from "core/domain/mainCause.types";
import { WarningLevel } from "core/domain/warningLevel.types";

import protobufjs from "protobufjs";

import snapshotProto from "proto/viewer.proto";

const ROUND_FACTOR = 0.5;
const BIT24FACTOR = 46603.377778;
const BIT24FACTOR_REVERSED = 1 / BIT24FACTOR;

const calculate32BitRepresentation = (val: number) => {
  const sgn = Math.sign(val);
  return (val - sgn * ROUND_FACTOR) * BIT24FACTOR_REVERSED;
};

const getEnumString = (field: protobufjs.Field, enumValue: number): string => {
  const enumType = field.resolvedType as protobufjs.Enum;
  return enumType.valuesById[enumValue];
};

const messageToSnapshotFeature = (message: MessageSchema) => {
  const { location, application, messageManagement } = message;
  const { Id: id } = messageManagement;
  const { longitude, latitude } =
    location.openLR.linearLocationReference.first.absoluteCoordinate;
  const coords = [longitude, latitude].map((value) =>
    calculate32BitRepresentation(value)
  );

  const causes = application.trafficIncident.cause;
  const causesWithStr = causes.map((cause) => {
    const { mainCause, warningLevel, subCause } = cause;
    const { mainCause: mainCauseField, warningLevel: warningLevelField } =
      cause.$type.fields;

    const causeWithStr: Cause = {
      mainCause: getEnumString(mainCauseField, mainCause) as MainCause,
      warningLevel: getEnumString(
        warningLevelField,
        warningLevel
      ) as WarningLevel,
    };

    if (subCause) {
      const subCauseKey = Object.keys(subCause)[0];
      const subCauseValue = subCause[subCauseKey];
      causeWithStr.subCause = {
        [subCauseKey]: getEnumString(
          subCause.$type.fields[subCauseKey],
          subCauseValue
        ),
      };
    }

    return causeWithStr;
  });

  const section = message.application.trafficIncident.section;
  const { effectCode } = section;
  const effectCodeField = section.$type.fields.effectCode;

  const pointFeature = {
    type: "Feature" as Feature,
    geometry: { type: "Point", coordinates: coords },
    properties: {
      location,
      messageManagement,
      application: {
        trafficIncident: {
          cause: causesWithStr,
          section: {
            ...section,
            effectCode: getEnumString(
              effectCodeField,
              effectCode
            ) as EffectCode,
          },
        },
      },
      id: `${id}`,
    },
  };

  return pointFeature;
};

export const createSnapshotsFromProto = async (
  snapshot: ArrayBufferLike
): Promise<Snapshot> => {
  const root = await protobufjs.load(snapshotProto);

  const SnapshotMessage = root.lookupType("Snapshot");
  const buf = new Uint8Array(snapshot);
  const reader = protobufjs.Reader.create(buf);

  const decodedSnapshot = SnapshotMessage.decode(
    reader
  ) as SnapshotSchemaMessage;
  const { message, ...metadata } = decodedSnapshot;

  const features = message.map((messageObject) =>
    messageToSnapshotFeature(messageObject)
  );

  const snapshotGeo = {
    type: "FeatureCollection" as FeatureCollection,
    features,
    metadata,
  };

  return snapshotGeo;
};
