import { AppError } from "api/errors";
import { useEffect } from "react";
import { useToasts, ToastType } from "tombac";

export const Error = ({
  error,
  id,
  type = "danger",
}: {
  error: AppError | undefined;
  type?: ToastType;
  id: string;
}) => {
  const { addToast } = useToasts();

  useEffect(() => {
    if (!error) {
      return;
    }
    addToast(error.details, type, {
      autoDismiss: false,
      id,
      content: (
        <p>
          {error.status && error.status} {error.label}
        </p>
      ),
    });
  }, [error]);

  return null;
};
