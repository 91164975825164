import { Dispatch, SetStateAction, useMemo } from "react";
import { useFeed } from "viewer/contexts/FeedContext";
import { MonthDays } from "./MonthDays";
import { DaySelectorContainer } from "./RangeSelector.styles";
import { createGroupedDateRange } from "./utils";

interface Props {
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
}

export const DateSelector = ({ selectedDate, setSelectedDate }: Props) => {
  const { feed } = useFeed();
  const groupedDateRange = useMemo(() => createGroupedDateRange(feed), [feed]);

  const handleDayClick = (day: Date) =>
    setSelectedDate((prev) => (prev?.valueOf() === day.valueOf() ? null : day));

  return (
    <DaySelectorContainer>
      {groupedDateRange.map((item) => (
        <MonthDays
          key={item.month}
          groupedDays={item}
          selectedDay={selectedDate}
          onClick={handleDayClick}
        />
      ))}
    </DaySelectorContainer>
  );
};
