import { useLayoutEffect } from "react";
import { useSource } from "./Source";
import {
  BackgroundLayer,
  CircleLayer,
  FillLayer,
  LineLayer,
  SymbolLayer,
  FillExtrusionLayer,
  HeatmapLayer,
  HillshadeLayer,
  RasterLayer,
} from "mapbox-gl";
import { useMap } from "../Map/GlMap";

const createLayerId = (id: string, index: number) => `${id}-layer-${index}`;

type PaintAndLayoutLayers =
  | BackgroundLayer
  | CircleLayer
  | FillExtrusionLayer
  | FillLayer
  | HeatmapLayer
  | HillshadeLayer
  | LineLayer
  | RasterLayer
  | SymbolLayer;

type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

type Props = {
  index: number;
} & DistributiveOmit<PaintAndLayoutLayers, "id" | "source">;

export const Layer = ({ index, ...rest }: Props) => {
  const { id, sourceId } = useSource();
  const map = useMap();
  useLayoutEffect(() => {
    const layerId = createLayerId(id, index);

    if (map.getSource(sourceId)) {
      map.addLayer({ id: layerId, source: sourceId, ...rest } as any);
    }

    return () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    };
  }, [sourceId, id]);

  useLayoutEffect(() => {
    const layerId = createLayerId(id, index);
    if (map.getLayer(layerId)) {
      if (rest.layout) {
        Object.entries(rest.layout).forEach(([key, val]) => {
          map.setLayoutProperty(layerId, key, val);
        });
      }
      if (rest.paint) {
        Object.entries(rest.paint).forEach(([key, val]) => {
          map.setPaintProperty(layerId, key, val);
        });
      }
    }
  }, [rest?.layout, rest?.paint, id, index]);

  return null;
};
