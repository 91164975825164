import { ReactNode } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import styled from "styled-components";
import { useTombac } from "tombac";
import { reactSelectStyles } from "./reactSelectStyles";
import { reactSelectTheme } from "./reactSelectTheme";
import { selectCss } from "./selectCss";
import { TombacSelectProps } from "./TombacSelectProps";

export type Option<T> = { label: ReactNode; value: T };

type Reveal<T> = { [k in keyof T]: T[k] };

export type SelectProps<T> = Reveal<
  ReactSelectProps<Option<T>> & TombacSelectProps
>;

const UnstyledSelect = <T,>(props: SelectProps<T>) => {
  const tombac = useTombac();

  return (
    <ReactSelect
      isSearchable={false}
      placeholder=""
      theme={reactSelectTheme(tombac)}
      styles={reactSelectStyles(tombac, props) as any}
      {...props}
    />
  );
};

export const Select = styled(UnstyledSelect).attrs(() => ({
  className: "TombacSelect",
}))`
  ${selectCss}
` as typeof UnstyledSelect;
