import { useEffect } from "react";
import { useMap } from "../Map/GlMap";

interface Props {
  id: string;
  src: string;
}

const useImageUrl = (name: string, url: string) => {
  const map = useMap();
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (map.hasImage(name)) return;
      map.addImage(name, img);
    };
    img.src = url;
  }, [name, url]);
};

export const MapIconImage = ({ id, src }: Props) => {
  useImageUrl(id, src);
  return null;
};
